import { useCallback, useMemo, useState } from 'react';

import { ListProgramParticipantsComponentProps } from '~/app/assignments/interfaces';
import {
  useBulkAddMenteeMutation,
  useBulkDropMenteeMutation,
} from '~/app/data-fetching/mutations/mentorship';
import BulkParticipantFormModal from '~/app/rosters/components/ProgramParticipantsRoster/BulkParticipantFormModal';
import SelectParticipantModal from '~/app/rosters/components/ProgramParticipantsRoster/SelectParticipantModal';
import QueryItemPicker from '~/app/shared/components/QueryItemPicker';
import {
  ASSIGNMENT_ACTIONS,
  ASSIGNMENT_ACTIONS_ICONS,
  ASSIGNMENT_ACTIONS_LABELS,
  ASSIGNMENT_STATES,
} from '~/app/assignments/constants';
import Dashboard from '~/app/backoffice/components/Dashboard';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import AssignmentsActions from '~/app/rosters/components/AssignmentsRoster/AssignmentsActions';
import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import { getNormalizedSelectedData } from '~/app/rosters/utils';
import { displayDate } from '~/services/datetime';
import { Assignment } from '~/app/shared-content-item/interfaces';
import { TableSelectionMode } from '~/app/shared/components/constants';
import { ActionCallbackProps } from '~/app/shared/components/types';
import { get, includes, noop, size, map, capitalize } from 'lodash-es';
import { red } from '@mui/material/colors';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Paper } from '@mui/material';
import { AddIcon } from '~/vendor/mui-icons';
import rql from '~/vendor/rql';
import { useMentorshipProgramLabels } from '~/features/mentorship_programs/hooks/useMentorshipProgramLabels';

interface BulkAction {
  name: ASSIGNMENT_ACTIONS;
  selectedData: ActionCallbackProps;
  errors: any[];
}

const PAGE_SIZE = 25;
const CACHE_KEY = 'mentees_roster';

const ListProgramMentees = ({ contentItem }: ListProgramParticipantsComponentProps) => {
  const { public_id: publicId, name: programName } = contentItem;
  const defaultOrdering = 'name';
  const hideEdit = false;
  const { publishEvent, getCachedExpression } = useRQLFiltersContext();
  const { menteeLabel, menteeLabelPlural } = useMentorshipProgramLabels(contentItem);
  const [bulkAction, setBulkAction] = useState<BulkAction | null>(null);
  const [bulkCreateErrors, setBulkCreateErrors] = useState<any[]>();
  const [showSelectUsersModal, setShowSelectUsersModal] = useState(false);
  const [showBulkAssignModal, setShowBulkAssignModal] = useState(false);
  const [selectedUsersData, setSelectedUsersData] = useState<ActionCallbackProps>({
    selectedItems: [],
    expression: '',
    rows: [],
    rowCount: 0,
    selectAll: false,
  });
  const { expression } = getNormalizedSelectedData(get(bulkAction, 'selectedData'));
  const { total: totalUsers, expression: userRql } = getNormalizedSelectedData(
    selectedUsersData,
    'id'
  );
  const defaultFetchItemsOptions = useMemo(
    () => ({
      view_mode: 'roster',
      content_item: publicId,
    }),
    [publicId]
  );

  const bulkAddMutation = useBulkAddMenteeMutation(contentItem, {
    onError: (error: unknown) => {
      setBulkCreateErrors([error]);
      toast.error(
        `Error: Unable to add ${totalUsers} users to ${programName} as ${menteeLabelPlural}.`
      );
    },
    onSuccess: () => {
      setShowBulkAssignModal(false);
      setShowSelectUsersModal(true);
      toast.success(
        `Successfully added ${totalUsers} users to ${programName} as ${menteeLabelPlural}.`
      );
    },
    onSettled: () => {
      publishEvent(CACHE_KEY, 'forceReload');
    },
  });

  const bulkDropMutation = useBulkDropMenteeMutation(contentItem, {
    onError: (_error, variables) => {
      toast.error(
        `Error: Unable to drop ${size(variables.user_ids)} ${menteeLabelPlural} from ${programName}.`
      );
    },
    onSuccess: (_data, variables) => {
      toast.success(
        `Successfully dropped ${size(variables.user_ids)} ${menteeLabelPlural} from ${programName}.`
      );
    },
    onSettled: () => {
      publishEvent(CACHE_KEY, 'forceReload');
    },
  });

  const handleRowEditClick = useCallback(
    (assignment: Assignment) => {
      setBulkAction({
        name: ASSIGNMENT_ACTIONS.update,
        selectedData: {
          selectedItems: [assignment],
          selectAll: false,
          rows: [],
          rowCount: 0,
          expression: getCachedExpression(CACHE_KEY) || expression,
        },
        errors: [],
      });
    },
    [expression, getCachedExpression]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: capitalize(menteeLabel),
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: { mentee: participant } }) => (
          <Dashboard.UserCell data={participant} showAvatar highlighted />
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: { mentee: participant } }) => get(participant, 'email', ''),
      },
      {
        field: 'title',
        headerName: 'Title',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1.5,
        renderCell: ({ row: { mentee: participant } }) => get(participant, 'title', ''),
      },
      {
        field: 'department',
        headerName: 'Department',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: { mentee: participant } }) => get(participant, 'department', ''),
      },
      {
        field: 'working_since',
        headerName: 'Hire date',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: { mentee: participant } }) => {
          const workingSince = get(participant, 'working_since');
          return workingSince ? displayDate(workingSince) : '';
        },
      },
      {
        field: 'location_text',
        headerName: 'Location',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row: { mentee: participant } }) => get(participant, 'location_text', ''),
      },
      {
        field: 'created',
        headerName: 'Joined',
        filterable: false,
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ row }) => {
          const { created: createdAt } = row;
          return createdAt ? displayDate(createdAt) : '';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 20,
        sortable: false,
        editable: false,
        getActions: (props) =>
          AssignmentsActions({
            ...props,
            handleRowEditClick,
            hideEdit,
          }),
      },
    ],
    [handleRowEditClick, hideEdit]
  );

  const ActionIcon = ({ action }: { action: ASSIGNMENT_ACTIONS }) => {
    const Icon = ASSIGNMENT_ACTIONS_ICONS[action];
    return <Icon />;
  };

  const itemPicker = useMemo(() => {
    return (
      <QueryItemPicker
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'user_name'],
          right: ['actions'],
        }}
        columnVisibilityModel={{
          user_email: false,
          user_title: true,
          user_department: true,
          user_working_since: false,
          user_location_text: false,
          feedback_text: false,
          feedback_rating: false,
        }}
        initialFiltersConfig={[
          { name: null },
          { email: null },
          { title: null },
          { department: null },
        ]}
        query={(expression) => {
          if (expression) return queries.mentorship_program.mentees(publicId, expression);
          return queries.mentorship_program.mentees(publicId, null);
        }}
        queryOptions={rql(defaultFetchItemsOptions)}
        filtersQuery={queries.mentorship_program.mentee_filters}
        fetchFiltersOptions={{
          initial_filters: ['name', 'email', 'title', 'department'],
          filters_to_exclude: ['q'],
        }}
        defaultOrdering={defaultOrdering}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[5, 15, 25, 50, 100, 250]}
        topBarActions={[
          [
            {
              label: 'Bulk actions',
              callback: noop,
              onClickExpand: true,
              tooltip: ({ selectedItems, selectAll }) =>
                size(selectedItems) === 0 && !selectAll ? 'Select at least one assignment' : '',
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
            },
            {
              label: `${ASSIGNMENT_ACTIONS_LABELS.drop}`,
              callback: ({ selectedItems }) => {
                bulkDropMutation.mutate({ user_ids: map(selectedItems, 'mentee.id') });
              },
              startIcon: <ActionIcon action={ASSIGNMENT_ACTIONS.drop} />,
              isDisabled: ({ selectedItems, selectAll }) => size(selectedItems) === 0 && !selectAll,
              sx: { color: red['500'] },
            },
          ],
          [
            {
              label: `Add ${menteeLabelPlural}`,
              callback: () => {
                setShowSelectUsersModal(true);
              },
              variant: 'contained',
              startIcon: <AddIcon />,
            },
          ],
        ]}
        filterBarInputWidth="240px"
        isCellEditable={(params) =>
          includes(
            [
              ASSIGNMENT_STATES.not_started,
              ASSIGNMENT_STATES.in_progress,
              ASSIGNMENT_STATES.exempted,
            ],
            get(params, 'row.state')
          )
        }
        enableSelectAll
        selectionMode={TableSelectionMode.multiple}
        cacheKey={CACHE_KEY}
        backend="url"
      />
    );
  }, [publicId, columns, defaultFetchItemsOptions, bulkDropMutation]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        alignSelf: 'center',
        borderRadius: 1,
        width: '80%',
        height: '100%',
        p: 3,
      }}
    >
      <Typography variant="h5">{capitalize(menteeLabelPlural)}</Typography>
      {itemPicker}
      {showSelectUsersModal && (
        <SelectParticipantModal
          program={contentItem}
          assignLabel={`Add ${menteeLabelPlural}`}
          handleClose={() => setShowSelectUsersModal(false)}
          actionButtonCallback={({ selectedItems, expression, rows, rowCount, selectAll }) => {
            setSelectedUsersData({
              selectedItems,
              expression: expression,
              rows,
              rowCount,
              selectAll,
            });
            setShowSelectUsersModal(false);
            setBulkCreateErrors([]);
            setShowBulkAssignModal(true);
          }}
        />
      )}
      {showBulkAssignModal && (
        <BulkParticipantFormModal
          title={`Add ${menteeLabelPlural} - ${get(contentItem, 'name', '')}`}
          actionLabel={`Add ${menteeLabelPlural} - ${get(contentItem, 'name', '')}`}
          selectedData={selectedUsersData}
          handleAction={() => {
            bulkAddMutation.mutate({
              userRql: userRql,
            });
          }}
          isLoading={bulkAddMutation.isLoading}
          errors={bulkCreateErrors}
          handleClose={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
          handleBack={() => {
            setShowBulkAssignModal(false);
            setShowSelectUsersModal(true);
          }}
        />
      )}
    </Paper>
  );
};

export default ListProgramMentees;
