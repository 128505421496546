import { useMutation } from '@tanstack/react-query';

import { getErrorMessage } from '~/app/assignments/hooks';
import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { STATUS_ERROR } from '~/app/shared/constants';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { contentAssignmentsMutations } from '~/features/contentitems/api/mutations';
import {
  CompleteAssignmentBody,
  CreateAssignmentBody,
  DropAssignmentBody,
  RateAssignmentBody,
  UpdateAssignmentBody,
} from '~/features/contentitems/types';
import { toast } from '~/app/notifications/components/NotificationCenter';

type MutateOptionsType<T extends (...args: any) => any> = Parameters<T>[1];

function handleDisplayErrorToast(error: any, title: string) {
  const errorMessage = getErrorMessage(error);

  if (errorMessage) {
    toast.error(title, errorMessage);
  } else {
    toast.error(title);
  }
}

export type AssignmentActionType =
  | 'create'
  | 'update'
  | 'drop'
  | 'exempt'
  | 'undoExemption'
  | 'complete'
  | 'undoCompletion'
  | 'rate'
  | 'acknowledgeCompletion';

type UseContentItemAssignmentActionsOpts = {
  contentItem?: ContentItem;
  assignment?: Assignment;
  onSuccess?: (action: AssignmentActionType) => void;
};

export function useContentItemAssignmentActions(opts: UseContentItemAssignmentActionsOpts) {
  const { contentItem, assignment, onSuccess } = opts;

  // Left here for backward compatibility
  const [retrieveAssignment] = useEntities(actions.assignment.retrieve, ({ status }) => {
    if (status === STATUS_ERROR) {
      handleDisplayErrorToast(null, 'Failed to retrieve the assignment');
    }
  });

  const handleRefreshContentAssignment = () => {
    if (assignment) {
      retrieveAssignment(assignment.id);
    }
  };

  /* ---------------------------- CREATE ASSIGNMENT --------------------------- */

  const createAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.create,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to create the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('create');
    },
  });

  const handleCreateAssignment = (
    createAssignmentBody?: Omit<CreateAssignmentBody, 'content_item_id'>,
    mutateOptions?: MutateOptionsType<typeof createAssignmentMutation.mutate>
  ) => {
    if (!contentItem) {
      return;
    }

    createAssignmentMutation.mutate(
      {
        content_item_id: contentItem.id,
        ...createAssignmentBody,
      },
      mutateOptions
    );
  };

  /* ---------------------------- UPDATE ASSIGNMENT --------------------------- */

  const updateAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.update,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to update the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('update');
    },
  });

  const handleUpdateAssignment = (
    updateAssignmentBody?: Omit<UpdateAssignmentBody, 'id'>,
    mutateOptions?: MutateOptionsType<typeof updateAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    updateAssignmentMutation.mutate(
      {
        id: assignment.id,
        ...updateAssignmentBody,
      },
      mutateOptions
    );
  };

  /* ---------------------------- DROP ASSIGNMENT --------------------------- */

  const dropAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.drop,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to drop the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('drop');
    },
  });

  const handleDropAssignment = (
    dropAssignmentBody?: Omit<DropAssignmentBody, 'id'>,
    mutateOptions?: MutateOptionsType<typeof dropAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    dropAssignmentMutation.mutate(
      {
        id: assignment.id,
        ...dropAssignmentBody,
      },
      mutateOptions
    );
  };

  /* ---------------------------- EXEMPT ASSIGNMENT --------------------------- */

  const exemptAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.exempt,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to exempt the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('exempt');
    },
  });

  const handleExemptAssignment = (
    mutateOptions?: MutateOptionsType<typeof exemptAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    exemptAssignmentMutation.mutate(assignment.id, mutateOptions);
  };

  /* ---------------------------- UNDO EXEMPT ASSIGNMENT --------------------------- */

  const undoExemptionAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.undoExemption,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to undo the exemption of the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('undoExemption');
    },
  });

  const handleUndoExemptionAssignment = (
    mutateOptions?: MutateOptionsType<typeof undoExemptionAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    undoExemptionAssignmentMutation.mutate(assignment.id, mutateOptions);
  };

  /* ---------------------------- COMPLETE ASSIGNMENT --------------------------- */

  const completeAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.complete,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to complete the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('complete');
    },
  });

  const handleCompleteAssignment = (
    completeAssignmentBody?: Omit<CompleteAssignmentBody, 'id'>,
    mutateOptions?: MutateOptionsType<typeof completeAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    completeAssignmentMutation.mutate(
      {
        id: assignment.id,
        ...completeAssignmentBody,
      },
      mutateOptions
    );
  };

  /* ---------------------------- UNDO COMPLETION ASSIGNMENT --------------------------- */

  const undoCompletionAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.undoCompletion,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to undo the completion of the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('undoCompletion');
    },
  });

  const handleUndoCompletionAssignment = (
    mutateOptions?: MutateOptionsType<typeof undoCompletionAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    undoCompletionAssignmentMutation.mutate(assignment.id, mutateOptions);
  };

  /* ---------------------------- RATE ASSIGNMENT --------------------------- */

  const rateAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.rate,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to rate the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('rate');
    },
  });

  const handleRateAssignment = (
    rateAssignmentBody: Omit<RateAssignmentBody, 'id'>,
    mutateOptions?: MutateOptionsType<typeof rateAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    rateAssignmentMutation.mutate(
      {
        id: assignment.id,
        ...rateAssignmentBody,
      },
      mutateOptions
    );
  };

  /* ---------------------------- ACKNOWLEDGE COMPLETION ASSIGNMENT --------------------------- */

  const acknowledgeCompletionAssignmentMutation = useMutation({
    ...contentAssignmentsMutations.acknowledgeCompletion,
    onError(error) {
      handleDisplayErrorToast(error, 'Failed to acknowledge the completion of the assignment');
    },
    onSuccess() {
      handleRefreshContentAssignment();
      onSuccess?.('acknowledgeCompletion');
    },
  });

  const handleAcknowledgeCompletionAssignment = (
    mutateOptions?: MutateOptionsType<typeof acknowledgeCompletionAssignmentMutation.mutate>
  ) => {
    if (!assignment) {
      return;
    }

    acknowledgeCompletionAssignmentMutation.mutate(assignment.id, mutateOptions);
  };

  const isLoading =
    createAssignmentMutation.isLoading ||
    updateAssignmentMutation.isLoading ||
    dropAssignmentMutation.isLoading ||
    exemptAssignmentMutation.isLoading ||
    undoExemptionAssignmentMutation.isLoading ||
    completeAssignmentMutation.isLoading ||
    undoCompletionAssignmentMutation.isLoading ||
    rateAssignmentMutation.isLoading ||
    acknowledgeCompletionAssignmentMutation.isLoading;

  return {
    create: handleCreateAssignment,
    update: handleUpdateAssignment,
    drop: handleDropAssignment,
    exempt: handleExemptAssignment,
    undoExemption: handleUndoExemptionAssignment,
    complete: handleCompleteAssignment,
    undoCompletion: handleUndoCompletionAssignment,
    rate: handleRateAssignment,
    acknowledgeCompletion: handleAcknowledgeCompletionAssignment,
    isLoading,
  };
}
