import { useState } from 'react';

import { MENTEE_PREFERENCE_TAG_TYPE, MENTOR_PREFERENCE_TAG_TYPE } from '~/app/mentorship/constants';
import { MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE } from '~/app/program/constants';
import { FetchFilterOptions, User } from '~/app/shared/components/types';

import ConfirmationStepModal from './ConfirmationStepModal';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useMentorshipProgramLabels } from '~/features/mentorship_programs/hooks/useMentorshipProgramLabels';
import QueryPeoplePicker from '~/app/users/components/QueryPeoplePicker';
import { queries } from '~/queries';
import { map, get } from 'lodash-es';
import { APIResponse } from '~/app/data-fetching/utils';
import { InfiniteData } from '@tanstack/react-query';

// Needed because MENTORSHIP_REQUEST_KINDS is not in a Typescript file
type MentorshipRequestKinds =
  | 'offer_to_mentor'
  | 'session_request'
  | 'suggest_mentor'
  | 'suggest_mentee';

interface SuggestMentorshipModalProps {
  receiver: User;
  handleClose: () => void;
  suggestionKind: MentorshipRequestKinds;
  isManagerSuggestion?: boolean;
  program?: ContentItem | null;
}

const SuggestMentorshipModal = ({
  receiver,
  handleClose,
  suggestionKind,
  program = null,
  isManagerSuggestion = false,
}: SuggestMentorshipModalProps) => {
  const [step, setStep] = useState(0);
  const [suggestedUser, setSuggestedUser] = useState<User | null>(null);
  const { menteeLabel, mentorLabel } = useMentorshipProgramLabels(program);
  const isSuggestingMentee = suggestionKind === MENTORSHIP_REQUEST_KIND_SUGGEST_MENTEE;

  const mentorOrMentee = isSuggestingMentee ? menteeLabel : mentorLabel;

  const initialFiltersConfig: Record<string, any>[] = isSuggestingMentee
    ? [{ name: null }, { [MENTEE_PREFERENCE_TAG_TYPE]: null }]
    : [{ name: null }, { [MENTOR_PREFERENCE_TAG_TYPE]: null }];

  let query;
  let fetchItemsOptions: Record<string, any> = {};
  let fetchFiltersOptions: FetchFilterOptions = {};
  if (program) {
    query = isSuggestingMentee
      ? (filters) => queries.mentorship_program.mentees(program.public_id, filters)
      : (filters) => queries.mentorship_program.mentors(program.public_id, filters);
  } else {
    query = queries.users.list;
    fetchItemsOptions = isSuggestingMentee
      ? { has_mentee_profile: { $eq: 'true' } }
      : { has_mentor_profile: { $eq: 'true' } };

    fetchFiltersOptions = isSuggestingMentee
      ? { filters_to_exclude: ['has_mentee_profile'] }
      : { filters_to_exclude: ['has_mentor_profile'] };
  }

  const helpText = `Select a ${mentorOrMentee} to suggest. You can write a note on the next step.`;
  const selectUser = (mentorMenteeObj) =>
    get(mentorMenteeObj, isSuggestingMentee ? 'mentee' : 'mentor');
  const pageSelector = (page) => {
    const users = map(page.results, selectUser);
    page = { ...page, results: users };
    return page;
  };
  return (
    <>
      {step === 0 && (
        <QueryPeoplePicker
          query={query}
          dialogTitle={`Suggest a ${mentorOrMentee} (1/2)`}
          helpText={helpText}
          queryOptions={fetchItemsOptions}
          selectFn={(data: InfiniteData<APIResponse<User>>) => {
            return {
              pages: map(data.pages, pageSelector),
              pageParams: data.pageParams,
            };
          }}
          initialFiltersConfig={initialFiltersConfig}
          fetchFiltersOptions={fetchFiltersOptions}
          actionButtonLabel="Next..."
          actionButtonCallback={({ selectedItems: [selectedSuggestedUser] }) => {
            setSuggestedUser(selectedSuggestedUser);
            setStep(1);
          }}
          handleClose={handleClose}
        />
      )}
      {step === 1 && (
        <ConfirmationStepModal
          dialogTitle={`Suggest a ${mentorOrMentee} (2/2)`}
          receiver={receiver}
          suggestedUser={suggestedUser}
          initialValues={{ suggested_id: suggestedUser?.id }}
          suggestionKind={suggestionKind}
          handleClose={handleClose}
          handleBack={() => setStep(0)}
          onRequestSent={handleClose}
          isManagerSuggestion={isManagerSuggestion}
          program={program}
        />
      )}
    </>
  );
};

export default SuggestMentorshipModal;
