import { useMutation } from '@tanstack/react-query';
import { apiPatch, apiPost, apiDelete } from '~/services/requests';

export function useCreateSegment() {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPost('segments_api:list_create', {}, data);
      return response;
    },
  });
}

export function useEditSegment(publicId: string) {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPatch(
        'segments_api:retrieve_update_destroy',
        publicId,
        data
      );
      return response;
    },
  });
}

export function useDeleteSegment(publicId: string) {
  return useMutation({
    mutationFn: async () => {
      const { data: response } = await apiDelete('segments_api:retrieve_update_destroy', publicId);
      return response;
    },
  });
}
