import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Typography } from '~/common/components/Typography';
import { get } from 'lodash-es';

interface CollapsableTextProps {
  children: React.ReactNode;
  maxHeight?: number; // The collapsed height
  minOverflow?: number; // The overflow threshold
}

export const CollapsableText = (props: CollapsableTextProps) => {
  const { children, maxHeight = 100, minOverflow = 20 } = props;

  const textRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [domChanged, setDomChanged] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isExpandeable, setIsExpandeable] = useState(false);
  const height = get(textRef.current, 'scrollHeight');
  useEffect(() => {
    const observer = new MutationObserver(() => {
      // Change state to prompt a re-render since the underlying dom node changed
      setDomChanged(domChanged + 1);
    });
    if (textRef.current) {
      observer.observe(textRef.current, { attributes: true, childList: true, subtree: true });
      const { scrollHeight, clientHeight } = textRef.current;
      const difference = scrollHeight - clientHeight;
      const isCurrentlyOverflowing = difference >= minOverflow;

      setIsOverflowing(isCurrentlyOverflowing);

      if (!isExpandeable && isCurrentlyOverflowing) {
        setIsExpandeable(true);
      }
    }
    return () => {
      observer.disconnect();
    };
  }, [children, height]);

  const showGradient = isOverflowing && !isExpanded;
  return (
    <>
      <Box
        ref={textRef}
        sx={{
          maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease',
          maskImage: showGradient ? 'linear-gradient(to bottom, black 50%, transparent)' : 'none',
        }}
      >
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {children}
        </Typography>
      </Box>

      {isExpandeable && (
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            padding: 0,
            marginTop: -1.5,
            fontWeight: 'bold',
          }}
          variant="text"
        >
          {isExpanded ? 'Show Less' : 'Read More'}
        </Button>
      )}
    </>
  );
};
