import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { MentorProfileData } from '~/app/mentorship/interfaces';
import { addMentorToProgram } from '~/app/data-fetching/mutations/mentorship';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { useMentorshipMentorFiltersDefinition } from '~/app/mentorship/hooks';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import { mapRoute } from '~/services/requests';
import CardList from '~/app/shared-cards/components/CardList';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import RequestSessionModal from '~/app/shared-modals/components/RequestSessionModal';
import Button from '~/app/shared/components/Button';
import { MentorCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import { User } from '~/app/shared/components/types';
import { useCurrentUser, useEncodedCurrentRoute } from '~/app/shared/hooks';
import { isError, isEmpty, some, map, capitalize } from 'lodash-es';
import { Box, Stack } from '@mui/material';
import EmptyMessage from './EmptyMessage';
import { useMentorshipProgramLabels } from '~/features/mentorship_programs/hooks/useMentorshipProgramLabels';

interface ScheduleSessionProps {
  handleOnClick: () => void;
}
const ScheduleSessionButton = ({ handleOnClick }: ScheduleSessionProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
      paddingBottom: '15px',
    }}
  >
    <Button variant="outlined" onClick={handleOnClick}>
      Request a Session
    </Button>
  </Box>
);

const handleShowSessionModal = (mentor, setMentorForRequest, setShowRequestSessionModal) => {
  return () => {
    setShowRequestSessionModal(true);
    setMentorForRequest(mentor);
  };
};
export interface MentorshipProgramMentorListProps {
  program: ContentItem;
  canJoin: boolean;
  canScheduleSession: boolean;
}

const MentorshipProgramMentorList = ({
  program,
  canJoin,
  canScheduleSession,
}: MentorshipProgramMentorListProps) => {
  const { public_id, id } = program;
  const { mentorLabel, mentorLabelPlural } = useMentorshipProgramLabels(program);

  const [showRequestSessionModal, setShowRequestSessionModal] = useState<boolean>(false);
  const [mentorForRequest, setMentorForRequest] = useState<User | null>(null);

  const currentUser = useCurrentUser();

  const currentRoute = useEncodedCurrentRoute();

  const { filters, updateFilter, resetFilters, rqlExpression } = useRQLFilters({
    initialFiltersState: {},
    initialOrdering: 'name',
  });

  const { filters: barFilters, moreFilters } = useMentorshipMentorFiltersDefinition({
    filters,
    updateFilter,
  });

  const {
    isLoading,
    isError: hasError,
    data: response,
    error,
  } = useQuery(queries.mentorship_program.mentors(public_id, rqlExpression));

  const people = map(response?.results, 'mentor');

  const joinMutation = useMutation({
    mutationFn: () => addMentorToProgram(public_id, currentUser.id, id),
    onError: () => {
      toast.error(`Error: Unable to join ${program.name} as a ${mentorLabel}.`);
    },
    onSuccess: () => {
      toast.success(`Successfully joined ${program.name} as a ${mentorLabel}.`);
    },
  });

  const { data: isMentee } = useQuery(
    queries.mentorship_program.mentees(public_id, null)._ctx.is_mentee(currentUser.id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (hasError && isError(error)) {
    return <span>Error: {error.message} </span>;
  }

  const isMentor = some(people, (mentor) => {
    return mentor.id == currentUser.id;
  });

  const showJoin = canJoin && !isMentor;

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters} />
        </Box>
        {showJoin && (
          <Button sx={{ minWidth: '140px' }} onClick={() => joinMutation.mutate()}>
            {`Join as ${capitalize(mentorLabel)}`}
          </Button>
        )}

        {isMentor && canScheduleSession && (
          <Button
            size="large"
            component={Link}
            to={`${mapRoute('mentorshipSessionCreate', {
              public_id_and_slug: public_id,
            })}?origin=${currentRoute}`}
          >
            Schedule Session
          </Button>
        )}
      </Stack>

      {isEmpty(people) && <EmptyMessage message={`No ${mentorLabelPlural} found.`} />}

      {!isEmpty(people) && (
        <Box sx={{ pt: 3 }}>
          <CardList
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            items={people}
            renderItem={(mentor: MentorProfileData) => (
              <MentorCard
                cardProps={{ width: '100%' }}
                key={mentor.id}
                mentor={mentor}
                scheduleComponent={
                  isMentee ? (
                    <ScheduleSessionButton
                      handleOnClick={handleShowSessionModal(
                        mentor,
                        setMentorForRequest,
                        setShowRequestSessionModal
                      )}
                    />
                  ) : null
                }
              />
            )}
          />
        </Box>
      )}

      {showRequestSessionModal && (
        <RequestSessionModal
          host={mentorForRequest}
          handleClose={() => setShowRequestSessionModal(false)}
          program={program}
        />
      )}
    </Box>
  );
};

export default MentorshipProgramMentorList;
