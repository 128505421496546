import { Chip, chipClasses } from '@mui/material';

import {
  CONTENT_TYPES,
  CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING,
  LEARNING_TYPE_ICONS_MAPPING,
  LEARNING_TYPES,
} from '~/app/catalog/constants';
import { useLearningTypeLabels } from '~/app/catalog/hooks';
import { ValueOf } from 'type-fest';
import Icon from '~/app/shared/components/Icon';
import {
  blue,
  deepPurple,
  green,
  indigo,
  lightGreen,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@mui/material/colors';

type PillableContentType = Exclude<
  ValueOf<typeof CONTENT_TYPES>,
  | typeof CONTENT_TYPES.text_question
  | typeof CONTENT_TYPES.multiple_choice_question
  | typeof CONTENT_TYPES.question
  | typeof CONTENT_TYPES.all
>;

type PillableLearningType = Exclude<
  ValueOf<typeof LEARNING_TYPES>,
  | typeof LEARNING_TYPES.text_questions
  | typeof LEARNING_TYPES.multiple_choice_questions
  | typeof LEARNING_TYPES.questions
  | typeof LEARNING_TYPES.all
>;

type NewContentTypePillProps = {
  type: PillableContentType;
  iconOnly?: boolean;
};

const learningTypeColorMap: Record<PillableLearningType, { bg: string; fg: string }> = {
  [LEARNING_TYPES.event_types]: {
    bg: teal[50],
    fg: teal[700],
  },
  [LEARNING_TYPES.events]: {
    bg: teal[50],
    fg: teal[700],
  },
  [LEARNING_TYPES.articles]: {
    bg: pink[50],
    fg: pink[600],
  },
  [LEARNING_TYPES.tasks]: {
    bg: indigo[50],
    fg: indigo[600],
  },
  [LEARNING_TYPES.videos]: {
    bg: red[50],
    fg: red[800],
  },
  [LEARNING_TYPES.courses]: {
    bg: blue[50],
    fg: blue[800],
  },
  [LEARNING_TYPES.codelabs]: {
    bg: orange[100],
    fg: orange[900],
  },
  [LEARNING_TYPES.officehour_programs]: {
    bg: purple[50],
    fg: purple[500],
  },
  [LEARNING_TYPES.programs]: {
    bg: purple[50],
    fg: purple[500],
  },
  [LEARNING_TYPES.tracks]: {
    bg: '#FCE6F2',
    fg: '#7B0051',
  },
  [LEARNING_TYPES.scheduled_tracks]: {
    bg: '#FCE6F2',
    fg: '#7B0051',
  },
  [LEARNING_TYPES.assessments]: {
    bg: deepPurple[50],
    fg: deepPurple[600],
  },
  [LEARNING_TYPES.linkedcontent]: {
    bg: lightGreen[50],
    fg: lightGreen[800],
  },
  [LEARNING_TYPES.mentorship_programs]: {
    bg: teal[800],
    fg: green[50],
  },
} as const;

function useContentTypePillVariant(type: PillableContentType) {
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[type] as PillableLearningType;

  const label = useLearningTypeLabels()[learningType];
  const icon = LEARNING_TYPE_ICONS_MAPPING[learningType];

  const learningTypeColors = learningTypeColorMap[learningType];
  const bgColor = learningTypeColors.bg;
  const color = learningTypeColors.fg;

  return {
    label,
    bgColor,
    color,
    icon,
  };
}

function ContentTypePill(props: NewContentTypePillProps) {
  const { type, iconOnly = false } = props;

  const variant = useContentTypePillVariant(type);

  return (
    <Chip
      icon={<Icon name={variant.icon} height={12} width={12} color={variant.color} />}
      label={variant.label}
      size="small"
      sx={{
        fontSize: '0.75rem',
        padding: '0px 8px',
        gap: iconOnly ? '0px' : '4px',
        backgroundColor: variant.bgColor,
        [`& .${chipClasses.icon}`]: {
          flexShrink: 0,
          margin: '0',
        },
        [`& .${chipClasses.label}`]: {
          padding: '0px',
          color: variant.color,
        },
      }}
    />
  );
}

export { ContentTypePill };
