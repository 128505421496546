import { useQueryClient } from '@tanstack/react-query';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItemContextMenu } from '~/app/content-items/components/interfaces';
import { queries } from '~/queries';
import ContentItemLogtrailModal from '~/scenes/ContentItemLogtrailModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import ContentSurveyModal from '~/app/shared/components/ContentSurveyModal';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { useToggles } from '~/app/shared/hooks';
import DeleteStandAloneModal from '~/app/stand-alone-shared/components/DeleteStandAloneModal';
import ScheduleTrackModal from '~/app/tracks/components/ScheduleTrackModal';
import TrackItemCompletionStatsModal from '~/app/tracks/components/TrackItemCompletionStatsModal';
import { useCanDisplayTrackInsights } from '~/features/tracks/hooks/useCanDisplayTrackInsights';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import TrackArchiveUnarchiveConfirmationModal from './TrackArchiveUnarchiveConfirmationModal';
import { TrackInsightsModal } from '~/features/tracks/components/TrackInsightsModal';
import { useRouteActiveModalCtx } from '~/common/contexts/RouteActiveModal';

const AvailableModals = {
  ArchiveUnarchive: 'archive_unarchive',
  Delete: 'delete',
  Survey: 'survey',
  Schedule: 'schedule',
  Log: 'log',
  TrackInsights: 'track_insights',
  CompletionStats: 'completion_stats',
} as const;

const TrackContextMenu = (props: ContentItemContextMenu) => {
  const { content, contextMenuExtraProps } = props;

  const { activeModal, setActiveModal, closeActiveModal } = useRouteActiveModalCtx();

  const { public_id: publicId, content_type: contentType } = content;
  const isArchived = content.is_archived || Boolean(content.archival);
  const isTrack = contentType === CONTENT_TYPES.track;
  const isScheduleTrack = contentType === CONTENT_TYPES.scheduled_track;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const { canSeeSettings, canManageAssignment, contentTypeRoute, currentRoute } =
    contextMenuExtraProps ?? {};

  const queryClient = useQueryClient();

  const { toggle_scheduled_tracks: toggleScheduledTracks } = useToggles();
  const canDisplayTrackInsights = useCanDisplayTrackInsights(content);

  const handleCloseArchiveUnarchiveModal = (shouldRefreshContent: boolean) => {
    if (shouldRefreshContent) {
      queryClient.invalidateQueries({
        queryKey: queries.content_items.details(publicId, 'detail').queryKey,
      });
    }

    closeActiveModal();
  };

  const handleDisplayTrackInsightsModal = () => {
    if (canDisplayTrackInsights) {
      setActiveModal(AvailableModals.TrackInsights);
      return;
    }

    setActiveModal(AvailableModals.CompletionStats);
  };

  return (
    <>
      {canSeeSettings && (
        <DropDownMenu icon="elipsis" fontSize={16} buttonAriaLabel="Settings">
          <>
            {contentTypeRoute && (
              <DropDownMenu.Item
                route={contentTypeRoute.edit({ public_id: publicId })}
                title="Edit"
                icon="pencil"
              />
            )}
            {isTrack && toggleScheduledTracks && (
              <div data-testid="ScheduleMenuItem">
                <DropDownMenu.Item
                  onClick={() => setActiveModal(AvailableModals.Schedule)}
                  title="Schedule"
                  icon="add"
                />
              </div>
            )}
            {isTrack && (
              <DropDownMenu.Item
                route={`${mapRoute('trackCreate')}?cloned=${publicId}&origin=${currentRoute}`}
                title="Duplicate"
                icon="clone"
              />
            )}
            {canManageAssignment && (
              <>
                <DropDownMenu.Item
                  route={mapRoute('contentItemManageAssignments', {
                    public_id_and_slug: content.public_id_and_slug,
                  })}
                  title="View Roster"
                  icon="group"
                />
                <DropDownMenu.Item
                  onClick={handleDisplayTrackInsightsModal}
                  title="View Insights"
                  icon={MoreHorizIcon}
                />
              </>
            )}
            {!isAssessment && (
              <DropDownMenu.Item
                onClick={() => setActiveModal(AvailableModals.Survey)}
                title="Manage Surveys"
                icon="survey"
              />
            )}
            <DropDownMenu.Separator />
            {!isArchived && (
              <DropDownMenu.Item
                onClick={() => setActiveModal(AvailableModals.ArchiveUnarchive)}
                title={isScheduleTrack ? 'Cancel...' : 'Archive...'}
                icon="archive"
              />
            )}
            {isArchived && (
              <>
                <DropDownMenu.Item
                  onClick={() => setActiveModal(AvailableModals.ArchiveUnarchive)}
                  title="Restore..."
                  icon="unarchive"
                />
                <DropDownMenu.Item
                  onClick={() => setActiveModal(AvailableModals.Delete)}
                  title="Delete..."
                  icon="delete"
                  color={colors.error600}
                  textColor={colors.error600}
                />
              </>
            )}
            {canManageAssignment && (
              <DropDownMenu.Item
                onClick={() => setActiveModal(AvailableModals.Log)}
                title="View Logs"
                icon="log"
              />
            )}
          </>
        </DropDownMenu>
      )}

      {/* Modals */}
      {activeModal === AvailableModals.ArchiveUnarchive && (
        <TrackArchiveUnarchiveConfirmationModal
          track={content}
          handleClose={({ shouldRefreshContent }) => {
            handleCloseArchiveUnarchiveModal(shouldRefreshContent);
          }}
        />
      )}

      {activeModal === AvailableModals.Delete && (
        <DeleteStandAloneModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Survey && (
        <ContentSurveyModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Schedule && (
        <ScheduleTrackModal track={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.Log && (
        <ContentItemLogtrailModal content={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.CompletionStats && (
        <TrackItemCompletionStatsModal track={content} handleClose={closeActiveModal} />
      )}

      {activeModal === AvailableModals.TrackInsights && (
        <TrackInsightsModal track={content} onClose={closeActiveModal} />
      )}
    </>
  );
};

export default TrackContextMenu;
