import * as React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import {
  DataGridPro as DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  gridClasses,
} from '@mui/x-data-grid-pro';

import { InsightWidget, InsightWidgetHeader } from '~/common/components/InsightWidget';
import { ContentTypePill } from '~/features/contentitems/components/ContentTypePill';
import { grey } from '@mui/material/colors';
import { queries } from '~/queries';
import { useQuery } from '@tanstack/react-query';
import { Track, TrackCompletionStatusByItemInsightTrackItem } from '../types';
import { RuleIcon } from '~/vendor/mui-icons';
import colors from '~/services/colors';
import { useHistory } from 'react-router-dom';
import { mapRoute } from '~/services/requests';
import { CONTENT_TYPES } from '~/app/catalog/constants';

type ViewProgressByAssigneeActionProps = GridActionsCellItemProps & {
  trackItem: TrackCompletionStatusByItemInsightTrackItem;
  track: Track;
};

function ViewProgressByAssigneeAction(props: ViewProgressByAssigneeActionProps) {
  const { track, trackItem, ...rest } = props;

  const history = useHistory();

  const rosterUrl =
    trackItem.content_item.content_type === CONTENT_TYPES.event
      ? 'eventManageAttendance'
      : 'contentItemManageAssignments';

  const handleNavigate = () => {
    const route = mapRoute(rosterUrl, {
      public_id_and_slug: trackItem.content_item.public_id_and_slug,
    });

    history.push(`${route}?eq(parent_track,${track.id})`);
  };

  return (
    <GridActionsCellItem
      {...rest}
      label="View Progress by Assignee"
      icon={<RuleIcon sx={{ color: colors.neutral600 }} />}
      onClick={handleNavigate}
    />
  );
}

const columns: GridColDef<TrackCompletionStatusByItemInsightTrackItem>[] = [
  {
    field: 'content_type',
    headerName: 'Type',
    minWidth: 132,
    renderCell: ({ row }) => {
      return <ContentTypePill type={row.content_item.content_type as any} />;
    },
  },
  {
    field: 'content_item',
    valueGetter: (_, row) => row.content_item.name,
    headerName: 'Title',
    flex: 2,
  },
  {
    field: 'assignments',
    headerName: 'Assignments',
    minWidth: 243,
    flex: 1,
    renderCell: ({ row }) => {
      const totalAssignments =
        row.states.completed + row.states.in_progress + row.states.not_started;
      const progress = (row.states.completed / totalAssignments) * 100;

      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '4px',
            maxWidth: '200px',
          }}
        >
          <LinearProgress variant="determinate" value={progress} />
          <Typography
            variant="caption"
            component="span"
            sx={{
              fontSize: '0.75rem',
              color: 'text.secondary',
            }}
          >
            {row.states.completed} / {totalAssignments}
          </Typography>
        </Box>
      );
    },
  },
];

type TrackItemsProgressInsightWidgetProps = {
  track: Track;
  queryExpression: string;
};

function TrackItemsProgressInsightWidget(props: TrackItemsProgressInsightWidgetProps) {
  const { track, queryExpression } = props;

  const { data: insightData, status } = useQuery({
    ...queries.tracks.completionStatusByItemInsights(track.id, queryExpression),
  });

  const trackItems = insightData?.track_items || [];

  const cols = React.useMemo<GridColDef<TrackCompletionStatusByItemInsightTrackItem>[]>(() => {
    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        width: 44,
        getActions: (params) => {
          return [
            <ViewProgressByAssigneeAction
              key={`view-track-item-progress-by-assignee-${params.row.id}`}
              label="View Progress by Assignee"
              icon={<RuleIcon sx={{ color: colors.neutral600 }} />}
              trackItem={params.row}
              track={track}
              showInMenu
            />,
          ];
        },
      },
    ];
  }, [track]);

  return (
    <InsightWidget
      sx={{
        gap: '4px',
      }}
    >
      <InsightWidgetHeader
        title="Items Progress"
        icon={<AssignmentTurnedInOutlinedIcon fontSize="small" sx={{ color: '#0288D1' }} />}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '368px',
        }}
      >
        <DataGrid
          sx={{
            border: 'none',
            '--DataGrid-containerBackground': 'unset',
            [`& .${gridClasses['container--top']}`]: {
              backgroundColor: grey[50],
            },
          }}
          loading={status === 'loading'}
          rows={trackItems}
          columns={cols}
          disableColumnMenu
          disableColumnSorting
          disableRowSelectionOnClick
          autosizeOnMount
          autosizeOptions={{
            columns: ['content_type', 'assignments'],
            expand: true,
            includeHeaders: true,
            includeOutliers: true,
          }}
          pagination
          pageSizeOptions={[5, 10, 20]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
        />
      </Box>
    </InsightWidget>
  );
}

export { TrackItemsProgressInsightWidget };
