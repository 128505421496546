import { QueryFunction, QueryKey, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { STATUS_DONE, STATUS_LOADING, STATUS_NOT_REQUESTED } from '~/app/shared/constants';
import { map, get, concat, last, noop } from 'lodash-es';
import { DEFAULT_STALE_TIME } from '~/common/constants/queryClient';
import { APIResponse } from './utils';

const DEFAULT_RETRY = 1;
const EMPTY_RESPONSE = {
  data: undefined,
  count: 0,
  status: STATUS_NOT_REQUESTED,
  hasNextPage: false,
  nextPage: '',
  isLoading: true,
  refetch: noop,
  isError: false,
  error: undefined,
};

export interface FetchDataProps {
  queryKey: QueryKey;
  queryFn: QueryFunction;
  select?: (data) => any;
  enabled?: boolean;
  staleTime?: number;
  retry?: number;
  keepPreviousData?: boolean;
}

export interface FetchDataListResponse<TData = any> {
  data?: TData[];
  count: number;
  status: string;
  scrollId?: string;
  hasNextPage: boolean;
  nextPage: string;
  isLoading: boolean;
  isError: boolean;
  error: any;
  fetchMore?: () => void;
  refetch: any;
}

export const useFetchDataList = <TData = any>({
  queryKey,
  queryFn,
  enabled = true,
  staleTime = DEFAULT_STALE_TIME,
  retry = DEFAULT_RETRY,
  keepPreviousData = false,
}: FetchDataProps) => {
  const [result, setResult] = useState<FetchDataListResponse<TData>>(EMPTY_RESPONSE);
  const { data, fetchStatus, refetch, isError, error, isLoading } = useQuery({
    queryKey,
    queryFn,
    enabled,
    staleTime,
    retry,
    keepPreviousData,
  });

  useEffect(() => {
    setResult({
      data: get(data, 'results', []) as TData[],
      count: get(data, 'count', 0),
      status: fetchStatus === 'idle' ? STATUS_DONE : STATUS_LOADING,
      hasNextPage: Boolean(get(data, 'next')),
      nextPage: get(data, 'next') ?? '',
      scrollId: get(data, 'scroll_id'),
      isLoading: isLoading,
      isError: isError,
      error: error,
      refetch,
    });
  }, [data, fetchStatus, refetch]);

  return result;
};

// In the paginated version, TanStack will handle the pageParam, so setting the page in the search is unnecessary.
// https://tanstack.com/query/v4/docs/react/guides/infinite-queries
export const useFetchDataListPaginated = <TData = any>({
  queryKey,
  queryFn,
  select,
  enabled = true,
  staleTime = DEFAULT_STALE_TIME,
  retry = DEFAULT_RETRY,
}: FetchDataProps) => {
  const [result, setResult] = useState<FetchDataListResponse<TData>>(EMPTY_RESPONSE);

  const { data, fetchStatus, fetchNextPage, refetch, isError, error, isLoading } = useInfiniteQuery(
    {
      queryKey,
      queryFn,
      enabled,
      staleTime,
      retry,
      select,
      // The InfiniteQuery understand "null" as a valid value for the next page, it only understands that it is the final page when the value is undefined
      getNextPageParam: (lastPage: APIResponse<TData>) => lastPage.next || undefined,
    }
  );

  useEffect(() => {
    setResult({
      data: concat([], ...map(get(data, 'pages', []), 'results')) as TData[],
      count: get(data, 'pages.0.count', 0),
      status: fetchStatus === 'idle' ? STATUS_DONE : STATUS_LOADING,
      hasNextPage: Boolean(get(last(get(data, 'pages')), 'next')),
      nextPage: get(last(get(data, 'pages')), 'next') ?? '',
      isLoading: isLoading,
      isError: isError,
      error: error,
      fetchMore: () => fetchNextPage(),
      refetch,
    });
  }, [data, isLoading, isError, error, fetchStatus, fetchNextPage, refetch]);
  return result;
};
