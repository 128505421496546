import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { useGetContentTypeLabel } from '~/app/catalog/hooks';
import actions from '~/app/entities/actions';
import { trackItemSchema } from '~/app/entities/schema';
import { useArchiveRestore, useEntities } from '~/app/entities/utils';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import CompletionSection from '~/app/shared/components/NewContentForm/CompletionSection';
import AccessControlSection from '~/app/shared/components/NewContentForm/sections/AccessControlSection';
import ResourcesSection from '~/app/shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from '~/app/shared/components/NewContentForm/sections/SurveysSection';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from '~/app/shared/constants';
import { useLabels } from '~/app/shared/hooks';
import {
  filter,
  find,
  forEach,
  get,
  isEmpty,
  map,
  size,
  toLower,
  findIndex,
  cloneDeep,
} from 'lodash-es';

import AssessmentSettingsSection from './components/AssessmentSettingsSection';
import {
  TrackItem,
  FullTrackSection,
  TrackSection,
  TrackContentItem,
  Track,
  trackTypes,
} from './interfaces';

export const useTrackArchiveUnarchive = (track: Track, successCallback?: CallableFunction) => {
  const { label_question_plural: labelQuestionPlural } = useLabels();
  const lowerLabelQuestionPlural = toLower(labelQuestionPlural);

  const { id, content_type: contentType, name } = track;
  const getContentTypeLabel = useGetContentTypeLabel();
  const contentLabel = getContentTypeLabel(contentType);
  const isArchived = track.is_archived || Boolean(track.archival);
  const isScheduleTrack = contentType === CONTENT_TYPES.scheduled_track;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const entityActions = {
    [CONTENT_TYPES.track]: actions.track,
    [CONTENT_TYPES.scheduled_track]: actions.scheduledTrack,
    [CONTENT_TYPES.assessment]: actions.assessment,
  }[track.content_type];

  const { archiveItem, restoreItem, isUpdating } = useArchiveRestore(
    entityActions,
    successCallback
  );

  const trackItemsLabel = isAssessment ? lowerLabelQuestionPlural : `${contentLabel} Items`;

  if (isScheduleTrack && !isArchived) {
    return {
      actionLabel: 'Cancel',
      handleAction: () => archiveItem(id),
      isUpdating,
      renderConfirmationText: () => (
        <>
          <Text block size="h5" color={colors.neutral900}>
            You are about to cancel <b>{name}</b>. Once cancelled, it will no longer be
            discoverable, unless you restore it.
          </Text>
          <Text block size="h5" color={colors.neutral900}>
            This action will <b>not</b> implicitly archive/cancel any underlying {trackItemsLabel},
            change any assignments/enrollments, nor send any notifications. It is up to you if you
            wish to do so explicitly.
          </Text>
          <Text block size="h5" color={colors.neutral900}>
            Are you sure you wish to proceed?
          </Text>
        </>
      ),
    };
  }

  if (!isScheduleTrack && !isArchived) {
    return {
      actionLabel: 'Archive',
      handleAction: () => archiveItem(id),
      isUpdating,
      renderConfirmationText: () => (
        <>
          <Text block size="h5" color={colors.neutral900}>
            You are about to archive <b>{name}</b>. Once archived, it will no longer be
            discoverable, unless you restore it.
          </Text>
          <Text block size="h5" color={colors.neutral900}>
            This action will <b>not</b> implicitly archive any underlying {trackItemsLabel}, change
            any assignments, nor send any notifications. It is up to you if you wish to do so
            explicitly.
          </Text>
          <Text block size="h5" color={colors.neutral900}>
            Are you sure you wish to proceed?
          </Text>
        </>
      ),
    };
  }

  return {
    actionLabel: 'Restore',
    handleAction: () => restoreItem(id),
    isUpdating,
    renderConfirmationText: () => (
      <>
        <Text block size="h5" color={colors.neutral900}>
          You are about to restore <b>{name}</b>. Once restored, it will again be discoverable,
          unless you {isScheduleTrack ? 'cancel' : 'archive'} it again.
        </Text>
        <Text block size="h5" color={colors.neutral900}>
          This action will <b>not</b> implicitly restore any underlying {trackItemsLabel}, change
          any {isScheduleTrack ? 'assignments/enrollments' : 'assignments'}, nor send any
          notifications. It is up to you if you wish to do so explicitly.
        </Text>
        <Text block size="h5" color={colors.neutral900}>
          Are you sure you wish to proceed?
        </Text>
      </>
    ),
  };
};

export const useCreateTrackSection = (trackSections, trackItems, changeFn) => {
  const createTrackSection = () => {
    const newSection = {
      id: uuid4(),
      name: `Section ${size(trackSections) + 1}`,
      description: null,
      items: [],
    };

    if (!isEmpty(trackSections)) {
      changeFn('sections', [...trackSections, newSection]);
      return;
    }

    const anotherSection = {
      id: uuid4(),
      name: `Section ${size(trackSections) + 2}`,
      description: null,
      items: [],
    };
    const mappedItems = map(trackItems, (item) => ({ ...item, section: newSection.id }));

    changeFn('sections', [...trackSections, newSection, anotherSection]);
    if (mappedItems) changeFn('track_items', mappedItems);
  };

  return createTrackSection;
};

export const useAddTrackItems = (trackItems: TrackItem[], changeFn) => {
  const existingTrackItemIdsSet = useMemo(() => {
    return new Set<number>(map(trackItems, (item) => get(item, 'content_item.id')));
  }, [trackItems]);

  const handleAddNewItems = (section: TrackSection, newContentItems: TrackContentItem[]) => {
    const filteredItems = filter(
      newContentItems,
      (item) => !existingTrackItemIdsSet.has(get(item, 'id'))
    );

    const newTrackItems: TrackItem[] = map(filteredItems, (item, index) => ({
      content_item: item,
      is_required: true,
      order: trackItems.length + index,
    }));

    // If there is not a section, the item will be just added to the end of the track
    const newTrackItemsLinked = isEmpty(section)
      ? [...trackItems, ...newTrackItems]
      : [
          ...trackItems,
          ...map(newTrackItems, (item) => ({ ...item, section: section.id, is_required: true })),
        ];

    changeFn('track_items', newTrackItemsLinked);
  };

  return handleAddNewItems;
};

export const useUpdateTrackItemContent =
  (trackItems: TrackItem[], changeFn) => (edited: TrackContentItem) => {
    const trackItemsEdited = cloneDeep(trackItems);

    const index = findIndex(
      trackItemsEdited,
      (trackItem) => trackItem.content_item.id === edited.id
    );
    if (index >= 0) {
      trackItemsEdited.splice(index, 1, { ...trackItemsEdited[index], content_item: edited });
      changeFn('track_items', trackItemsEdited);
    }
  };

export const useUpdateTrackItem = (trackItems: TrackItem[], changeFn) => (edited: TrackItem) => {
  const trackItemsEdited = cloneDeep(trackItems);

  const index = findIndex(trackItemsEdited, (trackItem) => trackItem.id === edited.id);
  if (index >= 0) {
    trackItemsEdited.splice(index, 1, edited);
    changeFn('track_items', trackItemsEdited);
  }
};

export const useTrackSectionsAndItems = (
  sections: FullTrackSection[] = [],
  items: TrackItem[] = []
) => {
  const sectionsAndItemsOrderedList: (FullTrackSection | TrackItem)[] = [];

  forEach(items, (item) => {
    const section = find(sections, ['id', item?.section]);
    const sectionIsIncluded = find(sectionsAndItemsOrderedList, ['id', item?.section]);
    if (section && !sectionIsIncluded) {
      sectionsAndItemsOrderedList.push(section);
    }
    sectionsAndItemsOrderedList.push(item);
  });

  const sectionsAndItemsIndexesMapping: Record<string, number> = {};
  forEach(sectionsAndItemsOrderedList, (value, index) => {
    // TrackSection has no 'public_id', just 'id'. TrackItem has both.
    const key = get(value, 'content_item.public_id') || get(value, 'id') || '';
    sectionsAndItemsIndexesMapping[key] = index;
  });
  const sectionsWithItems: FullTrackSection[] = map(sections, (section) => {
    return { ...section, items: filter(items, ['section', section?.id]) };
  });

  const nonEmptySectionsWithItems: FullTrackSection[] = filter(
    sectionsWithItems,
    (section) => !isEmpty(section?.items)
  );

  return {
    sectionsAndItemsOrderedList,
    sectionsAndItemsIndexesMapping,
    sectionsWithItems,
    nonEmptySectionsWithItems,
  };
};

export const useDestructiveActions = (
  trackSections,
  trackItems,
  changeFn,
  trackType: trackTypes
) => {
  const { label_track: labelTrack, label_assessment: labelAssessment } = useLabels();
  const label = trackType === CONTENT_TYPES.assessment ? labelAssessment : labelTrack;

  const handleRemoveAll = () => {
    changeFn('sections', []);
    changeFn('track_items', []);
    toast.success(
      `All sections and items removed from ${label}.`,
      'This change only takes effect after you publish.'
    );
  };

  const handleRemoveAllSections = () => {
    const newItems = map(trackItems, ({ section: _, ...rest }) => ({ ...rest }));

    changeFn('sections', []);
    changeFn('track_items', newItems);
    toast.success(
      `All sections removed from ${label}.`,
      'This change only takes effect after you publish.'
    );
  };

  const handleRemoveAllItems = () => {
    changeFn('track_items', []);
    toast.success(
      `All items removed from ${label}.`,
      'This change only takes effect after you publish.'
    );
  };

  const handleRemoveSection = (section) => {
    const newSections = filter(trackSections, (trackSection) => trackSection.id !== section.id);
    const newItems = filter(trackItems, (trackItem) => trackItem.section !== section.id);

    changeFn('sections', newSections);
    changeFn('track_items', newItems);
    toast.success(
      `"${section.name}" and its items removed from ${label}.`,
      'This change only takes effect after you publish.'
    );
  };

  const handleRemoveItemsFromSection = (section) => {
    const newItems = filter(trackItems, (trackItem) => trackItem.section !== section.id);
    changeFn('track_items', newItems);
    toast.success(
      `Items removed from "${section.name}".`,
      'This change only takes effect after you publish.'
    );
  };

  const handleRemoveItem = (item, doToast = true) => {
    const newItems = filter(
      trackItems,
      (listItem) => get(listItem, 'content_item.id') !== get(item, 'content_item.id')
    );
    changeFn('track_items', newItems);
    if (doToast) {
      toast.success(
        `"${item.content_item.name}" removed from ${label}.`,
        'This change only takes effect after you publish.'
      );
    }
  };

  return {
    handleRemoveAll,
    handleRemoveAllSections,
    handleRemoveAllItems,
    handleRemoveSection,
    handleRemoveItemsFromSection,
    handleRemoveItem,
  };
};

export const useTrackItemArchiveUnarchive = (
  trackItems: TrackItem[],
  changeFn,
  trackType: trackTypes
) => {
  const { label_question: labelQuestion } = useLabels();
  const label = trackType === CONTENT_TYPES.assessment ? labelQuestion : 'Item';

  const [item, setItem] = useState<TrackItem>();
  const updateTrackItem = useUpdateTrackItem(trackItems, changeFn);

  const [archive, { status: archiveStatus }] = useEntities(
    actions.trackItem.archive,
    ({ status, data }) => {
      if (status === STATUS_DONE && item) {
        // Track Item does not have a retrieve endpoint, so we manually update the store here instead of refreshing
        // See https://github.com/plusplusco/plusplus/blob/0d540b0f36ca05c7098ad196744e7996ceb3bc7f/frontend/app/entities/schema.js#L96-L97
        updateTrackItem({ ...item, archival: data });

        toast.success(`${label} archived successfully`);
      } else if (status === STATUS_ERROR) {
        toast.error('Error', 'Please try again later');
      }
    }
  );

  const [unarchive, { status: unarchiveStatus }] = useEntities(
    actions.trackItem.unarchive,
    ({ status }) => {
      if (status === STATUS_DONE && item) {
        updateTrackItem({ ...item, archival: undefined });

        toast.success(`${label} restored successfully`);
      } else if (status === STATUS_ERROR) {
        toast.error('Error', 'Please try again later');
      }
    }
  );

  const handleArchiveItem = (trackItem: TrackItem) => {
    if (archiveStatus !== STATUS_LOADING) {
      setItem(trackItem);
      archive(trackItem.id!);
    } else {
      toast.error('Error', 'Please await finishing the previous archival');
    }
  };

  const handleUnarchiveItem = (trackItem: TrackItem) => {
    if (unarchiveStatus !== STATUS_LOADING) {
      setItem(trackItem);
      unarchive(trackItem.id!);
    } else {
      toast.error('Error', 'Please await finishing the previous restoration');
    }
  };

  return { handleArchiveItem, handleUnarchiveItem, unarchiveStatus };
};

export const useTrackForm = (
  form: string,
  trackType: trackTypes,
  isEdit: boolean,
  selectedOfficeHour: any,
  change: (fieldName: string, value: any) => void,
  initialValues: any = {}
) => {
  const {
    label_track: labelTrack,
    label_track_plural: labelTrackPlural,
    label_assessment: labelAssessment,
    label_assessment_plural: labelAssessmentPlural,
  } = useLabels();
  return useMemo(() => {
    if (trackType === CONTENT_TYPES.assessment) {
      return {
        labelModel: labelAssessment,
        labelModelPlural: labelAssessmentPlural,
        toggleTypes: ['toggle_assessments'],
        settingsName: 'assessment/form',
        // TODO ASSESSMENT: Help link
        HCArticleURL:
          'https://help.plusplus.app/en/articles/6929125-plusplus-101-measure-learning-and-competencies-with-assessments',
        infoPanelText: `New to ${labelAssessmentPlural}?`,
        extraSections: [
          {
            id: 'assessment_settings',
            label: `${labelAssessment} Settings`,
            icon: 'cog',
            section: <AssessmentSettingsSection form={form} changeFn={change} />,
            sectionProps: {
              defaultOpen: true,
            },
          },
        ],
        advancedSettingsSections: [
          {
            id: 'access-control',
            label: 'Access Control',
            icon: 'lock',
            section: (
              <AccessControlSection
                showHideEnrolleesSection={false}
                contentNameSingular={labelAssessment}
                accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
                channel={initialValues.channel}
                hideAttendeesInfoText={`Only admins, maintainers, and assignees see who is assigned to this ${toLower(
                  labelAssessment
                )}. Remember ${toLower(
                  labelAssessmentPlural
                )} don't automatically propagate policies to its nested track items.`}
              />
            ),
            sectionProps: {
              defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
            },
          },
        ],
      };
    }
    return {
      labelModel: labelTrack,
      labelModelPlural: labelTrackPlural,
      toggleTypes: ['toggle_tracks'],
      settingsName: 'track/form',
      HCArticleURL:
        'https://help.plusplus.app/en/articles/6057221-plusplus-101-offer-sequential-learning-playbooks-with-tracks',
      infoPanelText: `${labelTrackPlural} are a hub for a variety of self paced contents, including the flexible snippets but also event types, courses, videos, articles, and cloud docs.`,
      extraSections: [
        {
          id: 'completion',
          label: 'Completion',
          icon: 'clock',
          section: <CompletionSection form={form} labelContentType={labelTrack} change={change} />,
          sectionProps: {
            defaultOpen: true,
          },
        },
        {
          id: 'resources',
          label: 'Resources',
          icon: 'teach',
          section: (
            <ResourcesSection
              formName={form}
              selectedOfficeHour={selectedOfficeHour}
              usePublicId={false}
            />
          ),
          sectionProps: {
            defaultOpen: true,
          },
        },
      ],
      advancedSettingsSections: [
        {
          id: 'surveys',
          label: 'Surveys',
          icon: 'survey',
          section: (
            <SurveysSection
              contentType={CONTENT_TYPES.track}
              formName={form}
              assignmentsCount={initialValues.assignments_count}
              isEditing={isEdit}
            />
          ),
          sectionProps: {
            defaultOpen:
              !isEmpty(initialValues.survey_relationships) ||
              !isEmpty(initialValues.external_survey_link),
          },
        },
        {
          id: 'access-control',
          label: 'Access Control',
          icon: 'lock',
          section: (
            <AccessControlSection
              showHideEnrolleesSection={false}
              contentNameSingular={labelTrack}
              accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
              channel={initialValues.channel}
              hideAttendeesInfoText={`Only admins, maintainers, and assignees see who is assigned to this ${toLower(
                labelTrack
              )}. Remember ${toLower(
                labelTrackPlural
              )} don't automatically propagate policies to its nested track items.`}
            />
          ),
          sectionProps: {
            defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
          },
        },
      ],
    };
  }, [
    form,
    trackType,
    isEdit,
    selectedOfficeHour,
    change,
    initialValues,
    labelTrack,
    labelTrackPlural,
    labelAssessment,
    labelAssessmentPlural,
  ]);
};

interface UseFetchTrackItemsProps {
  trackType: string;
  trackId: number;
  section?: string;
  pageSize?: number;
  viewMode?: string;
  skipSchema?: boolean;
}

export const useFetchTrackItems = ({
  trackType,
  trackId,
  section,
  pageSize,
  viewMode = 'summary',
  skipSchema = false,
}: UseFetchTrackItemsProps) => {
  const [fetchTrackItems, state, fetchMoreTrackItems] = useEntities(
    actions.trackItem.retrieveList,
    null,
    {
      schema: [trackItemSchema],
      loadMoreAction: actions.trackItem.retrieveListLoadMore,
    }
  );

  const trackItems = skipSchema ? state.data.results : state.data;
  const count = skipSchema ? state.data.count : state.count;
  const status = state.status;

  const cachedFetchTrackItems = useCallback(() => {
    fetchTrackItems(
      {
        track: trackId,
        page_size: pageSize,
        content_type: trackType,
        view_mode: viewMode,
        section,
      },
      { skipSchema }
    );
  }, [trackId, trackType, section, pageSize, viewMode, skipSchema, fetchTrackItems]);

  useEffect(() => {
    cachedFetchTrackItems();
  }, [cachedFetchTrackItems]);

  return { trackItems, status, count, fetchMoreTrackItems };
};

export const useFetchDescendantTrackItems = () => {
  const [
    fetchDescendantTrackItems,
    { data: descendantTrackItems, status: fetchDescendantTrackItemsStatus },
  ] = useEntities(actions.track.retrieveDescendantTrackItems, null, {
    schema: [trackItemSchema],
  });

  const cachedFetchDescendantTrackItems = useCallback(
    (trackPublicId) => {
      fetchDescendantTrackItems(trackPublicId);
    },
    [fetchDescendantTrackItems]
  );

  return {
    fetchDescendantTrackItems: cachedFetchDescendantTrackItems,
    descendantTrackItems,
    fetchDescendantTrackItemsStatus,
  };
};
