import React, { useEffect } from 'react';

import { useContentTypeRoutes } from '~/app/catalog/hooks';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import { permissionDeniedRedirectAlert } from '~/services/permission-denied-redirect-alert';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useLabels } from '~/app/shared/hooks';
import {
  CONTENT_ITEM_PERMISSIONS,
  EDIT_EVENT_TYPE_PERMISSION,
  EDIT_ARTICLE_PERMISSION,
  EDIT_VIDEO_PERMISSION,
  EDIT_CODELAB_PERMISSION,
  EDIT_COURSE_PERMISSION,
} from '~/app/shared/permissions';
import { isEmpty, intersection } from 'lodash-es';
import { useCurrentUser } from '~/app/shared/hooks';
import {
  IS_ADMIN_PERMISSION,
  IS_ORGANIZER_PERMISSION,
  IS_SUPERUSER_PERMISSION,
} from '~/app/shared/permissions';

interface ContentItemContainerProps {
  children?: React.ReactNode;
  content: ContentItem;
  isRenderedWithinTrack?: boolean;
}

const ContentItemContainer = ({
  children,
  content,
  isRenderedWithinTrack = false,
}: ContentItemContainerProps) => {
  const { label_track_plural: labelTrackPlural } = useLabels();
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const contentTypeRoute = contentTypeRoutes[content.content_type];
  const currentUser = useCurrentUser();

  // I have to use both public_id and public_id_and_slug here because the edit links are inconsistent across different content types
  const linkToEdit = contentTypeRoute.edit({
    public_id: content.public_id,
    public_id_and_slug: content.public_id_and_slug,
  });

  const { is_inline: isInline } = content;

  const userHasPermission = !isEmpty(
    intersection(
      [IS_ADMIN_PERMISSION, IS_ORGANIZER_PERMISSION, IS_SUPERUSER_PERMISSION],
      currentUser.permissions
    )
  );

  // Since we haven't migrated fully to API2.0, we need to check individually for permissions. Assessments, Tracks and LinkedContents have been migrated so they just use the manage permission.
  // TODO: API2.0 Remove outdated permissions as the details pages are migrated.
  const hasPermission = !isEmpty(
    intersection(
      [
        CONTENT_ITEM_PERMISSIONS.manage,
        EDIT_EVENT_TYPE_PERMISSION,
        EDIT_ARTICLE_PERMISSION,
        EDIT_VIDEO_PERMISSION,
        EDIT_CODELAB_PERMISSION,
        EDIT_COURSE_PERMISSION,
      ],
      content.permissions
    )
  );

  useEffect(() => {
    if (!isInline || isRenderedWithinTrack || userHasPermission) return;

    permissionDeniedRedirectAlert({
      title: 'Inline Item',
      content: (
        <p>
          This item can be only found and consumed within {labelTrackPlural}, so you will be
          redirected to the home page.{' '}
          {hasPermission && (
            <>
              If you think this is a mistake, please&nbsp;
              <a href={linkToEdit}>edit</a> the item and disable &quot;inline&quot;.
            </>
          )}
        </p>
      ),
    });
  }, [isInline, isRenderedWithinTrack, labelTrackPlural, hasPermission, linkToEdit]);

  return <BaseContainer>{children}</BaseContainer>;
};

export default ContentItemContainer;
