import * as React from 'react';

export function useRemoveDjangoApp() {
  const handleRemoveDjangoApp = React.useCallback(() => {
    const djangoAppEl = document.getElementById('plusplus-django-template');
    const reactAppEl = document.getElementById('react-app');

    if (djangoAppEl) {
      djangoAppEl.remove();
    }

    if (reactAppEl) {
      const hasHybridPageClass = reactAppEl.classList.contains('hybrid-page-class');

      if (!hasHybridPageClass) {
        reactAppEl.classList.add('hybrid-page-class');
      }
    }
  }, []);

  return handleRemoveDjangoApp;
}
