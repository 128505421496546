import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { DASHBOARD_TYPES } from '~/scenes/Dashboard/constants';
import { map, find } from 'lodash-es';
import { queries } from '~/queries';
import { useQuery } from '@tanstack/react-query';

import AutocompleteField from '../AutocompleteField';

const pageSize = 25;

const makeOptions = (data) => {
  return map(data.results, ({ public_id, name, expression, content_type }) => ({
    value: public_id,
    label: name,
    expression,
    content_type,
    public_id,
  }));
};

const PeopleSegmentSelectField = ({ contentType, setSelectedSegment, input, ...props }) => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const { value } = input;

  const [queryParams, setQueryParams] = useState({
    view_mode: 'filter_options',
    page_size: pageSize,
    content_type: DASHBOARD_TYPES.people,
  });

  const { data: options, isLoading } = useQuery({
    ...queries.segments.list(queryParams),
    select: makeOptions,
    enabled: shouldFetch,
  });

  useEffect(() => {
    if (!setSelectedSegment) return;

    const selectedOption = find(options, (segment) => segment.value === value);
    setSelectedSegment(selectedOption);
  }, [value, options]);

  const fetchOptions = ({ q, includeValues }) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      q,
      include_public_ids: includeValues,
    }));

    setShouldFetch(true);
  };

  return (
    <AutocompleteField
      options={options}
      loading={isLoading}
      input={input}
      fetchOptions={fetchOptions}
      {...props}
    />
  );
};

PeopleSegmentSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  contentType: PropTypes.object,
  setSelectedSegment: PropTypes.func,
};

export default PeopleSegmentSelectField;
