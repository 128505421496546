import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { join, size } from 'lodash-es';
import queryString from 'query-string';

import { listQueryKeyFactory } from '~/app/data-fetching/utils';
import { apiGet, apiPost } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';

const fetchUserFilters = async ({ initial_filters, filters_to_exclude }) => {
  const { data: response } = await apiGet('api_coaching:mentorship_mentor_filters', null, {
    initial_filters: initial_filters,
    filters_to_exclude: filters_to_exclude,
  });
  return response;
};

const fetchUsersByEmails = async (emails: string[]) => {
  let apiMethod;
  let emailsFilter: string | string[];
  if (size(emails) < 200) {
    apiMethod = apiGet;
    emailsFilter = join(emails, ',');
  } else {
    apiMethod = apiPost;
    emailsFilter = emails;
  }
  const { data: response } = await apiMethod('api_users:users_by_email', null, {
    emails: emailsFilter,
  });
  return response;
};

export const users = createQueryKeys('users', {
  list: listQueryKeyFactory({ endpointName: 'api_users:users_rql' }),
  departments: listQueryKeyFactory({ endpointName: 'api_users:departments' }),
  titles: listQueryKeyFactory({ endpointName: 'api_users:titles' }),
  filter_by_emails: (emails) => ({
    queryKey: [emails],
    queryFn: async () => fetchUsersByEmails(emails),
  }),
  profile_enrichment: (username) => ({
    queryKey: [username],
    queryFn: async () => {
      const url = ApiURLs['users_api:user_profile_enrichment'](username);
      const { data } = await fetchURL(url);
      return data;
    },
  }),
  filters: (params) => ({
    queryKey: [params],
    queryFn: () => fetchUserFilters(params),
  }),
  active_users: (params) => ({
    queryKey: [params],
    queryFn: async () => {
      const url = ApiURLs['users_api:active_users_list']();
      const qs = queryString.stringify(params);

      const { data } = await fetchURL(`${url}?${qs}`);
      return data;
    },
  }),
  detail: (id: number, viewMode?: string) => ({
    queryKey: [id, viewMode],
    queryFn: async () => {
      let url = ApiURLs['api_users:user'](id);

      if (viewMode) {
        url += `?view_mode=${viewMode}`;
      }

      const { data } = await fetchURL(url);
      return data;
    },
  }),
});

export type UsersQueryKeys = inferQueryKeys<typeof users>;
