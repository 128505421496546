import { SvgIcon } from '@mui/material';
import { GridActionsCellItem, DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';

import { buildSegmentData, DASHBOARD_SEGMENT_ACTIONS } from '~/app/backoffice/services';
import ButtonLink from '~/app/shared/components/ButtonLink';
import Icon from '~/app/shared/components/Icon';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody } from '~/app/shared/components/Modal';
import { map, keys } from 'lodash-es';

import DeleteSegmentModal from './DeleteSegmentModal';
import SaveSegmentModal from './SaveSegmentModal';

import { queries } from '~/queries';
import { useQuery } from '@tanstack/react-query';
import Page404 from '~/scenes/404';

const SEGMENTS_PER_PAGE = 7;

const makeSegmentOptions = (results) => {
  return map(results, (segment) => {
    const { public_id: id, name, created, created_by, url } = buildSegmentData(segment);
    return { id, name, created, created_by, url, segment };
  });
};

const makeOptions = (data) => {
  return {
    count: data.count,
    rows: makeSegmentOptions(data.results),
  };
};

const SegmentsBusyStateModal = ({ metadata: { label, type }, handleClose }) => {
  // OPTIMIZATION: pass the same hook instance that's being used to handle
  // the segments in the dashboard sidebar, so we can simplify the logic.
  // It would allows us, i.e., to call only one refresher function in the
  // edit/delete modals (we could actually remove the edit/delete modals
  // from this component and use only the ones from the PageContent component.
  // CHALLENGES: make sure we won't enter an infinite looping of re-renders
  // due to multiple fetches being repeated
  const history = useHistory();

  const [showEditSegmentModal, setShowEditSegmentModal] = useState(false);
  const [editSegmentModalMetadata, setEditSegmentModalMetadata] = useState({});

  const [showDeleteSegmentModal, setShowDeleteSegmentModal] = useState(false);
  const [deleteSegmentModalMetadata, setDeleteSegmentModalMetadata] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(SEGMENTS_PER_PAGE);

  const memoizedQueryParams = useMemo(
    () => ({
      content_type: type,
      page: currentPage + 1,
      page_size: SEGMENTS_PER_PAGE,
    }),
    [currentPage]
  );

  const {
    data: { count: segmentsCount, rows: segments } = {},
    isLoading,
    isError,
  } = useQuery({
    ...queries.segments.list(memoizedQueryParams),
    select: makeOptions,
  });

  const modalsManager = {
    edit: {
      setter: setShowEditSegmentModal,
      metadataSetter: setEditSegmentModalMetadata,
    },
    remove: {
      setter: setShowDeleteSegmentModal,
      metadataSetter: setDeleteSegmentModalMetadata,
    },
  };

  if (isError) return <Page404 />;

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterable: false,
      sortable: false,
      editable: false,
      renderCell: ({ row: { name, url } }) => {
        return (
          <ButtonLink
            onClick={() => {
              history.push(url);
              handleClose();
            }}
          >
            {name}
          </ButtonLink>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Created at',
      width: 160,
      filterable: false,
      sortable: false,
      editable: false,
    },
    {
      field: 'created_by',
      headerName: 'Created by',
      width: 150,
      filterable: false,
      sortable: false,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 20,
      sortable: false,
      editable: false,
      getActions: ({ row: { id, segment } }) => {
        return map(keys(DASHBOARD_SEGMENT_ACTIONS), (actionName) => {
          const action = DASHBOARD_SEGMENT_ACTIONS[actionName];
          return (
            <GridActionsCellItem
              key={`${actionName}-${id}-busy`}
              label={action.title}
              icon={
                <SvgIcon fontSize="small">
                  <Icon name={action.icon} />
                </SvgIcon>
              }
              onClick={() => {
                const manager = modalsManager[actionName];
                manager.setter(true);
                manager.metadataSetter(segment);
              }}
              showInMenu
            />
          );
        });
      },
    },
  ];

  return (
    <>
      <Modal title={`All ${label} Segments`} handleClose={handleClose} width={600}>
        <ModalBody>
          {isLoading ? (
            <Loading />
          ) : (
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader:nth-last-child(2) .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& p.MuiTablePagination-displayedRows': {
                  margin: 0,
                  fontWeight: 500,
                },
              }}
              loading={isLoading}
              columns={columns}
              rows={segments}
              rowCount={segmentsCount}
              pagination
              pageSize={SEGMENTS_PER_PAGE}
              paginationModel={{
                page: currentPage,
                pageSize: currentPageSize,
              }}
              onPaginationModelChange={(model) => {
                setCurrentPage(model.page);
                setCurrentPageSize(model.pageSize);
              }}
              paginationMode="server"
              disableRowSelectionOnClick
            />
          )}
        </ModalBody>
      </Modal>
      {showEditSegmentModal && (
        <SaveSegmentModal
          segment={editSegmentModalMetadata}
          handleClose={() => {
            setShowEditSegmentModal(false);
            setEditSegmentModalMetadata({});
          }}
        />
      )}
      {showDeleteSegmentModal && (
        <DeleteSegmentModal
          segment={deleteSegmentModalMetadata}
          handleClose={() => {
            setShowDeleteSegmentModal(false);
            setDeleteSegmentModalMetadata({});
          }}
        />
      )}
    </>
  );
};

SegmentsBusyStateModal.propTypes = {
  metadata: PropTypes.object,
  handleClose: PropTypes.func,
};

export default SegmentsBusyStateModal;
