import { QueryClient, MutationCache } from '@tanstack/react-query';
import { DEFAULT_STALE_TIME } from '../constants/queryClient';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }),
});
