import { Paper, type PaperProps, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

type InsightWidgetHeaderProps = {
  icon: React.ReactNode;
  title: string;
};

function InsightWidgetHeader(props: InsightWidgetHeaderProps) {
  const { title, icon } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
      }}
    >
      {icon}
      <Typography
        variant="h6"
        sx={{
          fontSize: '1rem',
          fontWeight: '700',
          lineHeight: 1.75,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

const InsightWidget = styled(Paper)<PaperProps>({
  backgroundColor: grey[50],
  padding: '24px',
  paddingTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export { InsightWidget, InsightWidgetHeader };
