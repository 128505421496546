import { useQuery } from '@tanstack/react-query';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/constants/queryClient';
import { queries } from '~/queries';
import { isEmbeddedVideoUrl } from '~/services/embed';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useCurrentUser } from '~/app/shared/hooks';
import { get } from 'lodash-es';

export const useIsProgressableVideo = (contentItem: ContentItem): boolean | null => {
  const currentUser = useCurrentUser();
  // only fetch data for content items of type video
  const enabled = contentItem.content_type == CONTENT_TYPES.video;
  const { data } = useQuery({
    ...queries.videos.detail(contentItem.public_id, 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
    retry: 0,
    enabled: enabled,
  });

  if (!data) {
    return null;
  }
  return isEmbeddedVideoUrl(get(data, 'video_url'), get(currentUser, 'allowed_video_url_pattern'));
};
