import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { listQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import queryString from 'query-string';

const DEFAULT_RQL_EXPRESSION = '?page=1&page_size=15';

const fetchSegmentList = async (filters = {}) => {
  let url = ApiURLs['segments_api:list_create']();

  if (filters) {
    url += `?${queryString.stringify(filters)}`;
  }

  const { data: response } = await fetchURL(url);
  return response;
};

const fetchWithRql = async (endpoint: string, public_id: string, rqlExpression?: string) => {
  const url = ApiURLs[endpoint]({ public_id });
  const finalRqlExpression = rqlExpression || DEFAULT_RQL_EXPRESSION;
  const { data: response } = await fetchURL(url + finalRqlExpression);
  return response;
};

const fetchSegmentReport = async (public_id: string) => {
  const url = ApiURLs['segments_api:report']({ public_id });
  const { data: response } = await fetchURL(url);
  return response;
};

const fetchSegmentDetail = async (public_id: string) => {
  const url = ApiURLs['segments_api:retrieve_update_destroy']({ public_id });
  const { data: response } = await fetchURL(url);
  return response;
};

export const segments = createQueryKeys('segments', {
  enrollment: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('segments_api:enrollment', public_id, rqlExpression),
  }),
  event: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('segments_api:event', public_id, rqlExpression),
  }),
  assignment: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('segments_api:assignment', public_id, rqlExpression),
  }),
  people: (public_id: string, rqlExpression: string) => ({
    queryKey: [public_id, rqlExpression],
    queryFn: () => fetchWithRql('segments_api:people', public_id, rqlExpression),
  }),
  report: (public_id: string) => ({
    queryKey: [public_id],
    queryFn: () => fetchSegmentReport(public_id),
  }),
  list: (filters = {}) => ({
    queryKey: ['segments', filters],
    queryFn: () => fetchSegmentList(filters),
  }),
  detail: (public_id: string) => ({
    queryKey: ['segment', public_id],
    queryFn: () => fetchSegmentDetail(public_id),
  }),
  dashboard: listQueryKeyFactory({ endpointName: 'segments_api:dashboard' }),
});

export type SegmentsQueryKeys = inferQueryKeys<typeof segments>;
