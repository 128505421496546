import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import Loading from '~/app/shared/components/Loading';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/constants/queryClient';
import { ComposableTrackProvider } from '~/features/tracks/contexts/ComposableTrackCtx';
import { ScheduledTrack } from '~/features/tracks/types';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import TrackPageContainer from '~/scenes/TrackPage/TrackPageContainer';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { usePublicIdFromURL, useQueryParams, useSetDetailedObject } from '~/app/shared/hooks';

const ScheduledTrackDetail = () => {
  const { publicId } = usePublicIdFromURL();

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { trackActivity } = useMetrics();

  useSetDetailedObject({ id: publicId }, 'scheduled_tracks', []);

  const { data: scheduledTrack, status } = useQuery<any, any, ScheduledTrack>({
    ...queries.content_items.details(publicId || '', 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
  });

  React.useEffect(() => {
    if (status === 'success' && page === 0) {
      trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
        contentItemId: scheduledTrack.id,
        contentItemType: scheduledTrack.content_type,
      });
    }
  }, [status, scheduledTrack, page]);

  if (status === 'error') {
    return <Page404 />;
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <ComposableTrackProvider track={scheduledTrack}>
      <TrackPageContainer track={scheduledTrack} entityType={LEARNING_TYPES.scheduled_tracks} />
    </ComposableTrackProvider>
  );
};

export default ScheduledTrackDetail;
