/* eslint-disable lodash/prefer-lodash-method */
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import { toast } from '~/app/notifications/components/NotificationCenter';
import { DASHBOARD_TYPES_URL_MAPPING } from '~/scenes/Dashboard/constants';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { useDeleteSegment } from '~/features/segments/api/mutations';
import { to } from '~/common/helpers';
import { getErrorMessageFromResponse } from '~/services/requests';
import { useParams } from 'react-router';

const DeleteSegmentModal = ({ segment, handleClose }) => {
  const history = useHistory();

  const { public_id: segmentPublicId } = useParams();
  const { mutateAsync: deleteSegmentMutation, isLoading } = useDeleteSegment(segment.public_id);

  const deleteSegment = async () => {
    const [err] = await to(deleteSegmentMutation());

    if (err) {
      const errorMessage = getErrorMessageFromResponse(err);
      toast.error(errorMessage);
      return;
    }

    const segmentBaseUrl = mapRoute(DASHBOARD_TYPES_URL_MAPPING[segment.content_type]);
    // Only redirect the user to the segment type main dashboard
    // page if they were in the deleted segment page
    if (segmentPublicId === segment.public_id) history.replace(segmentBaseUrl);
    toast.success('Segment successfully removed.');
    handleClose();
  };

  return (
    <Modal handleClose={handleClose} title={`Delete "${segment.name}"`}>
      <ModalBody>
        <Text block size="h5" colors={colors.neutral900}>
          You&#39;re about to remove the &quot;{segment.name}&quot; segment.
        </Text>
        <Text block size="h5" colors={colors.neutral900}>
          Are you sure you want to do this?
        </Text>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
        <ModalFooterButton
          color="error"
          onClick={() => deleteSegment()}
          disabled={isLoading}
          aria-label="Delete"
          data-cy="delete-segment-button"
        >
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DeleteSegmentModal.propTypes = {
  segment: PropTypes.object,
  handleClose: PropTypes.func,
};

export default DeleteSegmentModal;
