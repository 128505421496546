import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import * as constants from '~/app/catalog/constants';
import { CHANNEL } from '~/app/catalog/constants';
import { parseResources } from '~/app/content-resources/services';
import { programSelector } from '~/app/entities/selectors';
import ChannelSelectField from '~/app/inputs/components/ChannelSelectField';
import CoverImageField from '~/app/inputs/components/CoverImageField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import FacilitatorField from '~/app/inputs/components/FacilitatorField';
import InlineItemField from '~/app/inputs/components/InlineItemField';
import InputLabel from '~/app/inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from '~/app/inputs/components/OldTextEditorField';
import TagTypesInputFields from '~/app/inputs/components/TagTypesInputFields';
import TextField from '~/app/inputs/components/TextField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { useSettingsSectionsList } from '~/app/settings/hooks';
import { prepareSettingsValuesForSubmission } from '~/app/settings/services';
import { FormFieldGroup } from '~/app/shared/components/Form';
import NewContentForm from '~/app/shared/components/NewContentForm';
import CompletionSection from '~/app/shared/components/NewContentForm/CompletionSection';
import AccessControlSection from '~/app/shared/components/NewContentForm/sections/AccessControlSection';
import ResourcesSection from '~/app/shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from '~/app/shared/components/NewContentForm/sections/SurveysSection';
import Form from '~/app/shared/components/OldForm';
import {
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useToggles,
  useChannelToggle,
} from '~/app/shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import { concat, get, isNil, map, pick, toLower, isEmpty, filter } from 'lodash-es';

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

export const ArticleForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  selectedOfficeHour,
  isModalForm,
  currentUser,
}) => {
  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();
  useFormPreventTransition(form);
  const {
    label_article: labelArticle,
    label_article_plural: labelArticlePlural,
    label_track_plural: labelTrackPlural,
    label_channel: labelChannel,
  } = useLabels();

  const articleName = useFormSelector(form, 'name');
  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const HCArticleURL =
    'https://help.plusplus.app/en/articles/6104472-plusplus-101-getting-started-with-articles';

  const infoPanelText = `${labelArticlePlural} allow you to combine various forms of content (written, media, and embedded) within a single page. They can be added to ${toLower(
    labelTrackPlural
  )} and individually assigned to people.`;

  const canAddChannelContent = useChannelToggle();

  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  const [settingsSectionsList] = useSettingsSectionsList('article/form', form);

  const articleSectionsList = [
    {
      id: 'inline',
      label: 'Inline Item',
      icon: 'inline',
      section: <InlineItemField />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: 'article-details',
      label: `${labelArticle} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              label="Title"
              name="name"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelArticle)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="article_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Content</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowConfluenceDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              inputId="duration"
              name="duration"
              label="Estimated Duration (H:MM)"
              component={DurationField}
              validate={[validateDuration]}
              disableClearable={false}
              aditionalOptions={[{ label: '0:10', value: '0:10' }]}
            />
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_articles']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_articles']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: <FacilitatorField label="Author(s)" />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: `Completion`,
      icon: 'clock',
      section: (
        <CompletionSection
          form={form}
          labelContentType={labelArticle}
          change={change}
          optionallyCompletable
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'resources',
      label: `Resources`,
      icon: 'teach',
      section: (
        <ResourcesSection
          formName={form}
          selectedOfficeHour={selectedOfficeHour}
          usePublicId={false}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={constants.CONTENT_TYPES.article}
          formName={form}
          assignmentsCount={initialValues.assignments_count}
          isEditing={isEdit}
        />
      ),
      sectionProps: {
        defaultOpen:
          !isEmpty(initialValues.survey_relationships) ||
          !isEmpty(initialValues.external_survey_link),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={labelArticle}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          showAttendeesSection
          channel={initialValues.channel}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  return (
    <NewContentForm
      contentNameSingular={labelArticle}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCArticleURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={articleName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={articleSectionsList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={isModalForm}
    />
  );
};

ArticleForm.defaultProps = {
  initialValues: {},
};

ArticleForm.propTypes = {
  form: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,

  selectedOfficeHour: PropTypes.object,
  isModalForm: PropTypes.bool,

  currentUser: PropTypes.object,
};

const ConnectedArticleForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: async (values, _dispatch, { onSubmitHandler, isEdit, currentUser }) => {
    const payload = {
      ...pick(values, [
        'content_body',
        'cover',
        'duration',
        'external_survey_link',
        'facilitators_ids',
        'groups_ids',
        'hide_attendees',
        'is_completable',
        'is_hidden',
        'is_inline',
        'name',
        'office_hour_id',
        'resources',
        'survey_relationships',
        'time_to_complete',
        'time_to_retake',
        'topics',
        'channel_id',
      ]),
    };

    if (!payload.channel_id) {
      delete payload.channel_id;
    }

    const customTopicsList = buildCustomTopicsList({
      user: currentUser,
      toggleTypes: ['toggle_articles'],
      flexibleTags: pick(
        values,
        map(
          currentUser?.custom_tags.flexible_filter_tags,
          (flexibleTag) => flexibleTag.filter_field_name
        )
      ),
      tags: values.tags,
    });

    const tags = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

    payload.tags = filter(concat(tags, customTopicsList), Boolean);
    payload.facilitators = map(payload.facilitators_ids, (id) => ({
      user_id: id,
      role: constants.FACILITATOR_ROLES.author,
    }));

    // Surveys
    let { survey_relationships: surveyRelationships } = payload;
    const { external_survey_link: surveyLink } = payload;

    surveyRelationships = map(surveyRelationships, (sr) => {
      const surveyRelationship = pick(sr, [
        'survey_id',
        'survey_cutoff_period_value',
        'survey_cutoff_period_option',
        'survey_schedule_period_value',
        'survey_schedule_period_option',
        'survey_schedule_kind',
      ]);

      if (isEdit) surveyRelationship.id = sr.id;

      return surveyRelationship;
    });

    payload.external_survey_link = surveyLink;
    payload.survey_relationships = surveyRelationships;

    // Resources Links
    payload.resources = parseResources(payload.resources, isEdit);

    if (!payload.duration) {
      payload.duration = '00:00:00';
    }

    // Settings
    payload.settings = prepareSettingsValuesForSubmission(values?.settings);

    return await onSubmitHandler(payload);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(ArticleForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);
  return {
    selectedOfficeHour,
    currentUser: get(state, 'user.currentUser'),
  };
};

export default connect(mapStateToProps)(ConnectedArticleForm);
