import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Box, Typography, Breadcrumbs, Link, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TimelineIcon from '@mui/icons-material/Timeline';
import qs from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { InsightWidget, InsightWidgetHeader } from '~/common/components/InsightWidget';

import {
  axisClasses,
  BarChart,
  ChartsReferenceLine,
  ChartsText,
  useDrawingArea,
  useXAxis,
  useXScale,
  useAxisTooltip,
  ChartsAxisContentProps,
  ChartsOnAxisClickHandler,
  ChartsAxisData,
} from '@mui/x-charts-pro';
import { type ScaleBand } from '@mui/x-charts-vendor/d3-scale';
import { forEach, map, noop } from 'lodash-es';
import {
  ASSIGNMENT_STATES,
  ASSIGNMENT_STATES_CHART_COLORS,
  ASSIGNMENT_STATES_LABELS,
} from '~/app/assignments/constants';
import {
  ChartTooltipContent,
  ChartTooltipContentHeader,
  ChartTooltipContentDivider,
  ChartTooltipContentItemRow,
  ChartTooltipContentBody,
} from '~/common/components/ChartTooltipContent';
import { useGetContentTypeLabel } from '~/app/catalog/hooks';
import {
  Track,
  TrackCompletionStatusByItemInsight,
  TrackCompletionStatusByItemInsightTrackItem,
} from '../types';
import { queries } from '~/queries';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CHART_PORTAL_ROOT_ID = 'track-completion-status-by-item-insight-widget-chart-portal-root';

const CHART_SERIES_KEYS = {
  completed: ASSIGNMENT_STATES.completed,
  in_progress: ASSIGNMENT_STATES.in_progress,
  not_started: ASSIGNMENT_STATES.not_started,
  temp: 'temp',
} as const;

const CHART_SERIES_COLORS = {
  [CHART_SERIES_KEYS.completed]: ASSIGNMENT_STATES_CHART_COLORS[ASSIGNMENT_STATES.completed],
  [CHART_SERIES_KEYS.in_progress]: ASSIGNMENT_STATES_CHART_COLORS[ASSIGNMENT_STATES.in_progress],
  [CHART_SERIES_KEYS.not_started]: ASSIGNMENT_STATES_CHART_COLORS[ASSIGNMENT_STATES.not_started],
  [CHART_SERIES_KEYS.temp]: '#A779CA',
} as const;

function getTrackItemHash(item: TrackCompletionStatusByItemInsightTrackItem) {
  return `${item.id}_${item.section}_${item.content_item.content_type}`;
}

function transformChartDataset(item: TrackCompletionStatusByItemInsightTrackItem) {
  return {
    id: item.id,
    name: item.content_item.name,
    content_type: item.content_item.content_type,
    section_id: item.section,
    order: item.order,
    ...item.states,
    temp: 0,
    hash: getTrackItemHash(item),
  };
}

type ChartDatasetItem = ReturnType<typeof transformChartDataset>;

type ParseTrackSectionsForChartParams = {
  sections: TrackCompletionStatusByItemInsight['sections'];
  trackItems: TrackCompletionStatusByItemInsight['track_items'];
  dataset: ChartDatasetItem[];
};

/**
 * Parses the track sections in order to display them
 * in the x axis of the chart, and also for the reference lines
 * that separate the sections.
 */
function parseTrackSectionsForChart(params: ParseTrackSectionsForChartParams) {
  const { sections = [], trackItems = [], dataset = [] } = params;

  if (!sections || sections.length === 0) {
    return [];
  }

  // Count how many items each section has
  const sectionItemsCountMap: Record<string, number> = {};
  for (const item of trackItems) {
    if (!item.section) {
      continue;
    }

    const currentCount = sectionItemsCountMap[item.section] ?? 0;
    sectionItemsCountMap[item.section] = currentCount + 1;
  }

  /**
   * We need to know where each section starts and ends in the
   * provided dataset. Keep in mind that the dataset might have
   * collapsed sections.
   *
   * This will allow us to correctly position the reference lines and the axis labels.
   */

  const sectionDatasetMap = new Map<string, number[]>();
  const sectionItemsMaxCountMap: Record<string, number> = {};

  // Map items indices to their respective section
  for (const [index, item] of dataset.entries()) {
    if (!item.section_id) {
      continue;
    }

    if (!sectionDatasetMap.has(item.section_id)) {
      sectionDatasetMap.set(item.section_id, []);
    }

    const currentMaxCount = sectionItemsMaxCountMap[item.section_id] ?? 0;
    sectionItemsMaxCountMap[item.section_id] = Math.max(
      currentMaxCount,
      item.completed + item.in_progress + item.not_started
    );

    sectionDatasetMap.get(item.section_id)!.push(index);
  }

  // Remove sections that have no items
  // If a section has no items, it will not be displayed in the chart
  const sectionsWithItems = sections.filter((section) => {
    const sectionItems = sectionDatasetMap.get(section.id);
    return sectionItems && sectionItems.length > 0;
  });

  const result = sectionsWithItems.map((section) => {
    const datasetIndices = sectionDatasetMap.get(section.id)!;

    const itemsCount = sectionItemsCountMap[section.id];

    const startItemIdx = datasetIndices[0];
    const endItemIdx = datasetIndices.at(-1)!;

    return {
      id: section.id,
      name: section.name,
      itemsCount,
      itemsCountLabel: `${itemsCount} item${itemsCount === 1 ? '' : 's'}`,
      startItemIdx,
      startItem: dataset[startItemIdx],
      endItemIdx,
      endItem: dataset[endItemIdx],
      maxCount: sectionItemsMaxCountMap[section.id],
    };
  });

  return result;
}

type ChartTrackSection = ReturnType<typeof parseTrackSectionsForChart>[number];

type ChartTrackSectionWithBand = ChartTrackSection & {
  band: {
    start: number;
    end: number;
    middle: number;
    width: number;
  };
};

type CollapsedTrackSection = {
  count: number;
};
type CollapsedTrackSections = {
  [key: string]: CollapsedTrackSection | undefined;
};

type TrackChartCtxType = {
  collapsedSections: CollapsedTrackSections;
  toggleCollapseSection: (sectionId: string) => void;
};

const TrackChartCtx = React.createContext<TrackChartCtxType>({
  collapsedSections: {},
  toggleCollapseSection: noop,
});

function useTrackChartCtx() {
  const ctx = React.useContext(TrackChartCtx);

  if (!ctx) {
    throw new Error('useTrackChartCtx must be used within a TrackChartCtx provider');
  }

  return ctx;
}

const AxisRoot = styled('g')({});

type ChartTrackSectionsItemsCountProps = {
  sections: ChartTrackSectionWithBand[];
};

function ChartTrackSectionsItemsCount(props: ChartTrackSectionsItemsCountProps) {
  const { sections } = props;

  const { top, height } = useDrawingArea();

  const sectionTicks = React.useMemo(() => {
    return sections.map((section) => {
      return {
        value: section.itemsCountLabel,
        position: section.band.middle,
      };
    });
  }, [sections]);

  return (
    <AxisRoot transform={`translate(0, ${top + height})`}>
      {sectionTicks.map((tick, index) => {
        return (
          <g key={index} transform={`translate(${tick.position}, 0)`}>
            <ChartsText
              x={0}
              y={10}
              style={{
                textAnchor: 'middle',
                dominantBaseline: 'hanging',
                fontSize: 12,
              }}
              text={tick.value.toString()}
            />
          </g>
        );
      })}
    </AxisRoot>
  );
}

type TrackItemChartTooltipContentProps = ChartsAxisContentProps & {
  datasetFullItemsByIdMap: Map<number, TrackCompletionStatusByItemInsightTrackItem>;
};

function TrackItemChartTooltipContent(props: TrackItemChartTooltipContentProps) {
  const { datasetFullItemsByIdMap } = props;

  const axisData = useAxisTooltip();
  const getContentTypeLabel = useGetContentTypeLabel();

  if (!axisData) {
    return null;
  }

  const { axisFormattedValue, seriesItems } = axisData;
  const currentItem = datasetFullItemsByIdMap.get(axisData.axisValue as number);

  if (!currentItem) {
    return null;
  }

  const filteredSeriesItems = seriesItems.filter((item) => {
    return item.seriesId !== 'temp';
  });

  const contentTypeLabel = getContentTypeLabel(currentItem.content_item.content_type);
  const isNestedTrack = currentItem.content_item.content_type === CONTENT_TYPES.track;

  return (
    <ChartTooltipContent>
      <ChartTooltipContentHeader title={axisFormattedValue} subheader={contentTypeLabel} />
      <ChartTooltipContentDivider />
      <ChartTooltipContentBody>
        {filteredSeriesItems.map((item) => {
          return (
            <ChartTooltipContentItemRow
              key={item.seriesId}
              label={item.formattedLabel ?? ''}
              value={item.formattedValue}
              valueTag="Assignments"
              color={item.color}
            />
          );
        })}
      </ChartTooltipContentBody>
      {isNestedTrack && (
        <>
          <ChartTooltipContentDivider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '5px',
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: '0.75rem',
                lineHeight: 1.4,
                fontWeight: 500,
              }}
            >
              Click to expand
            </Typography>
          </Box>
        </>
      )}
    </ChartTooltipContent>
  );
}

const ReferenceLineRoot = styled('g')(({ theme }) => ({
  [`& path`]: {
    fill: 'none',
    stroke: theme.palette.text.primary,
    shapeRendering: 'crispEdges',
    strokeWidth: 1,
    pointerEvents: 'none',
  },
}));

type TrackSectionChartReferenceLineProps = Omit<
  React.ComponentProps<typeof ChartsReferenceLine>,
  'y' | 'labelAlign'
> & {
  section: ChartTrackSectionWithBand;
};

function TrackSectionChartReferenceLine(props: TrackSectionChartReferenceLineProps) {
  const { section, lineStyle, label = '' } = props;

  const { height } = useDrawingArea();
  const xScale = useXScale() as ScaleBand<number>;

  // Place the line in-between the gap of the previous section and the current section
  const bandGap = xScale.step() - xScale.bandwidth();
  const lineXPosition = section.band.start - bandGap / 2;

  // Have the line extend a bit below the bottom of the chart
  const d = `M ${lineXPosition} 0 l 0 ${height + 30}`;

  const { toggleCollapseSection, collapsedSections } = useTrackChartCtx();

  const isCollapsed = collapsedSections[section.id] !== undefined;
  const canCollapseSection = section.itemsCount > 1;

  const handleToggleCollapseSection = () => {
    if (!canCollapseSection) {
      return;
    }

    toggleCollapseSection(section.id);
  };

  const btnIcon = !canCollapseSection ? null : isCollapsed ? <ChevronRight /> : <ChevronLeft />;

  const portalRoot = document.getElementById(CHART_PORTAL_ROOT_ID);
  const buttonPortal = ReactDOM.createPortal(
    <>
      <Button
        size="small"
        sx={{
          position: 'absolute',
          top: 0,
          left: lineXPosition + 5,
          maxWidth: section.band.width,
        }}
        endIcon={btnIcon}
        onClick={handleToggleCollapseSection}
      >
        <Box
          component="span"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Box>
      </Button>
    </>,
    portalRoot!
  );

  return (
    <ReferenceLineRoot>
      <path d={d} style={lineStyle} />
      {buttonPortal}
    </ReferenceLineRoot>
  );
}

type ChartTrackSectionsReferenceLinesProps = {
  sections: ChartTrackSectionWithBand[];
};

function ChartTrackSectionsReferenceLines(props: ChartTrackSectionsReferenceLinesProps) {
  const { sections } = props;

  return (
    <>
      {sections.map((section, idx) => {
        return (
          <TrackSectionChartReferenceLine
            key={section.id}
            section={section}
            label={section.name}
            lineStyle={{
              ...(idx === 0
                ? {
                    fill: 'transparent',
                    stroke: 'transparent',
                  }
                : {}),
            }}
          />
        );
      })}
    </>
  );
}

type ChartTrackSectionsDisplayProps = {
  chartSections: ChartTrackSection[];
};

function ChartTrackSectionsDisplay(props: ChartTrackSectionsDisplayProps) {
  const { chartSections } = props;

  const { scale } = useXAxis();
  const xScaleBand = scale as ScaleBand<number>;

  const domain = xScaleBand.domain();

  const chartSectionsWithBands: ChartTrackSectionWithBand[] = React.useMemo(() => {
    return chartSections.map((section) => {
      const startDomain = domain[section.startItemIdx];
      const startBand = xScaleBand(startDomain)!;

      const endDomain = domain[section.endItemIdx];
      const endBand = xScaleBand(endDomain)! + xScaleBand.bandwidth();

      return {
        ...section,
        band: {
          start: startBand,
          end: endBand,
          middle: startBand + (endBand - startBand) / 2,
          width: endBand - startBand,
        },
      };
    });
  }, [chartSections, domain, xScaleBand]);

  return (
    <>
      <ChartTrackSectionsItemsCount sections={chartSectionsWithBands} />
      <ChartTrackSectionsReferenceLines sections={chartSectionsWithBands} />
    </>
  );
}

type TrackBreadcrumb = {
  id: number;
  name: string;
};

type TrackBreadcrumbsProps = {
  breadcrumbs: TrackBreadcrumb[];
  onBreadcrumbClick: (breadcrumb: TrackBreadcrumb) => void;
};

function TrackBreadcrumbs(props: TrackBreadcrumbsProps) {
  const { breadcrumbs, onBreadcrumbClick } = props;

  const linkBreadcrumbs = breadcrumbs.slice(0, -1);
  const currentTrack = breadcrumbs.at(-1)!;

  return (
    <Breadcrumbs>
      {linkBreadcrumbs.map((track) => {
        return (
          <Link
            key={track.id}
            component="button"
            variant="body2"
            underline="hover"
            onClick={() => onBreadcrumbClick(track)}
            sx={{
              fontWeight: 400,
            }}
          >
            {track.name}
          </Link>
        );
      })}

      <Typography
        variant="body2"
        component="span"
        color="text.primary"
        sx={{
          fontWeight: 500,
        }}
      >
        {currentTrack.name}
      </Typography>
    </Breadcrumbs>
  );
}

type TrackCompletionStatusByItemInsightWidgetProps = {
  track: Track;
  queryExpression: string;
};

function TrackCompletionStatusByItemInsightWidget(
  props: TrackCompletionStatusByItemInsightWidgetProps
) {
  const { track: rootTrack, queryExpression } = props;

  const [collapsedSections, setCollapsedSections] = React.useState<CollapsedTrackSections>({});

  const [trackBreadcrumbs, setTrackBreadcrumbs] = React.useState<TrackBreadcrumb[]>([
    {
      id: rootTrack.id,
      name: rootTrack.name,
    },
  ]);

  const currentTrackBreadcrumb = trackBreadcrumbs.at(-1)!;

  const isInNestedTrack = trackBreadcrumbs.length > 1;
  const parentTrackId = isInNestedTrack ? trackBreadcrumbs.at(-2)?.id : undefined;

  let fullQueryExpression = queryExpression;
  if (isInNestedTrack) {
    const parentTrackQueryExpression = qs.stringify({
      parent_track: parentTrackId,
    });

    fullQueryExpression = `${fullQueryExpression}&${parentTrackQueryExpression}`;
  }

  const { data: insightData, status } = useQuery({
    ...queries.tracks.completionStatusByItemInsights(
      currentTrackBreadcrumb.id,
      fullQueryExpression
    ),
  });

  const trackItems = insightData?.track_items ?? [];

  const chartDataset = React.useMemo(() => {
    const baseDataset = map(trackItems, transformChartDataset);

    /*
      Transform basedataset to include collapsed sections.

      We add a new collapsed section item whenever we encounter an item
      that belongs to a collapsed section, and we skip the rest of the items
      for that section.
    */

    const resultDataset: ChartDatasetItem[] = [];
    const processedSections = new Set<string>();

    forEach(baseDataset, (item, idx) => {
      const collapsedSection = collapsedSections[item.section_id];

      if (!collapsedSection) {
        resultDataset.push({
          ...item,
          id: idx,
        });

        return;
      }

      if (!processedSections.has(item.section_id)) {
        processedSections.add(item.section_id);

        resultDataset.push({
          id: item.id,
          name: item.section_id,
          content_type: 'section',
          section_id: item.section_id,
          order: 0,
          completed: 0,
          in_progress: 0,
          not_started: 0,
          temp: collapsedSection.count,
          hash: '',
        });
      }
    });

    return resultDataset;
  }, [trackItems, collapsedSections]);

  const datasetFullItemsByIdMap = React.useMemo(() => {
    const itemsByHashMap = new Map<string, TrackCompletionStatusByItemInsightTrackItem>();
    for (const item of trackItems) {
      itemsByHashMap.set(getTrackItemHash(item), item);
    }

    const map = new Map<number, TrackCompletionStatusByItemInsightTrackItem>();
    for (const item of chartDataset) {
      if (item.content_type === 'section') {
        continue;
      }

      map.set(item.id, itemsByHashMap.get(item.hash)!);
    }

    return map;
  }, [chartDataset, trackItems]);

  const chartSections = React.useMemo(() => {
    return parseTrackSectionsForChart({
      sections: insightData?.sections ?? [],
      trackItems,
      dataset: chartDataset,
    });
  }, [insightData?.sections, trackItems, chartDataset]);

  const chartSectionsMap = new Map<string, ChartTrackSection>();
  for (const section of chartSections) {
    chartSectionsMap.set(section.id, section);
  }

  const handleToggleCollapseSection = (sectionId: string) => {
    const section = chartSectionsMap.get(sectionId);
    if (!section) {
      return;
    }

    const isCollapsed = collapsedSections[sectionId];

    if (!isCollapsed && section.itemsCount > 1) {
      setCollapsedSections((prev) => ({
        ...prev,
        [sectionId]: { count: section.maxCount },
      }));
    } else if (isCollapsed) {
      setCollapsedSections((prev) => {
        const { [sectionId]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleClickOnNestedTrack = (axis: ChartsAxisData) => {
    const axisValue = axis.axisValue;

    if (!axisValue) {
      return;
    }

    const trackItem = datasetFullItemsByIdMap.get(axisValue as number);

    if (!trackItem) {
      return;
    }

    const isNestedTrack = trackItem.content_item.content_type === CONTENT_TYPES.track;

    if (!isNestedTrack) {
      return;
    }

    setTrackBreadcrumbs((prev) => [
      ...prev,
      {
        id: trackItem.content_item.id,
        name: trackItem.content_item.name,
      },
    ]);
  };

  const handleOnBreadcrumbClick = (breadcrumb: TrackBreadcrumb) => {
    let breadcrumbIndex = trackBreadcrumbs.findIndex((b) => b.id === breadcrumb.id);
    // Default set root track as the current track
    breadcrumbIndex = Math.max(breadcrumbIndex, 0);

    setTrackBreadcrumbs((prev) => prev.slice(0, breadcrumbIndex + 1));
  };

  const trackItemsCount = trackItems.length;
  const trackSections = insightData?.sections ?? [];
  const canDisplayTrackSectionsInChart = trackSections.length > 0;

  return (
    <TrackChartCtx.Provider
      value={{
        collapsedSections,
        toggleCollapseSection: handleToggleCollapseSection,
      }}
    >
      <InsightWidget>
        <InsightWidgetHeader
          title="Completion Status By Item"
          icon={<TimelineIcon fontSize="small" sx={{ color: '#905CB8' }} />}
        />

        <Box
          sx={{
            marginTop: '4px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <TrackBreadcrumbs
            breadcrumbs={trackBreadcrumbs}
            onBreadcrumbClick={handleOnBreadcrumbClick}
          />
          <Box>
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontWeight: 700,
                fontSize: '1.5rem',
              }}
            >
              {trackItemsCount}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontWeight: 700,
                fontSize: '1rem',
              }}
            >
              {` Item${trackItemsCount === 1 ? '' : 's'}`}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            minHeight: '255px',
            paddingTop: '16px',
            marginTop: '16px',
            position: 'relative',
          }}
        >
          <BarChart
            dataset={chartDataset}
            loading={status === 'loading'}
            xAxis={[
              {
                id: 'assignments',
                scaleType: 'band',
                dataKey: 'id',
                // @ts-expect-error For some reason this is not typed
                categoryGapRatio: 0.2,
                tickLabelPlacement: 'middle',
                disableTicks: true,
                tickSize: 0,
                valueFormatter(value, context) {
                  if (context.location === 'tick') {
                    return '';
                  }

                  const item = datasetFullItemsByIdMap.get(value);
                  if (!item) {
                    return '';
                  }

                  return item.content_item.name;
                },
              },
            ]}
            series={[
              {
                id: CHART_SERIES_KEYS.completed,
                dataKey: CHART_SERIES_KEYS.completed,
                label: ASSIGNMENT_STATES_LABELS[CHART_SERIES_KEYS.completed],
                stack: 'state',
                color: CHART_SERIES_COLORS[CHART_SERIES_KEYS.completed],
              },
              {
                id: CHART_SERIES_KEYS.in_progress,
                dataKey: CHART_SERIES_KEYS.in_progress,
                label: ASSIGNMENT_STATES_LABELS[CHART_SERIES_KEYS.in_progress],
                stack: 'state',
                color: CHART_SERIES_COLORS[CHART_SERIES_KEYS.in_progress],
              },
              {
                id: CHART_SERIES_KEYS.not_started,
                dataKey: CHART_SERIES_KEYS.not_started,
                label: ASSIGNMENT_STATES_LABELS[CHART_SERIES_KEYS.not_started],
                stack: 'state',
                color: CHART_SERIES_COLORS[CHART_SERIES_KEYS.not_started],
              },
              {
                id: CHART_SERIES_KEYS.temp,
                dataKey: CHART_SERIES_KEYS.temp,
                stack: 'state',
                color: CHART_SERIES_COLORS[CHART_SERIES_KEYS.temp],
              },
            ]}
            height={255}
            margin={{
              top: 20,
              right: 0,
              bottom: 55,
              left: 50,
            }}
            grid={{ horizontal: true, vertical: false }}
            sx={{
              [`& .${axisClasses.tickLabel}`]: {
                fontWeight: 400,
                lineHeight: 1.16,
              },
            }}
            slotProps={{
              legend: {
                padding: {
                  left: 50,
                },
                position: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              },
            }}
            slots={{
              axisContent: (props) => (
                <TrackItemChartTooltipContent
                  {...props}
                  datasetFullItemsByIdMap={datasetFullItemsByIdMap}
                />
              ),
            }}
          >
            {canDisplayTrackSectionsInChart && (
              <ChartTrackSectionsDisplay chartSections={chartSections} />
            )}

            <ChartsOnAxisClickHandler
              onAxisClick={(_, axis) => {
                if (axis) {
                  handleClickOnNestedTrack(axis);
                }
              }}
            />
          </BarChart>

          <div id={CHART_PORTAL_ROOT_ID}></div>
        </Box>
      </InsightWidget>
    </TrackChartCtx.Provider>
  );
}

export { TrackCompletionStatusByItemInsightWidget };
