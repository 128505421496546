import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SettingConfiguredWidgetList } from '~/common/components/SettingConfiguredWidgetList';
import CardListWrapper from '~/app/content-items/components/CardListWrapper';
import { useFetchDataListPaginated } from '~/app/data-fetching/hooks';
import { translatorFactory } from '~/app/feature-parity/utils';
import {
  usePeopleDirectoryFiltersDefinition,
  usePeopleDirectoryPageTitle,
  usePeopleDirectoryPillsDefinition,
} from '~/features/users/hooks';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import FilterPills from '~/app/navigation/components/FilterPills';
import { queries } from '~/queries';
import colors from '~/services/colors';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import CardList from '~/app/shared-cards/components/CardList';
import {
  PeopleLoadMoreButtonWrapper,
  PeopleNotFoundMessage,
  PeopleSectionHeader,
} from '~/app/shared-layouts/People';
import { PersonCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import LoadMoreButton from '~/app/shared/components/LoadMoreButton';
import PageTitle from '~/app/shared/components/PageTitle';
import Text from '~/app/shared/components/Text';
import { useCurrentUser, useLabels, useStickyFilters } from '~/app/shared/hooks';
import { includes, map, isEmpty, get, filter, size, has } from 'lodash-es';
import ReactMarkdown from '~/vendor/react-markdown';

import SuggestedProgramsList from './SuggestedProgramsList';

const PAGE_SIZE = 24;

const PeopleDirectory = () => {
  const {
    label_people_directory: peopleDirectoryLabel,
    label_people_directory_page_blurb: peopleDirectoryPageBlurb,
  } = useLabels();
  const { trackActivity } = useMetrics();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const search = useRef(isEmpty(location.search) ? '' : location.search.slice(1)); // remove the '?' symbol
  const defaultTabsQueryStrings = useRef(
    map(
      filter(get(currentUser, 'tabs_configuration'), (tab) => get(tab, 'module') === 'people'),
      'querystring'
    )
  );
  const [isStickyFiltersLoaded, setIsStickyFiltersLoaded] = useState(
    // Load the sticky filters only if the user is accessing the page with an empty query string or
    // one of the custom tab configurations which has a query string defined.
    !isEmpty(search.current) && !includes(defaultTabsQueryStrings.current, search.current) // set as true if the user is accessing the page with a search defined
  );

  // Filters
  const translator = translatorFactory({
    defaultOrdering: 'name',
    orderingOptions: {
      name: 'name',
      '-name': '-name',
      title: 'title',
      department: 'department',
      location: 'location.name',
    },
  });
  const {
    filters,
    ordering,
    updateFilter,
    removeValue,
    setOrdering,
    resetFilters,
    rqlExpression,
    processExpression,
  } = useRQLFilters({
    initialFiltersState: {},
    initialOrdering: 'name',
    translator,
  });

  // Data fetching
  const queryKey = `${rqlExpression}&page_size=${PAGE_SIZE}&view_mode=people&gt(unique_people_impacted_count,0)`;
  const {
    data: people,
    fetchMore: loadMorePeople,
    count,
    hasNextPage,
    isLoading,
  } = useFetchDataListPaginated({
    ...queries.users.list(queryKey),
    enabled: !isEmpty(rqlExpression),
  });

  // Page configuration
  const pageTitle = usePeopleDirectoryPageTitle({ filters });

  // Sticky filters
  const [stickyFilters, updateStickyFilters] = useStickyFilters('people_page');
  const stickyRqlExpression = has(stickyFilters, 'rqlExpression')
    ? get(stickyFilters, 'rqlExpression', '')
    : stickyFilters // check if the sticky filters are in the old format
      ? translator(queryString.stringify(stickyFilters))
      : '';

  // Filters definition
  const { filters: barFilters, moreFilters } = usePeopleDirectoryFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
    fixedFilters: ['My Audience', 'My Attendance', 'My Experts'],
  });

  const qParam = get(get(filters, 'q', ''), '$eq', '');
  // Pills definition
  const { pills } = usePeopleDirectoryPillsDefinition({ filters });

  // Creating reference to mutable values to avoid re-renders
  const cachedTrackActivity = useRef(trackActivity);
  const cachedUpdateStickyFilters = useRef(updateStickyFilters);

  useEffect(() => {
    cachedTrackActivity.current = trackActivity;
    cachedUpdateStickyFilters.current = updateStickyFilters;
  });

  // Track activity
  useEffect(() => {
    if (rqlExpression) {
      cachedTrackActivity.current(METRICS_ACTIVITIES.PEOPLE_VIEW, { filters: rqlExpression });
    }
  }, [rqlExpression]);

  // Load sticky filters
  useEffect(() => {
    if (
      !isStickyFiltersLoaded &&
      !isEmpty(stickyRqlExpression) &&
      rqlExpression &&
      isEmpty(search.current)
    ) {
      // Ensure it loads once and the initial URL was processed
      setIsStickyFiltersLoaded(true);
      processExpression(stickyRqlExpression);
    }
    if (isEmpty(stickyRqlExpression)) {
      setIsStickyFiltersLoaded(true);
    }
  }, [stickyRqlExpression, isStickyFiltersLoaded, processExpression, rqlExpression]);

  useEffect(() => {
    if (rqlExpression && isStickyFiltersLoaded) {
      cachedUpdateStickyFilters.current({ rqlExpression });
    }
  }, [rqlExpression, isStickyFiltersLoaded]);

  // Do not show the widget list if the user is filtering the people list
  const showWidgetList = rqlExpression === `ordering(${ordering})`;

  return (
    <>
      <PageTitle title={peopleDirectoryLabel} />
      <CardListWrapper alignSelf="center">
        <Text size="h1" color={colors.neutral900} medium>
          {pageTitle}
        </Text>

        <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters} />
        <FilterPills
          pills={pills}
          onRemove={(item) => removeValue(get(item, 'filterName', ''), get(item, 'value', ''))}
        />
        {/* Do not show the widget list if the user is filtering the people list */}
        {showWidgetList && (
          <>
            <SettingConfiguredWidgetList orderSetting="widgets_order.widget_order_people" />
            <SuggestedProgramsList />
          </>
        )}

        <PeopleSectionHeader>
          <Text size="h4" color={colors.neutral600} maxWidth="80%">
            <ReactMarkdown linkTarget="_blank">{peopleDirectoryPageBlurb}</ReactMarkdown>
          </Text>
          <Text color={colors.neutral600}>
            {count} {count === 1 ? 'person' : 'people'}
          </Text>
        </PeopleSectionHeader>

        {isLoading && <Loading />}

        {!isLoading && size(people) <= 0 && (
          <PeopleNotFoundMessage>No people found</PeopleNotFoundMessage>
        )}

        <CardList
          items={people}
          renderItem={(person) => (
            <PersonCard key={person.id as number} person={person} q={qParam as string} />
          )}
        />

        <PeopleLoadMoreButtonWrapper>
          <LoadMoreButton
            isLoading={isLoading}
            show={hasNextPage}
            onClick={loadMorePeople}
            size="large"
          />
        </PeopleLoadMoreButtonWrapper>
      </CardListWrapper>
    </>
  );
};

export default PeopleDirectory;
