// eslint-disable-next-line no-unused-vars
import React from 'react';

import { useCurrentUser } from '~/app/shared/hooks';
import { useCurrentExpressionWithoutPage } from '~/app/backoffice/utils';
import { ApiURLs } from '~/services/requests-base';
import { STATUS_DONE } from '~/app/shared/constants';
import { useState } from 'react';
import Button from '~/app/shared/components/Button';
import ExportWithCustomAttrModal from '~/app/shared-modals/components/ExportWithCustomAttrModal';
import { useAssignmentsContext } from './Context';
import { isEmpty } from 'lodash-es';
import Dashboard from '~/app/backoffice/components/Dashboard';

const Actions = () => {
  const expression = useCurrentExpressionWithoutPage();
  const [showPeopleIntegrationModal, setShowPeopleIntegrationModal] = useState(false);
  const currentUser = useCurrentUser();
  const { exportable_people_integration_fields: exportablePeopleIntegrationFields } = currentUser;
  const { fetchFiltersStatus, exportCSVURL } = useAssignmentsContext();
  const noPeopleIntegrationFields = isEmpty(exportablePeopleIntegrationFields);
  const baseUrl = ApiURLs[exportCSVURL]();
  const defaultUrl = `${baseUrl}?${expression}`;

  if (fetchFiltersStatus !== STATUS_DONE) return null;

  const exportWithCustomAttrUrl = (requiredFields) => {
    const url = isEmpty(requiredFields) ? defaultUrl : `${defaultUrl}&${requiredFields}`;
    window.open(url, '_self');
  };
  return (
    <>
      {!noPeopleIntegrationFields && (
        <Button onClick={() => setShowPeopleIntegrationModal(true)} variant="contained">
          Export CSV
        </Button>
      )}
      {noPeopleIntegrationFields && <Dashboard.ExportCsvButton buttonUrl={defaultUrl} />}
      {showPeopleIntegrationModal && (
        <ExportWithCustomAttrModal
          handleClose={() => setShowPeopleIntegrationModal(false)}
          handleSubmit={exportWithCustomAttrUrl}
        />
      )}
    </>
  );
};

export { Actions };
