import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { queries } from '~/queries';
import { map } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';

const BadgeSelectField = (props) => {
  const { isLoading, data } = useQuery({
    ...queries.badges.list(),
    select: (data: any) => {
      return map(data.results, (row) => ({
        value: row.id,
        image: row.image,
        label: row.name,
      }));
    },
  });

  return <AutocompleteField options={data} loading={isLoading} {...props} />;
};

BadgeSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default BadgeSelectField;
