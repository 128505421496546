import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { MentorProfileRow } from '~/app/mentorship/interfaces';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { apiGet } from '~/services/requests';
import { ApiURLs, fetchURL } from '~/services/requests-base';

import { addMenteeToProgram, addMentorToProgram } from '../mutations/mentorship';
import { APIResponse, listQueryKeyFactory } from '../utils';

const fetchMentorListByProgram = async (
  public_id: string,
  rqlExpression?: string
): Promise<APIResponse<MentorProfileRow>> => {
  let url = ApiURLs['api_coaching:mentorship_mentors_filter']({ public_id });
  if (rqlExpression) {
    url = url + '?' + rqlExpression;
  }
  const { data: response } = await fetchURL(url);
  return response;
};

const isMentor = async (public_id: string, user_id: number) => {
  if (!public_id) {
    return false;
  }
  const url = ApiURLs['api_coaching:mentorship_is_mentor']({ public_id }) + `?user_id=${user_id}`;
  const { data: response } = await fetchURL(url);
  return response.data;
};

const fetchMenteeListByProgram = async (public_id: string, rqlExpression?: string) => {
  const url = ApiURLs['api_coaching:mentorship_mentees_filter']({ public_id });
  if (!rqlExpression) {
    rqlExpression = '';
  }
  const { data: response } = await fetchURL(url + '?' + rqlExpression);
  return response;
};

const isMentee = async (public_id: string, user_id: number) => {
  if (!public_id) {
    return false;
  }
  const url = ApiURLs['api_coaching:mentorship_is_mentee']({ public_id }) + `?user_id=${user_id}`;
  const { data: response } = await fetchURL(url);
  return response.data;
};

const fetchSessionListByProgram = async (public_id: string, rqlExpression?: string) => {
  const url = ApiURLs['api_coaching:mentorship_sessions_filter']({ public_id });
  if (!rqlExpression) {
    rqlExpression = '';
  }
  const { data: response } = await fetchURL(url + '?' + rqlExpression);
  return response.results;
};

const fetchSessionById = async (public_id: string) => {
  if (!public_id) {
    return null;
  }
  const url = ApiURLs['api_coaching:mentorship_session_details']({ public_id });
  const { data: response } = await fetchURL(url);
  return response;
};

const fetchMentorFilters = async ({
  initial_filters,
  filters_to_exclude,
}): Promise<APIResponse> => {
  const { data: response } = await apiGet('api_coaching:mentorship_mentor_filters', null, {
    initial_filters: initial_filters,
    filters_to_exclude: filters_to_exclude,
  });
  return response;
};

const fetchMenteeFilters = async ({
  initial_filters,
  filters_to_exclude,
}): Promise<APIResponse> => {
  const { data: response } = await apiGet('api_coaching:mentorship_mentee_filters', null, {
    initial_filters: initial_filters,
    filters_to_exclude: filters_to_exclude,
  });
  return response;
};

// define a list of key/queryfn pairs under a group name
export const mentorship_queries = createQueryKeys('mentorship_program', {
  filter: listQueryKeyFactory({ endpointName: 'api_coaching:mentorship_programs_filter' }),
  list: listQueryKeyFactory({ endpointName: 'mentorship_programs_api:mentorship_list_create' }),
  detail: (program_public_id) => ({
    queryKey: [program_public_id],
    queryFn: async (): Promise<ContentItem> => {
      const res = await fetchURL(
        ApiURLs['apiCoaching:mentorshipProgramDetails'](program_public_id)
      );
      return res.data;
    },
  }),
  mentors: (program_public_id, rqlExpression) => ({
    queryKey: [program_public_id, rqlExpression],
    queryFn: () => fetchMentorListByProgram(program_public_id, rqlExpression),
    contextQueries: {
      // TODO: Move this over to data-fetching/mutations/mentorship.ts
      add: (user: number, program: number) => ({
        queryKey: [user, program],
        queryFn: () => addMentorToProgram(program_public_id, user, program),
      }),
      is_mentor: (user: number) => ({
        queryKey: [user],
        queryFn: () => isMentor(program_public_id, user),
      }),
    },
  }),
  mentees: (program_public_id, rqlExpression) => ({
    queryKey: [program_public_id, rqlExpression],
    queryFn: () => fetchMenteeListByProgram(program_public_id, rqlExpression),
    contextQueries: {
      // TODO: Move this over to data-fetching/mutations/mentorship.ts
      add: (user: number, program: number) => ({
        queryKey: [user, program],
        queryFn: () => addMenteeToProgram(program_public_id, user, program),
      }),
      is_mentee: (user: number) => ({
        queryKey: [user],
        queryFn: () => isMentee(program_public_id, user),
      }),
    },
  }),
  sessions: (program_public_id, rqlExpression) => ({
    queryKey: [program_public_id, rqlExpression],
    queryFn: () => fetchSessionListByProgram(program_public_id, rqlExpression),
  }),
  session: (session_id) => ({
    queryKey: [session_id],
    queryFn: () => fetchSessionById(session_id),
  }),
  mentor_filters: (params) => ({
    queryKey: [params],
    queryFn: () => fetchMentorFilters(params),
  }),
  mentee_filters: (params) => ({
    queryKey: [params],
    queryFn: () => fetchMenteeFilters(params),
  }),
});

export type MentorshipQueryKeys = inferQueryKeys<typeof mentorship_queries>;
