import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import colors from '~/services/colors';
import Clicker from '~/app/shared/components/Clicker';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import Icon from '~/app/shared/components/Icon';
import IconButton from '~/app/shared/components/IconButton';
import Text from '~/app/shared/components/Text';
import { Tooltip } from '~/app/shared/components/Tooltip';
import { useEllipsisCheck, useTooltipUID, usePrevious } from '~/app/shared/hooks';
import { map, isEmpty, size } from 'lodash-es';
import { Tooltip as MuiTooltip } from '@mui/material';

const SortableElement = styled.div`
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
`;

const SortableElementAnchor = styled.div``;

const SubItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubItem = styled(Clicker)`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 8px 32px;
  background-color: ${({ selected }) => (selected ? colors.neutral100 : 'transparent')};
  color: ${({ selected }) => (selected ? colors.action600 : 'inherit')};
`;

const ItemWrapper = styled((props) =>
  props.onClick ? <Clicker fullWidth {...props} /> : <div {...props} />
)`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: ${({ labelColor }) => labelColor ?? colors.neutral900};
  > ${Icon} path {
    fill ${({ iconColor }) => iconColor ?? colors.neutral900}
  }

  ${({ selected }) =>
    selected
      ? `
    background-color: ${colors.action600};
    color: ${colors.action600TextColor};
    > ${Icon} path {
      fill ${colors.action600TextColor}
    }
  `
      : `
    &:hover {
      background-color: ${colors.neutral50};
    }
  `}

  ${({ showWarning }) => (showWarning ? `background-color: ${colors.alert100};` : '')}

  > * + * {
    margin-left: 8px;
  }

  ${Text} {
    flex: 1;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubLabelWrapper = styled.div`
  max-width: 180px;
`;

const ContentSidebarItem = ({
  id,
  label,
  subLabel,
  showWarning,
  labelColor,
  icon,
  iconColor,
  selected,
  settingItems,
  onClick,
  onReorder,
  dropDownButtonAriaLabel,
  buttonAriaLabel,
  subItemsList,
  customSubItemSelectedHandler,
  setSectionScrollBehavior,
}) => {
  const tooltip = useTooltipUID();
  const { nodeRef, hasEllipsis } = useEllipsisCheck({ multipleLines: true });
  const [expanded, setExpanded] = useState(!isEmpty(subItemsList) && selected);
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const previousSubItemsList = usePrevious(subItemsList);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setExpanded(true);
  };

  const handleToggleCollapse = (e) => {
    setExpanded(!expanded);
    e.stopPropagation();
  };

  const defaultSubItemSelectedHandler = (url) => {
    return url === `${pathname}${hash}` && !isEmpty(hash);
  };

  const isSubItemSelected = customSubItemSelectedHandler || defaultSubItemSelectedHandler;

  // In some cases the subItemsList can change
  if (
    !expanded &&
    selected &&
    !isEmpty(subItemsList) &&
    size(subItemsList) !== size(previousSubItemsList)
  ) {
    setExpanded(true);
  }

  return (
    <SortableElement
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      ref={setNodeRef}
    >
      <ItemWrapper
        aria-label={buttonAriaLabel}
        labelColor={labelColor}
        iconColor={iconColor}
        onClick={handleClick}
        selected={selected}
        showWarning={showWarning}
        {...tooltip.targetProps}
      >
        {onReorder && (
          <SortableElementAnchor {...attributes} {...listeners}>
            <IconButton
              title="Reorder"
              iconName="hamburger"
              width={12}
              height={12}
              color={colors.neutral400}
              cursor="grab"
              hoverColor={colors.action600}
            />
          </SortableElementAnchor>
        )}

        {icon && <Icon name={icon} width={12} height={12} />}

        <LabelWrapper>
          <Text size="h5" ellipsisOnLine={2} ref={nodeRef}>
            {label}
          </Text>
          {subLabel && (
            <SubLabelWrapper>
              <Text size="h6" ellipsisOnLine={2} color={showWarning ? colors.alert600 : 'inherit'}>
                {subLabel}
              </Text>
            </SubLabelWrapper>
          )}
        </LabelWrapper>

        {!isEmpty(subItemsList) && (
          <IconButton
            onClick={handleToggleCollapse}
            color={selected ? colors.neutral0 : colors.neutral900}
            hoverColor={selected ? colors.neutral0 : colors.neutral900}
            iconName={expanded ? 'up' : 'down'}
            width={10}
            height={10}
          />
        )}

        {settingItems && (
          <DropDownMenu
            icon="elipsis"
            color={colors.neutral400}
            fontSize={12}
            buttonAriaLabel={dropDownButtonAriaLabel}
          >
            {settingItems}
          </DropDownMenu>
        )}
      </ItemWrapper>
      <Tooltip id={tooltip.uid} hide={!hasEllipsis} place="right" noWrap>
        {label}
      </Tooltip>
      {expanded && (
        <SubItems>
          {map(subItemsList, ({ url, label, tooltipContent = '', contextMenuItems, onClick }) => (
            <MuiTooltip key={`tooltip-${url}`} title={tooltipContent} arrow placement="right">
              <SubItem
                aria-label={label}
                selected={isSubItemSelected(url)}
                onClick={() => {
                  if (onClick) onClick();
                  else {
                    if (setSectionScrollBehavior) setSectionScrollBehavior('smooth');
                    // Just replace the URL if the pathname changes
                    // eslint-disable-next-line lodash/prefer-lodash-method
                    if (pathname !== url) history.replace(url);
                  }
                }}
              >
                <Text size="h6" ellipsisOnOverflow>
                  {label}
                </Text>
                {contextMenuItems && (
                  <DropDownMenu
                    icon="elipsis"
                    fontSize={12}
                    color={colors.neutral400}
                    buttonAriaLabel={`${label} dashboard actions`}
                    stopPropagationOnClick
                  >
                    {map(contextMenuItems, ({ title, icon, onClick }) => (
                      <DropDownMenu.Item
                        key={`${title}-${url}`}
                        title={title}
                        icon={icon}
                        type="button"
                        onClick={(e) => {
                          // We call stopPropagation here to avoid triggering
                          // a re-render if the item whose action we've clicked
                          // is not the item that's currently selected in the URL
                          e.stopPropagation();
                          onClick();
                        }}
                      />
                    ))}
                  </DropDownMenu>
                )}
              </SubItem>
            </MuiTooltip>
          ))}
        </SubItems>
      )}
    </SortableElement>
  );
};

ContentSidebarItem.defaultProps = {
  dropDownButtonAriaLabel: 'Options',
  buttonAriaLabel: 'Button',
  subItemsList: [],
  showWarning: false,
};

ContentSidebarItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  showWarning: PropTypes.bool,
  dropDownButtonAriaLabel: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  labelColor: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  selected: PropTypes.bool,
  settingItems: PropTypes.node,
  onClick: PropTypes.func,
  onReorder: PropTypes.func,
  subItemsList: PropTypes.array,
  customSubItemSelectedHandler: PropTypes.func,
  setSectionScrollBehavior: PropTypes.func,
};

export default ContentSidebarItem;
