import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash-es';

import { useTrackContentConsumptionCtx } from '~/features/tracks/contexts/TrackContentConsumptionCtx';
import { toast } from '~/app/notifications/components/NotificationCenter';

import { completeQuestionMutationOpts } from '../api/mutations';
import { CompleteMultipleChoiceQuestionBody, CompleteTextQuestionBody } from '../types';

type CompleteMultipleChoiceQuestionPayload = {
  type: 'multipleChoice';
} & Omit<CompleteMultipleChoiceQuestionBody, 'assignment_id'>;

type CompleteTextQuestionPayload = {
  type: 'text';
} & Omit<CompleteTextQuestionBody, 'assignment_id'>;

type CompleteQuestionPayload = CompleteMultipleChoiceQuestionPayload | CompleteTextQuestionPayload;

function getErrorMessage(error: any) {
  return get(error, 'detail', null) || get(error, 0, null);
}

function handleDisplayErrorMessage(error: any) {
  const errorMessage = getErrorMessage(error);
  toast.error('Failed to submit response.', errorMessage);
}

export function useCompleteQuestion() {
  const { currentItemAssignment } = useTrackContentConsumptionCtx();

  const completeMultipleChoiceQuestionMutation = useMutation({
    ...completeQuestionMutationOpts.multipleChoice,
    onError(error) {
      handleDisplayErrorMessage(error);
    },
  });

  const completeTextQuestionMutation = useMutation({
    ...completeQuestionMutationOpts.text,
    onError(error) {
      handleDisplayErrorMessage(error);
    },
  });

  const handleCompleteQuestion = (payload: CompleteQuestionPayload) => {
    if (!currentItemAssignment) {
      return;
    }

    if (payload.type === 'multipleChoice') {
      completeMultipleChoiceQuestionMutation.mutate({
        assignment_id: currentItemAssignment.id,
        content_type: payload.content_type,
        option_id: payload.option_id,
      });
    } else {
      completeTextQuestionMutation.mutate({
        assignment_id: currentItemAssignment.id,
        content_type: payload.content_type,
        text_response: payload.text_response,
      });
    }
  };

  const isLoading =
    completeMultipleChoiceQuestionMutation.isLoading || completeTextQuestionMutation.isLoading;

  return {
    completeQuestion: handleCompleteQuestion,
    isLoading,
  };
}
