import actions from '~/app/entities/actions';
import { automatedRuleSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { useLabels } from '~/app/shared/hooks';
import { toLower } from 'lodash-es';

import {
  ADD_REMOVE_BADGE,
  ADD_REMOVE_MENTORSHIP_USER,
  ASSIGN_CONTENT_ITEM_TO_USER,
  ENROLL_USER_ON_NEXT_EVENT_OF_EVENT_TYPE,
  ENROLL_USER_ON_NEXT_EVENT_OF_RECURRING_EVENT_TYPE,
  ENROLL_USER_ON_NEXT_EVENTS_OF_ALL_EVENT_TYPES_IN_TRACK,
  SYNC_GROUP_FROM_SEGMENT,
  SYNC_GROUP_TEMPLATE_FROM_SEGMENT,
  ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK,
} from './constants';

export const useOperationInfo = (operation) => {
  const {
    label_event_type: labelEventType,
    label_track: labelTrack,
    label_mentorship_program: labelMentorshipProgram,
  } = useLabels();

  const lowerLabelEventType = toLower(labelEventType);
  const lowerLabelTrack = toLower(labelTrack);

  switch (operation) {
    case ADD_REMOVE_MENTORSHIP_USER: {
      return `This action will add or remove people in the selected segment to the selected ${labelMentorshipProgram}.`;
    }
    case ADD_REMOVE_BADGE: {
      return `This action will add or remove badges for the people in the selected segment.`;
    }
    case ENROLL_USER_ON_NEXT_EVENT_OF_EVENT_TYPE: {
      return `This action will enroll the people of the selected segment to an upcoming event of selected ${lowerLabelEventType}.`;
    }
    case ENROLL_USER_ON_NEXT_EVENT_OF_RECURRING_EVENT_TYPE: {
      return (
        `This action will enroll the people of the selected segment ` +
        `(regardless of previous enrollments) to an upcoming event of selected ${lowerLabelEventType}.`
      );
    }
    case ENROLL_USER_ON_NEXT_EVENTS_OF_ALL_EVENT_TYPES_IN_TRACK: {
      return `This action will enroll the people of the selected segment to all upcoming events of a ${lowerLabelTrack}.`;
    }
    case ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK: {
      return `This action will enroll the people of the selected segment to a upcoming scheduled offering of selected ${lowerLabelTrack}.`;
    }
    case ASSIGN_CONTENT_ITEM_TO_USER: {
      return 'This action will assign a content item to the people of the selected segment.';
    }
    case SYNC_GROUP_FROM_SEGMENT: {
      return 'This action will update a group to match the people of the selected segment.';
    }
    case SYNC_GROUP_TEMPLATE_FROM_SEGMENT: {
      return (
        'This action will identify a group for each person from the selected segment by ' +
        "combining the Group name template with the person's attributes (e.g. title). " +
        'It will then update each such group to match its expected members, while ' +
        'removing non-members. Groups that do not exist will be automatically created. ' +
        'Consider enabling the "Delete stale groups" feature to automatically clean up ' +
        'unused stale groups.'
      );
    }
    default: {
      return '';
    }
  }
};

export const useUpdateAutomatedRule = () => {
  const [update, { status, error }] = useEntities(actions.automatedRule.updateSubmit, null, {
    schema: automatedRuleSchema,
  });
  return { update, status, error };
};
