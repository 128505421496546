import { Assignment, ContentItem } from '~/app/shared-content-item/interfaces';
import { AssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { useContentItemAssignmentActions } from '~/features/contentitems/hooks/useContentItemAssignmentActions';

type TrackItemAssignmentActionsProviderProps = {
  contentItem: ContentItem;
  assignment: Assignment;
  children: React.ReactNode;
};

export function TrackItemAssignmentActionsProvider(props: TrackItemAssignmentActionsProviderProps) {
  const { contentItem, assignment, children } = props;

  const assignmentActions = useContentItemAssignmentActions({
    contentItem,
    assignment,
  });

  return (
    <AssignmentActionsCtx.Provider value={assignmentActions}>
      {children}
    </AssignmentActionsCtx.Provider>
  );
}
