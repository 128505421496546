import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRemoveDjangoApp } from '../hooks/useRemoveDjangoApp';

function RemoveDjangoApp() {
  const history = useHistory();

  const handleRemoveDjangoApp = useRemoveDjangoApp();

  // Only remove django app after navigating inside the app
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      handleRemoveDjangoApp();
    });

    return () => unlisten();
  }, [handleRemoveDjangoApp, history]);

  return null;
}

export { RemoveDjangoApp };
