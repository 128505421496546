import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@mui/material';

import { mapRoute } from '~/services/requests';

import BasePersonCard from './BasePersonCard';

const MenteeCard = ({
  mentee,
  disableAllClicks,
  offerComponent,
  hasMargins,
  cardProps,
  suggestComponent,
}) => {
  const { id } = mentee;

  const userProfileUrl = mapRoute('userProfile', { id });

  const cardContainerStyle = {
    boxShadow: 1,
    ...(hasMargins ? { margin: '12px 10px' } : {}),
  };

  return (
    <Box sx={cardContainerStyle}>
      <BasePersonCard
        user={mentee}
        route={userProfileUrl}
        disableAllClicks={disableAllClicks}
        cardProps={cardProps}
      />
      {offerComponent || suggestComponent}
    </Box>
  );
};

MenteeCard.propTypes = {
  mentee: PropTypes.shape({
    id: PropTypes.number,
    free_hosting_sessions_count: PropTypes.number,
    badges: PropTypes.array,
    name: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.object,
    skills: PropTypes.array,
  }),
  disableAllClicks: PropTypes.bool,
  hasMargins: PropTypes.bool,
  offerComponent: PropTypes.node,
  suggestComponent: PropTypes.node,
  cardProps: PropTypes.object,
};

export { MenteeCard };
