/* eslint-disable lodash/prefer-lodash-method */
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { useCurrentExpressionWithoutPage } from '~/app/backoffice/utils';
import { DASHBOARD_TYPES_URL_MAPPING } from '~/scenes/Dashboard/constants';
import { mapRoute } from '~/services/requests';
import Modal from '~/app/shared/components/Modal';
import { isEmpty } from 'lodash-es';
import { useCreateSegment, useEditSegment } from '~/features/segments/api/mutations';

import SaveSegmentForm from './SaveSegmentForm';

export const SaveSegmentModal = ({ segment, contentType, handleClose }) => {
  const location = useLocation();
  const history = useHistory();
  const isEdit = !isEmpty(segment);
  const expression = useCurrentExpressionWithoutPage();

  const createSegmentMutation = useCreateSegment();
  const editSegmentMutation = useEditSegment(segment?.public_id);

  const onSubmitHandler = isEdit
    ? async (values) => await editSegmentMutation.mutateAsync(values)
    : async (values) => await createSegmentMutation.mutateAsync(values);

  const refreshHistory = ({ public_id }) => {
    if (!isEdit) {
      const newSegmentUrl = mapRoute(DASHBOARD_TYPES_URL_MAPPING[contentType], { public_id });
      history.replace(`${newSegmentUrl}${location.search}`);
    }
  };

  const onSubmitSuccessHandler = (response) => {
    refreshHistory(response);
    handleClose();
  };

  return (
    <Modal
      title={isEdit ? 'Edit Segment' : 'Save as'}
      handleClose={handleClose}
      height="290px"
      width={500}
    >
      <SaveSegmentForm
        initialValues={segment}
        isEdit={isEdit}
        expression={expression}
        contentType={contentType}
        handleClose={handleClose}
        onSubmitHandler={onSubmitHandler}
        onSubmitSuccessHandler={onSubmitSuccessHandler}
      />
    </Modal>
  );
};

SaveSegmentModal.propTypes = {
  segment: PropTypes.object,
  contentType: PropTypes.string,
  handleClose: PropTypes.func,
};

SaveSegmentModal.defaultProps = {
  segment: {},
};

export default SaveSegmentModal;
