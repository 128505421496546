import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { map } from 'lodash-es';

import AutocompleteField from '../AutocompleteField';
import { queries } from '~/queries';
import { useQuery } from '@tanstack/react-query';

const PAGE_SIZE = 50;

const makeOptions = (data) => {
  return map(data.results, ({ id, name, members_count }) => ({
    value: id,
    label: `${name} (${members_count})`,
  }));
};

const GroupSelectField = (props) => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const [queryParams, setQueryParams] = useState({
    view_mode: 'lite',
    page_size: PAGE_SIZE,
  });

  const { data: options, isLoading } = useQuery({
    ...queries.groups.list(queryParams),
    select: makeOptions,
    enabled: shouldFetch,
  });

  const fetchOptions = ({ q, includeValues }) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      q,
      include_ids: includeValues,
    }));

    setShouldFetch(true);
  };

  return (
    <AutocompleteField
      options={options}
      loading={isLoading}
      fetchOptions={fetchOptions}
      {...props}
    />
  );
};

GroupSelectField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default GroupSelectField;
