import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { DEFAULT_DETAIL_PAGE_STALE_TIME } from '~/common/constants/queryClient';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Loading from '~/app/shared/components/Loading';
import { usePublicIdFromURL } from '~/app/shared/hooks';
import { get, isNil } from 'lodash-es';

import CodelabDetailPage from './CodelabDetailPage';

const CodelabDetail = () => {
  const [isTracked, setIsTracked] = useState(false);
  const { trackActivity } = useMetrics();
  const { publicId } = usePublicIdFromURL();

  const { data, status, error, refetch } = useQuery({
    ...queries.codelabs.detail(publicId || '', 'detail'),
    staleTime: DEFAULT_DETAIL_PAGE_STALE_TIME,
    retry: 0,
  });

  useEffect(() => {
    if (status === 'success' && !isTracked) {
      trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
        contentItemId: get(data, 'id', ''),
        contentItemType: get(data, 'content_type', ''),
      });
      setIsTracked(true);
    }
  }, [status, data, trackActivity, isTracked]);

  if (!isNil(data))
    return <CodelabDetailPage content={data as ContentItem} refreshContent={refetch} />;

  if (error) return <Page404 />;

  return <Loading />;
};

export default CodelabDetail;
