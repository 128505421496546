import { useQuery } from '@tanstack/react-query';

import { CONTENT_TYPES } from '~/app/catalog/constants';
import { EVENT_VIEW_MODES } from '~/app/event/constants';
import { queries } from '~/queries';
import { size } from 'lodash-es';
import rql from '~/vendor/rql';

const topEventsListRqlExpression = rql({
  start_time: {
    $gt: '-PT0H',
  },
  $ordering: '-total_assignments',
  status: 'published',
  view_mode: EVENT_VIEW_MODES.listing,
  page_size: 6,
  page: 1,
});

const topContentsListRqlExpression = rql({
  is_inline: false,
  content_type: {
    $out: [
      CONTENT_TYPES.event,
      CONTENT_TYPES.task,
      CONTENT_TYPES.scheduled_track,
      CONTENT_TYPES.question,
      CONTENT_TYPES.text_question,
      CONTENT_TYPES.multiple_choice_question,
    ],
  },
  $ordering: '-total_assignments',
  status: 'published',
  view_mode: 'catalog',
  page_size: 6,
  page: 1,
});

const topExpertsListRqlExpression = rql({
  $ordering: '-unique_people_impacted_count',
  view_mode: 'people',
  page_size: 6,
  page: 1,
  unique_people_impacted_count: {
    $gt: 0,
  },
});

type UseMyHubPageDataParams = {
  rqlExpression: string;
};

export function useMyHubPageData(params: UseMyHubPageDataParams) {
  const { rqlExpression } = params;

  const parsedRqlExpression = size(rqlExpression) > 0 ? `&${rqlExpression}` : '';

  const { data: eventsData, isLoading: isLoadingEvents } = useQuery({
    ...queries.events.list(`${topEventsListRqlExpression}${parsedRqlExpression}`),
  });

  const { data: contentData, isLoading: isLoadingContent } = useQuery({
    ...queries.content_items.list(`${topContentsListRqlExpression}${parsedRqlExpression}`),
  });

  const { data: expertsData, isLoading: isLoadingExperts } = useQuery({
    ...queries.users.list(`${topExpertsListRqlExpression}${parsedRqlExpression}`),
  });

  const isLoadingData = isLoadingEvents || isLoadingContent || isLoadingExperts;

  return {
    topEvents: eventsData,
    topContents: contentData,
    topExperts: expertsData,
    isLoadingData,
  };
}
