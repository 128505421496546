import * as React from 'react';

import { useLearningTypeLabels } from '~/app/catalog/hooks';
import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from '~/app/catalog/constants';
import CodelabFormModal from '~/app/codelab/components/CodelabImportFormModal/CodelabImportFormModal';
import { useContentCreationPermissions } from '~/common/hooks/useContentCreationPermissions';
import CourseFormModal from '~/app/course/components/CourseImportFormModal/CourseImportFormModal';
import NewLinkedContentModal from '~/app/linkedcontent/components/NewLinkedContentModal';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import DropDownMenu from '~/app/shared/components/DropDownMenu';
import { useEncodedCurrentRoute } from '~/app/shared/hooks';
import { FactCheckIcon, LinkIcon } from '~/vendor/mui-icons';

export type DisplayedCreateContentModalType = 'codelab' | 'course' | 'linkedcontent';

type CreateContentDropdownProps = {
  children: (props: { toggleMenu: () => void }) => React.ReactNode;
};

export function CreateContentDropdown(props: CreateContentDropdownProps) {
  const { children } = props;

  const [displayedModal, setDisplayedModal] =
    React.useState<DisplayedCreateContentModalType | null>(null);

  const currentRoute = useEncodedCurrentRoute();
  const { permissions } = useContentCreationPermissions();
  const learningTypeLabels = useLearningTypeLabels();

  const handleDisplayCodelabModal = () => {
    setDisplayedModal('codelab');
  };

  const handleDisplayCourseModal = () => {
    setDisplayedModal('course');
  };

  const handleDisplayLinkedContentModal = () => {
    setDisplayedModal('linkedcontent');
  };

  const handleCloseDisplayedModal = () => {
    setDisplayedModal(null);
  };

  return (
    <>
      <DropDownMenu renderButton={children}>
        {permissions.canCreateArticle && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.articles]}
            color={colors.learningArticle700}
            title={learningTypeLabels[LEARNING_TYPES.articles]}
            route={`${mapRoute('articleCreate')}?origin=${currentRoute}`}
            aria-label="New Article"
          />
        )}
        {permissions.canCreateAssessment && (
          <DropDownMenu.Item
            icon={FactCheckIcon}
            color={colors.learningAssessment700}
            title={learningTypeLabels[LEARNING_TYPES.assessments]}
            route={`${mapRoute('assessmentCreate')}?origin=${currentRoute}`}
            aria-label="New Assessment"
          />
        )}
        {permissions.canCreateCodelab && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.codelabs]}
            color={colors.learningCodelab700}
            title={learningTypeLabels[LEARNING_TYPES.codelabs]}
            onClick={handleDisplayCodelabModal}
            aria-label="New Codelab"
          />
        )}
        {permissions.canCreateCourse && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.courses]}
            color={colors.learningCourse700}
            title={learningTypeLabels[LEARNING_TYPES.courses]}
            onClick={handleDisplayCourseModal}
            aria-label="New Course"
          />
        )}
        {permissions.canCreateEventType && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.event_types]}
            color={colors.learningEventType700}
            title={learningTypeLabels[LEARNING_TYPES.event_types]}
            route={`${mapRoute('eventTypeCreate')}?origin=${currentRoute}`}
            aria-label="New Event Type"
          />
        )}
        {permissions.canCreateLinkedContent && (
          <DropDownMenu.Item
            icon={LinkIcon}
            color={colors.learningLinkedContent700}
            title={learningTypeLabels[LEARNING_TYPES.linkedcontent]}
            onClick={handleDisplayLinkedContentModal}
            aria-label="New Link"
          />
        )}
        {permissions.canCreateProgram && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.programs]}
            color={colors.learningProgram700}
            title={learningTypeLabels[LEARNING_TYPES.programs]}
            route={`${mapRoute('programCreate')}?origin=${currentRoute}`}
            aria-label="New Program"
          />
        )}
        {permissions.canCreateMentorshipProgram && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.mentorship_programs]}
            color={colors.learningMentorshipProgram700}
            title={learningTypeLabels[LEARNING_TYPES.mentorship_programs]}
            route={`${mapRoute('mentorshipProgramCreate')}?origin=${currentRoute}`}
            aria-label="New Program"
          />
        )}
        {permissions.canCreateTrack && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tracks]}
            color={colors.learningTrack700}
            title={learningTypeLabels[LEARNING_TYPES.tracks]}
            route={`${mapRoute('trackCreate')}?origin=${currentRoute}`}
            aria-label="New Track"
          />
        )}
        {permissions.canCreateVideo && (
          <DropDownMenu.Item
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.videos]}
            color={colors.learningVideo600}
            title={learningTypeLabels[LEARNING_TYPES.videos]}
            route={`${mapRoute('videoCreate')}?origin=${currentRoute}`}
            aria-label="New Video"
          />
        )}
      </DropDownMenu>

      {displayedModal === 'codelab' && <CodelabFormModal handleClose={handleCloseDisplayedModal} />}
      {displayedModal === 'course' && (
        <CourseFormModal handleClose={handleCloseDisplayedModal} action="New" />
      )}
      {displayedModal === 'linkedcontent' && (
        <NewLinkedContentModal handleClose={handleCloseDisplayedModal} />
      )}
    </>
  );
}
