import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Dashboard from '~/app/backoffice/components/Dashboard';
import DashboardTopBar from '~/app/backoffice/components/Dashboard/DashboardTopBar';
import RQLDashboard from '~/app/backoffice/components/Dashboard/RQLDashboard';
import { useRQLRouteFilters } from '~/app/backoffice/hooks';
import actions from '~/app/entities/actions';
import { useEntities } from '~/app/entities/utils';
import { queries } from '~/queries';
import Page404 from '~/scenes/404';
import {
  alphabeticalAscendingText,
  alphabeticalDescendingText,
  DASHBOARD_EVENTS,
} from '~/scenes/Dashboard/constants';
import ViewMore from '~/scenes/Dashboard/Events/ViewMore';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { ApiURLs } from '~/services/requests-base';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import { usePublicIdFromURL, useLabels } from '~/app/shared/hooks';
import { map, size } from 'lodash-es';

type EventSection = {
  segmentPublicId: string;
  filterObj: any;
  updateOrdering: any;
  updatePagination: any;
};

const TextArea = styled.div`
  color: ${colors.neutral600};
`;

const PAGE_SIZE = 15;

const EventSection = (props: EventSection) => {
  const { segmentPublicId, filterObj, updateOrdering, updatePagination } = props;

  const queryParams = useLocation();
  const searchParams = queryParams.search;

  const { label_event_type: labelEventType } = useLabels();

  const { data, isLoading, isError, refetch } = useQuery(
    queries.segments.event(segmentPublicId, searchParams)
  );

  useEffect(() => {
    refetch();
  }, [segmentPublicId, searchParams, refetch]);

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  if (!data) return null;

  const { results, count } = data;

  return (
    <RQLDashboard
      dashboardName={DASHBOARD_EVENTS}
      rows={results}
      pageSize={PAGE_SIZE}
      totalCount={count}
      requestStatus={isLoading ? STATUS_LOADING : STATUS_DONE}
      refreshDashboard={queries.segments.report}
      filterObj={filterObj}
      updateOrdering={updateOrdering}
      updatePagination={updatePagination}
    >
      <Dashboard.Column
        size="20"
        header={
          <Dashboard.Header
            title="Name"
            orderingKey="name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <Dashboard.CellLink
            to={mapRoute('eventDetails', { public_id_and_slug: `${row.public_id_and_slug}` })}
            $highlighted
          >
            {row.name}
          </Dashboard.CellLink>
        )}
      </Dashboard.Column>
      <Dashboard.Column
        size="15"
        header={
          <Dashboard.Header
            title={labelEventType}
            orderingKey="event_type.name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) =>
          row.event_type && (
            <Dashboard.CellLink
              to={mapRoute('eventTypeDetails', {
                public_id_and_slug: `${row.event_type.public_id_and_slug}`,
              })}
            >
              {row.event_type.name}
            </Dashboard.CellLink>
          )
        }
      </Dashboard.Column>
      <Dashboard.Column
        size="15"
        header={
          <Dashboard.Header
            title="Location"
            orderingKey="location.name"
            ascendingText={alphabeticalAscendingText}
            descendingText={alphabeticalDescendingText}
          />
        }
      >
        {({ row }) => (
          <Dashboard.LocationCell
            data={{
              location: row.location,
              allowsOnline: row.is_online,
              allowsLocal: true,
            }}
          />
        )}
      </Dashboard.Column>
      <Dashboard.Column
        size="22"
        header={<Dashboard.Header title="Sessions" orderingKey="start_time" />}
      >
        {({ row }) => <ViewMore eventTimezone={row.timezone} timeslots={row.timeslots} />}
      </Dashboard.Column>
      <Dashboard.Column size="20" header={<Dashboard.Header title="Presenters" />}>
        {({ row }) => <Dashboard.MultipleUserCell data={row.presenters} />}
      </Dashboard.Column>
      <Dashboard.Column
        size="15"
        header={<Dashboard.Header title="Roster" orderingKey="going_enrollments_count" />}
      >
        {({ row }) => (
          <TextArea>
            {row.is_local && (
              <div>
                <Text color={colors.neutral900} medium>
                  {row.going_local_enrollments_count}
                </Text>{' '}
                enrolled locally
              </div>
            )}
            {row.is_online && (
              <div>
                <Text color={colors.neutral900} medium>
                  {row.going_online_enrollments_count}
                </Text>{' '}
                enrolled online
              </div>
            )}
            {row.waitlist_local_enrollments_count > 0 && (
              <div>
                <Text color={colors.neutral900} medium>
                  {row.waitlist_local_enrollments_count}
                </Text>{' '}
                on local waitlist
              </div>
            )}
            {row.waitlist_going_online_enrollments_count > 0 && (
              <div>
                <Text color={colors.neutral900} medium>
                  {row.waitlist_going_online_enrollments_count}
                </Text>{' '}
                on online waitlist
              </div>
            )}
            {row.checkins_count > 0 && (
              <div>
                <Text color={colors.neutral900} medium>
                  {row.checkins_count}
                </Text>{' '}
                checked in
              </div>
            )}
          </TextArea>
        )}
      </Dashboard.Column>
      <Dashboard.Column size="10" header={<Dashboard.Header title="Privacy" />}>
        {({ row }) => {
          if (row.is_hidden) {
            return <TextArea>Hidden</TextArea>;
          }
          if (size(row.groups) > 0) {
            return <TextArea>Restricted</TextArea>;
          }
          return <TextArea>Public</TextArea>;
        }}
      </Dashboard.Column>
      <Dashboard.Column
        size="20"
        header={<Dashboard.Header title="Last Engaged At" orderingKey="last_engaged_at" />}
      >
        {({ row }) => <Dashboard.DateTimeCell data={row.last_engaged_at} />}
      </Dashboard.Column>
    </RQLDashboard>
  );
};

const buildExportUrl = (publicId: string) => {
  return ApiURLs['segments_api:event-export'](publicId);
};

const EventReport = () => {
  const { publicId: segmentPublicId } = usePublicIdFromURL();

  const {
    data: segment,
    isLoading,
    isError,
  } = useQuery(queries.segments.report(segmentPublicId || ''));

  const [fetchFilters, { data: filters, status: filterStatus }] = useEntities(
    actions.userData.retrieveFilters,
    null
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const { filterObj, updateOrdering, updatePagination } = useRQLRouteFilters(
    'name',
    map(filters, (item) => item.filter),
    PAGE_SIZE
  );

  if (isError) return <Page404 />;

  if (isLoading || filterStatus === STATUS_LOADING) return <Loading />;

  if (!segment) return null;

  return (
    <>
      <DashboardTopBar title={segment.name} subTitle={segment.description}>
        <Dashboard.ExportCsvButton
          dashboardName="Event-Report"
          buttonUrl={buildExportUrl(segment.public_id)}
        />
      </DashboardTopBar>
      <EventSection
        segmentPublicId={segment.public_id}
        filterObj={filterObj}
        updateOrdering={updateOrdering}
        updatePagination={updatePagination}
      />
    </>
  );
};

export default EventReport;
