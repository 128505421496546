import { Box, Typography } from '@mui/material';
import {
  ChartsItemContentProps,
  DefaultizedPieValueType,
  PieChart,
  useItemTooltip,
} from '@mui/x-charts-pro';
import { InsightWidget, InsightWidgetHeader } from '~/common/components/InsightWidget';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {
  ASSIGNMENT_STATES,
  ASSIGNMENT_STATES_CHART_COLORS,
  ASSIGNMENT_STATES_LABELS,
} from '~/app/assignments/constants';
import { map } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';
import { AssignmentStatusInsightResponse, AssignmentStatusInsightState } from '../types';
import {
  ChartTooltipContent,
  ChartTooltipContentBody,
  ChartTooltipContentDivider,
  ChartTooltipContentHeader,
} from '~/common/components/ChartTooltipContent';

function transformChartData(data: AssignmentStatusInsightResponse['states'][number]) {
  const statusLabel = ASSIGNMENT_STATES_LABELS[data.state] ?? data.state;
  const statusColor =
    ASSIGNMENT_STATES_CHART_COLORS[data.state] ??
    ASSIGNMENT_STATES_CHART_COLORS[ASSIGNMENT_STATES.not_started];

  return {
    id: data.state,
    value: data.percentage,
    label: statusLabel,
    color: statusColor,
  };
}

type TooltipContentStatProps = {
  label: string;
  value: string;
};

function TooltipContentStat(props: TooltipContentStatProps) {
  const { label, value } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight: 700,
          fontSize: '0.875rem',
        }}
      >
        {value}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontWeight: 300,
          fontSize: '0.875rem',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
type AssignmentStatusTooltipContentProps = ChartsItemContentProps<'pie'> & {
  assignmentStatesMap: Map<string, AssignmentStatusInsightState>;
};

function AssignmentStatusTooltipContent(props: AssignmentStatusTooltipContentProps) {
  const { assignmentStatesMap } = props;

  const itemData = useItemTooltip<'pie'>();

  if (!itemData) {
    return null;
  }

  const currentState = assignmentStatesMap.get(itemData.value.id as string);

  if (!currentState) {
    return null;
  }

  return (
    <ChartTooltipContent>
      <ChartTooltipContentHeader title={itemData.label ?? ''} />
      <ChartTooltipContentDivider />
      <ChartTooltipContentBody>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TooltipContentStat label={itemData.label ?? ''} value={`${itemData.formattedValue}`} />
          <TooltipContentStat label="Assignments" value={`${currentState.count}`} />
        </Box>
      </ChartTooltipContentBody>
      <ChartTooltipContentDivider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '5px',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: '0.75rem',
            lineHeight: 1.4,
            fontWeight: 500,
          }}
        >
          Click to review
        </Typography>
      </Box>
    </ChartTooltipContent>
  );
}

type AssignmentStatusInsightWidgetProps = {
  queryExpression: string;
  onChartItemClick?: (state: AssignmentStatusInsightState) => void;
};

function AssignmentStatusInsightWidget(props: AssignmentStatusInsightWidgetProps) {
  const { queryExpression, onChartItemClick } = props;

  const { data: assignmentStatusInsightData, status } = useQuery({
    ...queries.content_items.assignmentStatusInsights(queryExpression),
  });

  const statesArr = assignmentStatusInsightData?.states ?? [];
  const mappedChartData = map(statesArr, transformChartData);

  const assignmentStatesMap = new Map<string, AssignmentStatusInsightState>(
    statesArr.map((state) => [state.state, state])
  );

  const handleChartItemClick = (item: DefaultizedPieValueType) => {
    const currentState = assignmentStatesMap.get(item.id as string);

    if (!currentState) {
      return;
    }

    onChartItemClick?.(currentState);
  };

  return (
    <InsightWidget>
      <InsightWidgetHeader
        title="Assignment Status"
        icon={<AssignmentTurnedInIcon fontSize="small" sx={{ color: '#0288D1' }} />}
      />

      <Box
        sx={{
          marginTop: '16px',
        }}
      >
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: 1.75,
          }}
        >
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
              fontSize: '1.5rem',
              lineHeight: 1.33,
            }}
          >
            {assignmentStatusInsightData?.total}
          </Typography>
          {` Assignments`}
        </Typography>
        <Typography variant="body2">Assignments vs Completion [%]</Typography>
      </Box>

      <Box
        sx={{
          marginTop: '16px',
          flex: 1,
          minHeight: '171px',
        }}
      >
        <PieChart
          series={[
            {
              data: mappedChartData,
              arcLabel: (data) => `${data.value}%`,
              valueFormatter: (data) => `${data.value}%`,
              arcLabelMinAngle: 10,
              innerRadius: 37.5,
              outerRadius: 75,
              cx: 75,
            },
          ]}
          loading={status === 'loading'}
          width={296}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          onItemClick={(_, __, item) => {
            handleChartItemClick(item);
          }}
          slotProps={{
            legend: {
              padding: {
                right: 20,
              },
            },
          }}
          slots={{
            itemContent: (props) => (
              <AssignmentStatusTooltipContent
                {...props}
                assignmentStatesMap={assignmentStatesMap}
              />
            ),
          }}
        />
      </Box>
    </InsightWidget>
  );
}

export { AssignmentStatusInsightWidget };
