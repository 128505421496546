import { ONE_MINUTE_MS } from './time';

/**
 * Default stale time for queries.
 * @description 1 minute
 */
export const DEFAULT_STALE_TIME = ONE_MINUTE_MS;

/**
 * Default stale time for detail pages.
 * @description 10 minutes
 */
export const DEFAULT_DETAIL_PAGE_STALE_TIME = ONE_MINUTE_MS * 10;
