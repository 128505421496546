import * as React from 'react';
import { isNil, isString, isEmpty, noop } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

type RouteActiveModalCtxType = {
  activeModal: string | null;
  isModalActive: boolean;
  setActiveModal: (modal: string | null) => void;
  closeActiveModal: () => void;
};

const defaultRouteActiveModalCtx: RouteActiveModalCtxType = {
  activeModal: null,
  isModalActive: false,
  setActiveModal: noop,
  closeActiveModal: noop,
};

const RouteActiveModalCtx = React.createContext<RouteActiveModalCtxType>(
  defaultRouteActiveModalCtx
);

function isQueryStateValid(value: ParsedQuery[string]): value is string {
  if (isNil(value) || !isString(value) || isEmpty(value)) {
    return false;
  }

  return true;
}

function RouteActiveModalProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const history = useHistory();
  const location = useLocation();

  const handleUpdateQueryState = React.useCallback(
    (newActiveModal: string | null) => {
      const currentSearch = queryString.parse(location.search);

      const newSearch = queryString.stringify({
        ...currentSearch,
        active_modal: newActiveModal === null ? undefined : newActiveModal,
      });

      history.replace(`${location.pathname}?${newSearch}${location.hash}`);
    },
    [location.pathname, location.hash, location.search, history]
  );

  const activeModal = React.useMemo(() => {
    const parsedSearch = queryString.parse(location.search);
    const activeModalQuery = parsedSearch.active_modal;

    if (!isQueryStateValid(activeModalQuery)) {
      return null;
    }

    return activeModalQuery;
  }, [location.search]);

  React.useEffect(() => {
    /*
      Clean the query state when the value is empty or invalid
      ie:
      - is not a string
      - is an empty string
    */

    const parsedSearch = queryString.parse(location.search);
    const activeModalQuery = parsedSearch.active_modal;

    // Skip if there is no value or it is valid
    if (isNil(activeModalQuery) || isQueryStateValid(activeModalQuery)) {
      return;
    }

    handleUpdateQueryState(null);
  }, [location.search, handleUpdateQueryState]);

  const isModalActive = !isNil(activeModal);

  const handleSetActiveModal = React.useCallback(
    (modal: string | null) => {
      handleUpdateQueryState(modal);
    },
    [handleUpdateQueryState]
  );

  const handleCloseActiveModal = React.useCallback(() => {
    handleUpdateQueryState(null);
  }, [handleUpdateQueryState]);

  const ctxValue = {
    activeModal,
    isModalActive,
    setActiveModal: handleSetActiveModal,
    closeActiveModal: handleCloseActiveModal,
  };

  return <RouteActiveModalCtx.Provider value={ctxValue}>{children}</RouteActiveModalCtx.Provider>;
}

function useRouteActiveModalCtx() {
  const ctx = React.useContext(RouteActiveModalCtx);

  if (isNil(ctx)) {
    throw new Error('useRouteActiveModalCtx must be used within a RouteActiveModalProvider');
  }

  return ctx;
}

export { RouteActiveModalProvider, useRouteActiveModalCtx };
