import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';

import ButtonLink from '~/app/shared/components/ButtonLink';
import Text from '~/app/shared/components/Text';
import {
  OPERATION_PARAMETERS_MAP,
  OVERCAPACITY_POLICY_OPTIONS,
  ATTENDANCE_METHOD_OPTIONS,
  ADD_REMOVE_OPTIONS,
  MENTOR_OR_MENTEE_OPTIONS,
  TIME_TO_COMPLETE_CUSTOM,
  TIME_TO_COMPLETE_OPTIONS,
  PREFERRED_ATTENDANCE_METHOD_OPTIONS,
  SCHEDULED_TRACK_OVERCAPACITY_POLICY_OPTIONS,
  ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK,
} from '~/app/automated-rule/constants';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import BadgeSelectField from '~/app/inputs/components/BadgeSelectField';
import MentorshipProgramSelectField from '~/app/inputs/components/MentorshipProgramSelectField';
import ContentTypeSelectField from '~/app/inputs/components/ContentTypeSelectField';
import GroupSelectField from '~/app/inputs/components/GroupSelectField';
import LocationSelectField from '~/app/inputs/components/LocationSelectField';
import SelectField from '~/app/inputs/components/SelectField';
import TextField from '~/app/inputs/components/TextField';
import UserSelectField from '~/app/inputs/components/UserSelectField/UserSelectField';
import { mapRoute } from '~/services/requests';
import { parseNumber } from '~/services/utils';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoText from '~/app/shared/components/InfoText';
import Form from '~/app/shared/components/OldForm';
import { useFormSelector, useLabels } from '~/app/shared/hooks';
import { map, toLower } from 'lodash-es';

const validateRequired = Form.validations.required();

const ParametersFields = ({ form, operation }) => {
  const {
    label_event_type: labelEventType,
    label_track: labelTrack,
    label_track_plural: labelTrackPlural,
  } = useLabels();

  const initialTimeToComplete = useFormSelector(form, 'time_to_complete');
  const [timeToComplete, setTimeToComplete] = useState('');

  useEffect(() => {
    if (initialTimeToComplete) setTimeToComplete(initialTimeToComplete);
  }, [initialTimeToComplete]);

  const offeringLabel =
    operation === ENROLL_USER_ON_NEXT_SCHEDULED_OFFERING_OF_TRACK
      ? `scheduled ${toLower(labelTrackPlural)}`
      : 'events';

  const fieldsMap = {
    program_id: (
      <FormFieldGroup>
        <Field
          name="program_id"
          label="Mentorship Program"
          required
          component={MentorshipProgramSelectField}
          validate={[validateRequired]}
        />
        <InfoText top={4} bottom={8} content="Select the Mentorship Program." />
      </FormFieldGroup>
    ),
    badge_id: (
      <FormFieldGroup>
        <Field
          name="badge_id"
          label="Badge"
          required
          component={BadgeSelectField}
          validate={[validateRequired]}
        />
        <InfoText top={4} bottom={8} content="Select the badge." />
      </FormFieldGroup>
    ),
    add_or_remove: (
      <FormFieldGroup>
        <Field
          name="add_or_remove"
          label="Add or remove"
          required
          component={SelectField}
          options={ADD_REMOVE_OPTIONS}
          validate={[validateRequired]}
        />
        <InfoText top={4} bottom={8} content="Do you want to add or remove?" />
      </FormFieldGroup>
    ),
    mentor_or_mentee: (
      <FormFieldGroup>
        <Field
          name="mentor_or_mentee"
          label="Mentor or mentee"
          required
          component={SelectField}
          options={MENTOR_OR_MENTEE_OPTIONS}
          validate={[validateRequired]}
        />
        <InfoText
          top={4}
          bottom={8}
          content="Do you want to add/remove a user as a mentor or mentee?"
        />
      </FormFieldGroup>
    ),
    event_type_public_id: (
      <FormFieldGroup>
        <Field
          name="event_type_public_id"
          label={labelEventType}
          required
          component={ContentTypeSelectField}
          validate={[validateRequired]}
          contentType={CONTENT_TYPES.eventtype}
        />
        <InfoText
          top={4}
          bottom={8}
          content={`Select the ${labelEventType} to filter events by.`}
        />
      </FormFieldGroup>
    ),
    location_ids: (
      <FormFieldGroup>
        <Field name="location_ids" label="Locations" component={LocationSelectField} multiple />
        <InfoText
          top={4}
          bottom={8}
          content={`Select locations to filter ${offeringLabel} by. If unspecified, all locations will be considered.`}
        />
      </FormFieldGroup>
    ),
    overcapacity_policy: (
      <FormFieldGroup>
        <Field
          name="overcapacity_policy"
          label="Overcapacity Policy"
          required
          component={SelectField}
          options={OVERCAPACITY_POLICY_OPTIONS}
          validate={[validateRequired]}
        />
        <InfoText top={4} bottom={8} content="What to do if the event is full." />
      </FormFieldGroup>
    ),
    scheduled_track_overcapacity_policy: (
      <FormFieldGroup>
        <Field
          name="scheduled_track_overcapacity_policy"
          label="Overcapacity Policy"
          required
          component={SelectField}
          options={SCHEDULED_TRACK_OVERCAPACITY_POLICY_OPTIONS}
          validate={[validateRequired]}
        />
        <InfoText
          top={4}
          bottom={8}
          content={`What to do if the scheduled ${toLower(labelTrack)} is full.`}
        />
      </FormFieldGroup>
    ),
    days_offset: (
      <FormFieldGroup>
        <Field
          name="days_offset"
          label="Day Offset"
          component={TextField}
          type="number"
          parse={parseNumber}
          inputProps={{ min: 0 }}
          placeholder="00"
        />
        <InfoText
          top={4}
          bottom={8}
          content={`Only consider ${offeringLabel} after this time offset (in days) relative to when the rule is evaluated or the person's hire date (whichever is later).`}
        />
      </FormFieldGroup>
    ),
    attendance_type: (
      <FormFieldGroup>
        <Field
          name="attendance_type"
          label="Attendance Method"
          component={SelectField}
          options={ATTENDANCE_METHOD_OPTIONS}
          required
          validate={[validateRequired]}
        />
        <InfoText
          top={4}
          bottom={8}
          content="Select how people will be attending the event. Only events with the same attendance method will be considered."
        />
      </FormFieldGroup>
    ),
    preferred_attendance_type: (
      <FormFieldGroup>
        <Field
          name="preferred_attendance_type"
          label="Preferred Attendance Method"
          component={SelectField}
          options={PREFERRED_ATTENDANCE_METHOD_OPTIONS}
          required
          validate={[validateRequired]}
        />
        <InfoText top={4} bottom={8} content="Select the preferred attendance method." />
      </FormFieldGroup>
    ),
    track_public_id: (
      <FormFieldGroup>
        <Field
          name="track_public_id"
          label={labelTrack}
          required
          component={ContentTypeSelectField}
          validate={[validateRequired]}
          contentType={CONTENT_TYPES.track}
        />
        <InfoText
          top={4}
          bottom={8}
          content={`Select which ${toLower(labelTrack)} you want to select content from.`}
        />
      </FormFieldGroup>
    ),
    content_item_public_id: (
      <FormFieldGroup>
        <Field
          name="content_item_public_id"
          label="Content Item"
          required
          component={ContentTypeSelectField}
          validate={[validateRequired]}
          contentType={[
            CONTENT_TYPES.article,
            CONTENT_TYPES.assessment,
            CONTENT_TYPES.linkedcontent,
            CONTENT_TYPES.codelab,
            CONTENT_TYPES.course,
            CONTENT_TYPES.eventtype,
            CONTENT_TYPES.track,
            CONTENT_TYPES.video,
          ]}
        />
        <InfoText
          top={4}
          bottom={8}
          content="Select which Content Item you want to assign users to"
        />
      </FormFieldGroup>
    ),
    assigner_id: (
      <FormFieldGroup>
        <Field
          name="assigner_id"
          label="Assigner"
          required
          component={UserSelectField}
          validate={[validateRequired]}
        />
        <InfoText
          top={4}
          bottom={8}
          content="This user will appear as the assigner (e.g. in the invitation email)."
        />
      </FormFieldGroup>
    ),
    enrolled_by_id: (
      <FormFieldGroup>
        <Field name="enrolled_by_id" label="Enrolled by" component={UserSelectField} />
        <InfoText
          top={4}
          bottom={8}
          content="This user will appear as the enroller (e.g. in the invitation email)."
        />
      </FormFieldGroup>
    ),
    time_to_complete: (
      <FormFieldGroup>
        <Field
          name="time_to_complete"
          label="Time to Complete"
          component={SelectField}
          options={TIME_TO_COMPLETE_OPTIONS}
          validate={[validateRequired]}
          onChange={(value) => setTimeToComplete(value)}
          clearable
        />
        <InfoText
          top={4}
          bottom={8}
          content="Set a time interval in which this content should be completed after a person is assigned."
        />
      </FormFieldGroup>
    ),
    time_to_complete_days_offset:
      timeToComplete === TIME_TO_COMPLETE_CUSTOM ? (
        <FormFieldGroup>
          <Field
            name="time_to_complete_days_offset"
            label="Time to Complete (Custom Duration)"
            component={TextField}
            type="number"
            parse={parseNumber}
            inputProps={{ min: 0 }}
            placeholder="00"
          />
          <InfoText
            top={4}
            bottom={8}
            content="Set a days interval in which this content should be completed after a person is assigned."
          />
        </FormFieldGroup>
      ) : null,
    group_id: (
      <FormFieldGroup>
        <Field
          name="group_id"
          label="Group"
          required
          component={GroupSelectField}
          validate={[validateRequired]}
        />
        <InfoText
          top={4}
          bottom={8}
          content={
            <Text>
              Select which group will be synchronized with the selected segment.{' '}
              <ButtonLink route={mapRoute('groupCreate')} target="_blank">
                Create a new group.
              </ButtonLink>
            </Text>
          }
        />
      </FormFieldGroup>
    ),
    group_name_template: (
      <FormFieldGroup>
        <Field
          name="group_name_template"
          label="Group name template"
          component={TextField}
          required
        />
        <InfoText
          top={4}
          bottom={8}
          content={
            <Text>
              <p>
                Specify the group name as a template. This template may be a simple fixed string.
                For example: &quot;People Managers&quot;.
              </p>
              <p>
                The template may also reference any of the following people attributes as
                placeholders:
                <code>
                  {`{{`} title {`}}`}
                </code>
                ,{' '}
                <code>
                  {`{{`} department {`}}`}
                </code>
                ,
                <code>
                  {`{{`} location {`}}`}
                </code>
                ,{' '}
                <code>
                  {`{{`} manager.name {`}}`}
                </code>
                ,
                <code>
                  {`{{`} manager.email {`}}`}
                </code>
                , and{' '}
                <code>
                  {`{{`} custom.* {`}}`}
                </code>
                (e.g.{' '}
                <code>
                  {`{{`} custom.employee_type {`}}`}
                </code>
                , assuming that
                <code>employee_type</code> was a valid custom attribute).
              </p>
              <p>
                For example, the following template:
                <code>
                  {`{`}% if manager %{`}`}
                  {`{{`} manager.email {`}}'`}s directs{`{`}% endif %{`}`}
                </code>
                would evaluate to a group named <em>&lt;manager.email&gt;&apos;s directs</em>
                {` `}
                for each manager, and add their directs to this group.
              </p>
              <p>
                Empty space on either side of the name is automatically trimmed. Group templates
                that evaluate to an empty string will be ignored.
              </p>
            </Text>
          }
        />
      </FormFieldGroup>
    ),
    group_name_delimiter: (
      <FormFieldGroup>
        <Field name="group_name_delimiter" label="Group name delimiter" component={TextField} />
        <InfoText
          top={4}
          bottom={8}
          content={
            <Text>
              <p>
                Specify an optional group name delimiter. This can be used to split a compound group
                name into multiple ones.
              </p>
              <p>
                For example, if the <em>Group name template</em> was set to
                &quot;Managers,Leaders&quot;, and the delimiter was set to &quot;,&quot;, then this
                would result in two groups being created: &quot;Managers&quot; and
                &quot;Leaders&quot;.
              </p>
              <p>
                Another example is to use this to assign users to multiple groups that may be
                defined as a custom attribute (e.g.{' '}
                <code>
                  {`{{`} custom.groups {`}}`}
                </code>
                ).
              </p>
              <p>
                Note, any spaces on either side of the delimited values are automatically stripped.
                So, &quot;One, Two, Three&quot; would result in groups &quot;One&quot;,
                &quot;Two&quot;, and &quot;Three&quot;.
              </p>
              <p>
                Learn more about{` `}
                <ButtonLink
                  url="https://help.plusplus.app/en/articles/4887588-how-to-manage-groups-automatically"
                  target="_blank"
                >
                  How to manage groups automatically
                </ButtonLink>
                .
              </p>
            </Text>
          }
        />
      </FormFieldGroup>
    ),
  };

  return map(OPERATION_PARAMETERS_MAP[operation], (fieldName) => {
    const component = fieldsMap[fieldName];
    if (component === undefined) {
      throw new Error(`The parameter field '${fieldName}' is not mapped.`);
    }

    return <React.Fragment key={`${operation}-${fieldName}`}>{component}</React.Fragment>;
  });
};

ParametersFields.propTypes = {
  form: PropTypes.string,
  operation: PropTypes.string,
};

export default ParametersFields;
