import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LEARNING_TYPE_FACILITATOR_MAIN_ROLE, LEARNING_TYPES } from '~/app/catalog/constants';
import colors from '~/services/colors';
import { mapRoute } from '~/services/requests';
import { filter, map, size, take } from 'lodash-es';

import { ValueOf } from 'type-fest';
import { Facilitator } from '~/app/shared/components/types';
import { PeoplePill } from '../PeoplePill';

const MoreOwnersChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: colors.neutral600,
  fontSize: '0.75rem',
}));

function getOwners(facilitators: any[], learningType: string) {
  const mainRoleForLearningType = LEARNING_TYPE_FACILITATOR_MAIN_ROLE[learningType];

  const mainOwners = mainRoleForLearningType
    ? filter(facilitators, ['role', mainRoleForLearningType])
    : facilitators;

  const coMainOwners = mainRoleForLearningType
    ? filter(facilitators, ['role', `co_${mainRoleForLearningType}`])
    : [];

  return map([...mainOwners, ...coMainOwners], 'user');
}

const MAX_OWNERS_DISPLAY = 2;

type ContentItemCardOwnersProps = {
  facilitators: Facilitator[];
  learningType: ValueOf<typeof LEARNING_TYPES>;
};

export function ContentItemCardOwners(props: ContentItemCardOwnersProps) {
  const { facilitators, learningType } = props;

  const owners = getOwners(facilitators, learningType);

  const ownersCount = size(owners);
  const ownersToDisplay = take(owners, MAX_OWNERS_DISPLAY);
  const hasMoreOwners = ownersCount > MAX_OWNERS_DISPLAY;
  const hasMoreCount = hasMoreOwners ? ownersCount - MAX_OWNERS_DISPLAY : 0;

  if (ownersCount === 0) {
    return null;
  }

  return (
    <Box display="flex" gap="8px">
      <Box display="flex" gap="8px" minWidth={0}>
        {map(ownersToDisplay, (owner: any) => (
          <PeoplePill
            key={owner.id}
            image={owner.profile_image}
            name={owner.display_name}
            route={mapRoute('userProfile', { id: owner.id })}
          />
        ))}
      </Box>

      {hasMoreCount ? <MoreOwnersChip size="small" label={`+${hasMoreCount}`} /> : null}
    </Box>
  );
}
