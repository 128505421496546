import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useContentFeedback } from '~/app/catalog/hooks';
import ContentItemHeader from '~/app/shared-content-item/components/ContentItemHeader';
import { usePublicIdFromURL, useToggles } from '~/app/shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from '~/app/stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { LEARNING_TYPES } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import { mentorshipProgramSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import BaseContainer from '~/app/navigation/components/BaseContainer';
import ProgramPeopleContainer from '~/app/program/components/ProgramPeopleContainer';
import Page404 from '~/scenes/404';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemBody from '~/app/shared-content-item/components/ContentItemBody';
import DescriptionContent from '~/app/shared-content-item/components/DescriptionContent';
import { PageContainer, PageBody } from '~/app/shared/components/DefaultPage';
import Loading from '~/app/shared/components/Loading';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import { STATUS_DONE, STATUS_ERROR } from '~/app/shared/constants';
import {
  CAN_JOIN_AS_MENTEE,
  CAN_JOIN_AS_MENTOR,
  CREATE_MENTORSHIP_SESSION_PERMISSION,
} from '~/app/shared/permissions';
import { constant, includes, trim } from 'lodash-es';
import { Tab } from '~/common/components/Tabs';
import { Paper, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '~/vendor/mui-lab';

import MentorshipProgramContextMenu from './MentorshipProgramContextMenu/MentorshipProgramContextMenu';
import MentorshipProgramMenteeList from './MentorshipProgramMenteeList';
import MentorshipProgramMentorList from './MentorshipProgramMentorList';
import MentorshipProgramSessionList from './MentorshipProgramSessionList';

const InDevelopment = styled.h1`
  display: flex;
  justify-content: center;
`;

interface MentorshipProgramDetailProps {
  clickableName: boolean;
  isTrackItem: boolean;
}

export const MentorshipProgramDetail = ({
  clickableName = false,
  isTrackItem = false,
}: MentorshipProgramDetailProps) => {
  const history = useHistory();
  const location = useLocation();
  const { publicId } = usePublicIdFromURL();
  const [selectedTab, setSelectedTab] = useState(trim(location.hash, '#') || 'details');
  const { toggle_mentorship_programs: toggleMentorshipProgams } = useToggles();
  const [fetchContent, { data: content, status: contentStatus }] = useEntities(
    actions.mentorshipProgram.retrieveDetails,
    ({ status }) => {
      if (status === STATUS_DONE) {
        content.permissions.push('see_feedback');
      }
    },
    {
      schema: mentorshipProgramSchema,
    }
  );

  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.mentorship_programs
  );
  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [false, false],
  });

  useEffect(() => {
    fetchContent(publicId);
  }, [fetchContent, publicId]);

  if (contentStatus == STATUS_ERROR) return <Page404 />;

  if (!toggleMentorshipProgams)
    return (
      <InDevelopment>
        Coming Soon!
        <br />
        This page is under construction.
      </InDevelopment>
    );

  if (contentStatus !== STATUS_DONE) return <Loading />;

  const joinAsMentee = includes(content.permissions, CAN_JOIN_AS_MENTEE);
  const joinAsMentor = includes(content.permissions, CAN_JOIN_AS_MENTOR);
  const canCreateSession = includes(content.permissions, CREATE_MENTORSHIP_SESSION_PERMISSION);

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const handleTabNavigation = (_event, value) => {
    setSelectedTab(value);
    history.push('#' + value);
  };
  return (
    <BaseContainer>
      <PageTitle title={content.name} />
      <ContentItemBackgroundPanel $isTrackItem={isTrackItem} />
      <BreadCrumbs content={content} padding="20px 0 0 0" margin="0" />
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          contextMenu={<MentorshipProgramContextMenu content={content} />}
          clickableName={clickableName}
          primaryActionButton={constant(null)}
          secondaryActionButton={constant(null)}
        />
        <PageBody>
          <ContentItemBody>
            <TabContext value={selectedTab}>
              <Paper elevation={3} sx={{ display: 'flex', alignSelf: 'center' }}>
                <TabList
                  onChange={handleTabNavigation}
                  centered
                  aria-label="Mentorship tabs"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Tab label="Details" value="details" sx={{ width: '120px' }} />
                  <Tab
                    label={content.settings.label_mentorship_sessions_creator_title_plural.value}
                    value="mentors"
                    sx={{ width: '120px' }}
                  />
                  <Tab
                    label={content.settings.label_mentorship_sessions_attendee_title_plural.value}
                    value="mentees"
                    sx={{ width: '120px' }}
                  />
                  <Tab label="Sessions" value="sessions" sx={{ width: '120px' }} />
                </TabList>
              </Paper>
              <TabPanel value="details" sx={{ padding: 0 }}>
                <Stack direction="column" spacing={2}>
                  <DescriptionContent content={content} />
                  <ProgramPeopleContainer
                    content={content}
                    contentType={LEARNING_TYPES.mentorship_programs}
                  />
                  {showFeedback && (
                    <BaseFeedbackContainer
                      content={content}
                      feedbackList={feedback}
                      mode={feedbackContainerMode}
                      isLoading={isLoadingFeedback}
                    />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel value="mentors" sx={{ padding: 0 }}>
                <MentorshipProgramMentorList
                  program={content}
                  canJoin={joinAsMentor}
                  canScheduleSession={canCreateSession}
                />
              </TabPanel>
              <TabPanel value="mentees" sx={{ padding: 0 }}>
                <MentorshipProgramMenteeList program={content} canJoin={joinAsMentee} />
              </TabPanel>
              <TabPanel value="sessions" sx={{ padding: 0 }}>
                <MentorshipProgramSessionList program={content} />
              </TabPanel>
            </TabContext>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </BaseContainer>
  );
};

export default MentorshipProgramDetail;
