import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, reduxForm } from 'redux-form';
import styled from 'styled-components';

import * as constants from '~/app/catalog/constants';
import { CHANNEL } from '~/app/catalog/constants';
import actions from '~/app/entities/actions';
import ChannelSelectField from '~/app/inputs/components/ChannelSelectField';
import CoverImageField from '~/app/inputs/components/CoverImageField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import FacilitatorField from '~/app/inputs/components/FacilitatorField';
import InlineItemField from '~/app/inputs/components/InlineItemField';
import InputLabel from '~/app/inputs/components/InputLabel';
import { convertTextToDraftJSDict } from '~/app/inputs/components/LazyTextEditor/services';
import TextEditorField, { TextEditorContainer } from '~/app/inputs/components/OldTextEditorField';
import RadioField from '~/app/inputs/components/RadioField';
import TagTypesInputFields from '~/app/inputs/components/TagTypesInputFields';
import TextField from '~/app/inputs/components/TextField';
import { useLinkedContentFetchMetadata } from '~/app/linkedcontent/hooks';
import { LinkedContentMetadata } from '~/app/linkedcontent/interfaces';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { useSettingsSectionsList } from '~/app/settings/hooks';
import { prepareSettingsValuesForSubmission } from '~/app/settings/services';
import { formValidations } from '~/app/shared';
import { FormFieldGroup } from '~/app/shared/components/Form';
import NewContentForm from '~/app/shared/components/NewContentForm';
import CompletionSection from '~/app/shared/components/NewContentForm/CompletionSection';
import AccessControlSection from '~/app/shared/components/NewContentForm/sections/AccessControlSection';
import Form from '~/app/shared/components/OldForm';
import { FormSection } from '~/app/shared/components/types';
import { STATUS_DONE, STATUS_LOADING } from '~/app/shared/constants';
import {
  useFormSelector,
  useLabels,
  useToggles,
  useFormSyncErrors,
  useFormPreventTransition,
  useChannelToggle,
} from '~/app/shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import { concat, get, isNil, map, pick, toLower, isEmpty, filter, trim } from 'lodash-es';
import { Box, Stack, Tooltip } from '@mui/material';
import { RefreshIcon } from '~/vendor/mui-icons';
import { LoadingButton } from '~/vendor/mui-lab';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

interface LinkedContentPayload {
  name: string;
  content_body: object;
  topics: any[];
  cover: string;
  provider: string;
  completion_policy: string;
  facilitators_ids: any[];
  is_hidden: boolean;
  hide_attendees: boolean;
  groups_ids: number[];
  tags?: any[];
  facilitators?: any[];
  settings?: any;
  duration?: string;
  channel_id?: number;
}

interface LinkedContentFormProps {
  form: any;
  topBarActionName?: string;
  isEdit?: boolean;
  backRoute?: string;
  change: (fieldName: string, value: any) => void;
  invalid?: boolean;
  error?: any;
  handleSubmit?: () => void;
  submitting?: boolean;
  isModalForm?: boolean;
  initialValues: any;
  breadcrumbsItemList?: any[];
  currentUser?: any;
}

export const LinkedContentForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  isModalForm,
  currentUser,
}: LinkedContentFormProps) => {
  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();

  useFormPreventTransition(form);

  const { label_linkedcontent: labelLinkedContent, label_channel: labelChannel } = useLabels();

  const linkedContentUrl = useFormSelector(form, 'url');
  const linkedContentUrlErrors = useFormSyncErrors(form, 'url');
  const linkedcontentName = useFormSelector(form, 'name');

  const canAddChannelContent = useChannelToggle();
  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  const {
    metadata,
    status: fetchMetadataStatus,
    fetch: fetchMetadata,
  } = useLinkedContentFetchMetadata();

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const HCLinkedContentURL = '';

  const infoPanelText =
    'This content is tied to an external link. You can manage it as a content item in PlusPlus.';

  const [settingsSectionsList] = useSettingsSectionsList('linkedcontent/form', form) as Array<
    FormSection[]
  >;

  const handleUpdateFieldsWithMetadata = useCallback(
    ({ title, site_name, description, image_url, tags, duration }: LinkedContentMetadata) => {
      change('name', title);
      change('provider', site_name);
      change('content_body', {
        type: 'refresh_content',
        content: convertTextToDraftJSDict(description),
      });
      change('cover', image_url);
      if (tags) {
        change('tags', tags);
      }
      if (duration) {
        change('duration', duration);
      }
    },
    [change]
  );

  useEffect(() => {
    if (!isEmpty(metadata) && fetchMetadataStatus === STATUS_DONE) {
      handleUpdateFieldsWithMetadata(metadata);
    }
    // TODO temporarily not adding handleUpdateFieldsWithMetadata to the depArray because the multiple changes
    // within the function are causing the browser to crash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, fetchMetadataStatus]);

  const linkedcontentSectionsList = [
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: 'inline',
      label: 'Inline Item',
      icon: 'inline',
      section: <InlineItemField />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'linkedcontent-details',
      label: `${labelLinkedContent} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Box display="flex" gap={2} alignItems="start">
              <Field
                label="Url"
                name="url"
                required
                component={TextField}
                disabled={fetchMetadataStatus === STATUS_LOADING}
                placeholder="Place your content link here"
                validate={[validateRequired, formValidations.url]}
              />
              <Tooltip title="Reload the metadata from the linked page. Doing so will automatically update the cover image, title, provider, description, duration, and tags.">
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  onClick={() => fetchMetadata({ url: linkedContentUrl })}
                  loading={fetchMetadataStatus === STATUS_LOADING}
                  loadingPosition="start"
                  disabled={isEmpty(trim(linkedContentUrl)) || !isEmpty(linkedContentUrlErrors)}
                  sx={{ mt: 1 }}
                >
                  Refresh
                </LoadingButton>
              </Tooltip>
            </Box>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              label="Title"
              name="name"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelLinkedContent)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="linkedcontent_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              label="Provider"
              name="provider"
              required
              component={TextField}
              placeholder={`Indicate what is the ${toLower(labelLinkedContent)} provider`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowConfluenceDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              inputId="duration"
              name="duration"
              label="Estimated Duration (H:MM)"
              component={DurationField}
              validate={[validateDuration]}
              disableClearable={false}
              // aditionalOptions={[{ label: '0:10', value: '0:10' }]}
            />
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_linkedcontent']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_linkedcontent']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'Owners',
      icon: 'persons',
      section: (
        <FacilitatorField
          label="Owners"
          infoText="Owners can modify settings and access restrictions for this content."
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: 'Completion',
      icon: 'clock',
      section: (
        <CompletionSection
          form={form}
          labelContentType={labelLinkedContent}
          change={change}
          optionallyCompletable
          renderOptionalSection={() => (
            <FormFieldGroup>
              <InputLabel
                htmlFor="completion_policy"
                sx={{ fontWeight: 700, fontSize: 14, fontColor: colors.neutral900 }} // this is wrong and wonky
              >
                Completion Policy
              </InputLabel>
              <Box marginLeft="10px" marginTop="15px">
                <Stack spacing={2}>
                  <Field
                    component={RadioField}
                    name="completion_policy"
                    label="Mark completion"
                    labelSize="h5"
                    optionValue="mark_completion" // ADD TO CONSTANT
                    helpText="People manually mark the content as complete"
                  />
                  <Field
                    component={RadioField}
                    name="completion_policy"
                    label="Complete when opened"
                    labelSize="h5"
                    optionValue="complete_when_opened" // ADD TO CONSTANT
                    helpText="Automatically marked as completed when people launch the content"
                  />
                </Stack>
              </Box>
            </FormFieldGroup>
          )}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={labelLinkedContent}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          showAttendeesSection
          channel={initialValues.channel}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  return (
    <NewContentForm
      contentNameSingular={labelLinkedContent}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCLinkedContentURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={linkedcontentName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={linkedcontentSectionsList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={isModalForm}
    />
  );
};

const ConnectedLinkedContentForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { isEdit, formName, currentUser, initialValues }) => {
    const actionName = actions.linkedContent[isEdit ? 'update' : 'create'].toString();
    return onSubmitActions(actionName, (values) => {
      const payload: LinkedContentPayload = {
        ...pick(values, [
          'completion_policy',
          'content_body',
          'cover',
          'duration',
          'facilitators_ids',
          'groups_ids',
          'hide_attendees',
          'is_completable',
          'is_hidden',
          'is_inline',
          'name',
          'provider',
          'time_to_complete',
          'time_to_retake',
          'topics',
          'url',
          'channel_id',
        ]),
      };

      if (!payload.channel_id) {
        delete payload.channel_id;
      }

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_linkedcontent'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const tags = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

      payload.tags = filter(concat(tags, customTopicsList), Boolean);
      payload.facilitators = map(payload.facilitators_ids, (id) => ({
        user_id: id,
        role: constants.FACILITATOR_ROLES.maintainer,
      }));

      if (!payload.duration) {
        payload.duration = '00:00:00';
      }

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      return {
        ...(initialValues.id && { id: initialValues.id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result: any, _dispatch: any, { onSubmitSuccessHandler }) =>
    onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(LinkedContentForm);

const mapStateToProps = (state: any) => {
  return {
    currentUser: get(state, 'user.currentUser'),
  };
};

// TODO https://app.asana.com/0/0/1204714157347136/f
export default connect<any, any, any>(mapStateToProps)(ConnectedLinkedContentForm);
