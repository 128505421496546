import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import styled from 'styled-components';
import colors from '~/services/colors';
import Text from '~/app/shared/components/Text';
import { useCurrentUser } from '~/app/shared/hooks';
import { map, includes, concat, without } from 'lodash-es';
import { Box, Stack } from '@mui/material';
import CheckboxInput from '~/app/inputs/components/SquareCheckboxInput';
import { titleCase } from '~/services/utils';

const ModalContent = styled.div`
  padding: 20px 40px 5px 40px;
  overflow-y: auto;
`;

const CheckboxContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const ExportWithCustomAttrModal = ({ handleSubmit, handleClose }) => {
  const currentUser = useCurrentUser();
  const { exportable_people_integration_fields: exportablePeopleIntegrationFields } = currentUser;
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  const toggleField = (field) => {
    setCheckedFields((checkedFields) => {
      if (includes(checkedFields, field)) {
        return without(checkedFields, field);
      }
      return concat(checkedFields, field);
    });
  };

  const handleOnSubmit = () => {
    const params = new URLSearchParams();
    for (const field of checkedFields) {
      params.append('required_fields', field);
    }

    const queryString = params.toString();

    handleSubmit(queryString);
    handleClose();
  };
  return (
    <Modal title="Configure CSV Export" handleClose={handleClose} width={500}>
      <ModalContent>
        <Text block size="h4" color={colors.neutral900}>
          Your CSV will contain the default columns. You may add the following custom user fields:
        </Text>

        <Stack pt={1} pb={2} gap={2}>
          <Box>
            {map(exportablePeopleIntegrationFields, (field) => {
              const isChecked = includes(checkedFields, field);
              return (
                <CheckboxContainer key={field}>
                  <CheckboxInput
                    checked={isChecked}
                    onChange={() => toggleField(field)}
                    label={titleCase(field)}
                  />
                </CheckboxContainer>
              );
            })}
          </Box>
        </Stack>
      </ModalContent>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="secondary" onClick={handleClose} size="small">
          Cancel
        </ModalFooterButton>
        <ModalFooterButton size="small" onClick={handleOnSubmit}>
          Export
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

ExportWithCustomAttrModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export { ExportWithCustomAttrModal };
