import { Box, Divider, Typography, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import colors from '~/services/colors';
import { grey } from '@mui/material/colors';

const ChartTooltipContent = styled(Box)({
  borderRadius: '4px',
  padding: '12px 8px',
  backgroundColor: grey[700],
  opacity: 0.9,
  color: colors.neutral0,
  display: 'inline-flex',
  flexDirection: 'column',
});

type ChartTooltipHeaderProps = {
  title: string;
  subheader?: string;
};

function ChartTooltipContentHeader(props: ChartTooltipHeaderProps) {
  const { title, subheader } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '5px',
      }}
    >
      {subheader && (
        <Typography
          component="span"
          sx={{
            fontSize: '0.75rem',
            lineHeight: 1.4,
            fontWeight: 500,
          }}
        >
          {subheader}
        </Typography>
      )}
      <Typography
        component="span"
        sx={{
          fontSize: '0.875rem',
          lineHeight: 1.43,
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

const ChartTooltipContentDivider = styled(Divider)({
  borderColor: '#D9D9D999',
});

type ChartTooltipContentItemRowProps = {
  label: string;
  value: string;
  valueTag?: string;
  color: string;
};

function ChartTooltipContentItemRow(props: ChartTooltipContentItemRowProps) {
  const { label, value, valueTag, color } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          width: '8px',
          height: '8px',
          borderRadius: '50%',
        }}
      />
      <Typography
        component="span"
        sx={{
          fontWeight: 300,
          fontSize: '0.875rem',
        }}
      >
        {label}
      </Typography>
      <Box>
        <Typography
          component="span"
          sx={{
            fontWeight: 700,
            fontSize: '0.875rem',
          }}
        >
          {value}
        </Typography>
        {valueTag && (
          <Typography
            component="span"
            sx={{
              fontWeight: 300,
              fontSize: '0.875rem',
            }}
          >
            {' '}
            {valueTag}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

type ChartTooltipContentBodyProps = BoxProps;

function ChartTooltipContentBody(props: ChartTooltipContentBodyProps) {
  const { children, ...restBoxProps } = props;

  return (
    <Box
      sx={{
        padding: '10px 18px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...restBoxProps.sx,
      }}
      {...restBoxProps}
    >
      <Box>{children}</Box>
    </Box>
  );
}

export {
  ChartTooltipContent,
  ChartTooltipContentHeader,
  ChartTooltipContentDivider,
  ChartTooltipContentItemRow,
  ChartTooltipContentBody,
};
