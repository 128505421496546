import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory, detailQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';
import { AssignmentStatusInsightResponse } from '../types';

export const content_items = createQueryKeys('content_items', {
  list: listQueryKeyFactory({ endpointName: 'content_items_api:list' }),
  details: detailQueryKeyFactory({ endpointName: 'content_items_api:retrieve' }),
  assignmentStatusInsights: (search: string) => {
    return {
      queryKey: [search],
      queryFn: async () => {
        let url = ApiURLs['insights_api:assignments_completion']();

        url = `${url}?${search}`;

        const { data: response } = await fetchURL(url);
        return response as AssignmentStatusInsightResponse;
      },
    };
  },
});

export type ContentItemsQueryKeys = inferQueryKeys<typeof content_items>;
