import { FetchDataProps } from '~/app/data-fetching/hooks';
import Dashboard from '~/app/backoffice/components/Dashboard';
import { queries } from '~/queries';
import { TableSelectionMode } from '~/app/shared/components/constants';
import Modal, { ModalBody } from '~/app/shared/components/Modal';
import QueryItemPicker from '~/app/shared/components/QueryItemPicker';
import {
  ActionCallback,
  ActionCallbackProps,
  FetchFilterOptions,
} from '~/app/shared/components/types';
import { isEmpty, upperFirst } from 'lodash-es';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '~/common/components/Typography';
import { Stack } from '@mui/material';
import rql from '~/vendor/rql';
import { APIResponse } from '~/app/data-fetching/utils';
import { InfiniteData } from '@tanstack/react-query';

interface QueryPeoplePickerProps {
  dialogTitle?: string;
  helpText?: string;
  query: (filters) => FetchDataProps;
  queryOptions?: Record<string, any>;
  fetchFiltersQuery?: (params) => FetchDataProps;
  fetchFiltersOptions?: FetchFilterOptions;
  initialFiltersConfig?: Record<string, any>[];
  selectFn?: (data: InfiniteData<APIResponse>) => any;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  handleClose: () => void;
  handleBack?: () => void;
  handleCancel?: () => void;
  actionButtonLabel?: string;
  actionButtonCallback: ActionCallback;
  actionButtonIsDisabled?: (props: ActionCallbackProps) => boolean;
  initialSelectedIds?: string[] | number[];
  defaultOrdering?: string;
  enableSelectAll?: boolean;
  selectionMode?: TableSelectionMode;
  cacheKey?: string;
}

const DEFAULT_PAGE_SIZE = 5;

const QueryPeoplePicker = ({
  dialogTitle = 'Select people',
  helpText,
  query,
  queryOptions,
  fetchFiltersQuery = queries.users.filters,
  fetchFiltersOptions,
  initialFiltersConfig,
  selectFn,
  pageSize,
  rowsPerPageOptions = [5, 15, 25, 50, 100, 250],
  handleClose,
  handleBack,
  handleCancel,
  actionButtonLabel = 'Confirm',
  actionButtonCallback,
  actionButtonIsDisabled = ({ selectedItems }: ActionCallbackProps) => isEmpty(selectedItems),
  initialSelectedIds = [],
  defaultOrdering = 'name',
  enableSelectAll = false,
  selectionMode = TableSelectionMode.single,
  cacheKey = '',
}: QueryPeoplePickerProps) => {
  const defaultFetchItemsOptions = {
    view_mode: ['people_picker'],
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Person',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
      display: 'flex',
      renderCell: ({ row: user }) => <Dashboard.UserCell data={user} showAvatar highlighted />,
    },
    {
      field: 'email',
      headerName: 'Email',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
    },
    {
      field: 'role.name',
      headerName: 'Role',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { role } }) => upperFirst(role),
    },
    {
      field: 'department',
      headerName: 'Department',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { location } }) => (
        <Dashboard.LocationCell data={{ location, allowsLocal: true }} />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1.5,
    },
    {
      field: 'working_since',
      headerName: 'Hire date',
      filterable: false,
      sortable: true,
      editable: false,
      flex: 1,
      renderCell: ({ row: { working_since } }) =>
        working_since && <Dashboard.DateCell data={working_since} includeYear />,
    },
  ];

  return (
    <Modal title={dialogTitle} width={1240} handleClose={handleClose} handleBack={handleBack}>
      <ModalBody>
        {helpText && (
          <Stack pb={2}>
            <Typography>{helpText}</Typography>
          </Stack>
        )}
        <QueryItemPicker
          initialFiltersConfig={initialFiltersConfig}
          query={query}
          queryOptions={rql({
            ...defaultFetchItemsOptions,
            ...queryOptions,
          })}
          filtersQuery={fetchFiltersQuery}
          fetchFiltersOptions={fetchFiltersOptions}
          selectFn={selectFn}
          defaultOrdering={defaultOrdering}
          columns={columns}
          pageSize={pageSize ?? DEFAULT_PAGE_SIZE}
          rowsPerPageOptions={rowsPerPageOptions}
          actions={[
            {
              color: 'error',
              label: 'Cancel',
              callback: handleCancel ?? handleClose,
            },
            {
              label: actionButtonLabel,
              callback: actionButtonCallback,
              isDisabled: actionButtonIsDisabled,
            },
          ]}
          filterBarInputWidth="240px"
          disabledItemsIds={initialSelectedIds}
          enableSelectAll={enableSelectAll}
          selectionMode={selectionMode}
          cacheKey={cacheKey}
        />
      </ModalBody>
    </Modal>
  );
};

export default QueryPeoplePicker;
