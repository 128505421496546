import { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import EmailTagsInput from '~/app/inputs/components/EmailTagsInput';
import InputLabel from '~/app/inputs/components/InputLabel';
import UserSelectField from '~/app/inputs/components/UserSelectField';
import colors from '~/services/colors';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Form from '~/app/shared/components/OldForm';
import {
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  join,
  keys,
  map,
  toLower,
  values,
} from 'lodash-es';
import { queries } from '~/queries';

const FindPeopleArea = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  color: ${colors.error600};
  overflow-wrap: break-word;
  margin-top: 4px;
  font-size: 14px;
  max-height: 100px;
  overflow: auto;
`;

interface AddPeopleModalProps {
  handleClose: () => void;
  handleAddPeople: (newPeople, users) => void;
  fieldValues: { emails?: any[]; people_ids?: any[] };
}

const AddPeopleModal = ({ handleClose, handleAddPeople, fieldValues }: AddPeopleModalProps) => {
  const [emailsToAdd, setEmailsToAdd] = useState<any[]>([]);

  const emails = get(fieldValues, 'emails', []);
  const people = get(fieldValues, 'people_ids', []);
  let emailsValidationMap: Record<string, boolean> = {};

  const { data, isInitialLoading, isLoading } = useQuery({
    ...queries.users.filter_by_emails(emailsToAdd),
    enabled: !isEmpty(emailsToAdd),
  });

  if (data && !isLoading) {
    const validEmails = map(data, 'email');
    const validationMap = {};
    forEach(emails, ({ email }) => {
      validationMap[email] = includes(validEmails, toLower(email));
    });
    const hasInvalidEmail = includes(values(validationMap), false);
    if (hasInvalidEmail) {
      emailsValidationMap = validationMap;
    } else {
      const people = get(fieldValues, 'people_ids', []);
      handleAddPeople(people, data);
    }
  }
  const invalidEmails = filter(keys(emailsValidationMap), (email) => !emailsValidationMap[email]);
  const numValidPeopleBeingAdded = isEmpty(emailsValidationMap)
    ? filter(emails, ({ isValid }) => isValid !== false).length
    : filter(keys(emailsValidationMap), (email) => emailsValidationMap[email]).length;

  const handleAddButton = () => {
    if (!isEmpty(emails)) {
      setEmailsToAdd(map(emails, 'email'));
    }
    if (!isEmpty(people)) {
      handleAddPeople(people, []);
    }
  };

  return (
    <Modal title="Add people" handleClose={handleClose} width={500} overflow="initial">
      <ModalBody>
        <Form.FieldGroup style={{ margin: '10' }}>
          <Field name="people_ids" label="Find people" component={UserSelectField} multiple />
        </Form.FieldGroup>
        <Form.FieldGroup style={{ margin: '15' }}>
          <FindPeopleArea>
            <InputLabel>Add people by email</InputLabel>
            <Fields
              names={['emails']}
              component={EmailTagsInput}
              emailsValidationMap={emailsValidationMap}
              placeholder="You can paste multiple emails"
            />
          </FindPeopleArea>
          {!isEmpty(invalidEmails) && (
            <ErrorMessage>
              No people found with the {invalidEmails.length > 1 ? 'emails' : 'email'}{' '}
              {join(invalidEmails, ', ')}
            </ErrorMessage>
          )}
        </Form.FieldGroup>
        {numValidPeopleBeingAdded > 0 && (
          <div>
            You are adding {numValidPeopleBeingAdded}{' '}
            {numValidPeopleBeingAdded > 1 ? 'people' : 'person'} to this group.
          </div>
        )}
      </ModalBody>
      <ModalFooter variant="buttons">
        <ModalFooterButton color="error" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        {isInitialLoading ? (
          <ModalFooterButton>
            <Loading size={10} hasMargin={false} color={colors.neutral0} />
          </ModalFooterButton>
        ) : (
          <ModalFooterButton onClick={handleAddButton} data-cy="add-people-button">
            Add People
          </ModalFooterButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

const formSelector = formValueSelector('addPeopleToGroupEdit');

const mapStateToProps = (state) => ({
  fieldValues: {
    people_ids: formSelector(state, 'people_ids'),
    emails: formSelector(state, 'emails'),
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'addPeopleToGroupEdit',
  })(AddPeopleModal)
);
