import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { CHANNEL, CONTENT_TYPES, FACILITATOR_ROLES } from '~/app/catalog/constants';
import { parseResources } from '~/app/content-resources/services';
import actions from '~/app/entities/actions';
import {
  eventTypeSelector,
  locationSelector,
  programSelector,
  userSelector,
} from '~/app/entities/selectors';
import { validateTimeslots } from '~/app/event-shared/validators';
import ChannelSelectField from '~/app/inputs/components/ChannelSelectField';
import CheckboxField from '~/app/inputs/components/CheckboxField';
import CoverImageField from '~/app/inputs/components/CoverImageField';
import EventTypeSelectField from '~/app/inputs/components/EventTypeSelectField';
import InlineItemField from '~/app/inputs/components/InlineItemField';
import InputLabel from '~/app/inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from '~/app/inputs/components/OldTextEditorField';
import TagTypesInputFields from '~/app/inputs/components/TagTypesInputFields';
import TextField from '~/app/inputs/components/TextField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import colors from '~/services/colors';
import { handleFailureWithConfirmation, submitWithConfirmation } from '~/services/forms';
import { INTERCOM_DATA_TARGETS, withProductTour } from '~/services/product-tour';
import { scrollToTop } from '~/services/utils';
import { useSettingsSectionsList } from '~/app/settings/hooks';
import { prepareSettingsValuesForSubmission } from '~/app/settings/services';
import { FormFieldGroup } from '~/app/shared/components/Form';
import HR from '~/app/shared/components/HR';
import InfoText from '~/app/shared/components/InfoText';
import NewContentForm from '~/app/shared/components/NewContentForm';
import CompletionSection from '~/app/shared/components/NewContentForm/CompletionSection';
import AccessControlSection from '~/app/shared/components/NewContentForm/sections/AccessControlSection';
import EnrollmentCapSection from '~/app/shared/components/NewContentForm/sections/EnrollmentCapSection';
import ExternalRegistrationSection from '~/app/shared/components/NewContentForm/sections/ExternalRegistrationSection';
import FacilitatorsSection from '~/app/shared/components/NewContentForm/sections/FacilitatorsSection';
import LocationSection from '~/app/shared/components/NewContentForm/sections/LocationSection';
import ResourcesSection from '~/app/shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from '~/app/shared/components/NewContentForm/sections/SurveysSection';
import OldForm from '~/app/shared/components/OldForm';
import { REGULAR_ROLE_KEY } from '~/app/shared/constants';
import {
  useCurrentUser,
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useQueryParams,
  useChannelToggle,
} from '~/app/shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import { useCalendarResourcesForEventsEffect } from '~/app/users/hooks';
import {
  concat,
  get,
  includes,
  isArray,
  isEmpty,
  isNil,
  isString,
  trim,
  join,
  map,
  pick,
  size,
  toLower,
  parseInt,
} from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

import EventTypeInfoBox from './EventTypeInfoBox';

const USER_MUST_BE_PRESENTER_ERROR_MESSAGE = 'You must be one of the presenters or organizers';

const validateRequired = OldForm.validations.required();
const maxLength = OldForm.validations.maxLength(255);

const LimitDoubleBookingWrapper = withProductTour(styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`);

const CoverContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const validate = (values, props) => {
  const { currentUser, isEventType } = props;
  const errors = {};

  const isRegular = currentUser.role === REGULAR_ROLE_KEY;
  const isPresenter = includes(get(values, 'presenters_ids', []), currentUser.id);
  const isOrganizer = values.organizer_id === currentUser.id;
  const isCoOrganizer = includes([...get(values, 'co_organizers_ids', [])], currentUser.id);

  if (isRegular && !(isCoOrganizer || isPresenter || isOrganizer)) {
    errors.presenters_ids = USER_MUST_BE_PRESENTER_ERROR_MESSAGE;
  }

  if (!values.is_local && !values.is_online && !isEventType) {
    errors.attendance_methods = 'Please select an enrollment option for your event.';
  }

  errors.timeslots = validateTimeslots(values.timeslots);

  return errors;
};

const EventForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  isEventType,
  breadcrumbsItemList,
  selectedEventType,
  selectedOfficeHour,
  isModalForm,
  settingsContext,
}) => {
  useCalendarResourcesForEventsEffect();
  useFormPreventTransition(form);

  const currentUser = useCurrentUser();
  const {
    label_event_type: labelEventType,
    label_event_type_plural: labelEventTypePlural,
    label_channel: labelChannel,
  } = useLabels();

  const { from_previous: fromPrevious } = useQueryParams();

  const [settingsSectionsList] = useSettingsSectionsList(settingsContext, form);

  const fieldValues = {
    timeslots: useFormSelector(form, 'timeslots'),
    is_local: useFormSelector(form, 'is_local'),
    is_online: useFormSelector(form, 'is_online'),
    is_hidden: useFormSelector(form, 'is_hidden'),
    location_id: useFormSelector(form, 'location_id'),
    organizer_id: useFormSelector(form, 'organizer_id'),
    co_organizers_ids: useFormSelector(form, 'co_organizers_ids'),
    name: useFormSelector(form, 'name'),
    groups_ids: useFormSelector(form, 'groups_ids'),
    convert_event: useFormSelector(form, 'convert_event'),
    event_type_id: useFormSelector(form, 'event_type_id'),
    has_external_registration: useFormSelector(form, 'has_external_registration'),
    // Enroll caps
    enrollment_limit: useFormSelector(form, 'enrollment_limit'),
    wait_list_limit: useFormSelector(form, 'wait_list_limit'),
    online_enrollment_limit: useFormSelector(form, 'online_enrollment_limit'),
    online_wait_list_limit: useFormSelector(form, 'online_wait_list_limit'),
    has_wait_list: useFormSelector(form, 'has_wait_list'),
    // Enroll Counts
    going_local_enrollments_count: useFormSelector(form, 'going_local_enrollments_count'),
    waitlist_local_enrollments_count: useFormSelector(form, 'waitlist_local_enrollments_count'),
    going_online_enrollments_count: useFormSelector(form, 'going_online_enrollments_count'),
    waitlist_going_online_enrollments_count: useFormSelector(
      form,
      'waitlist_going_online_enrollments_count'
    ),
  };

  const HCArticleURL =
    'https://help.plusplus.app/en/articles/6070095-plusplus-101-getting-started-with-live-events';

  const saveAndDuplicate = useFormSelector(form, 'save_and_duplicate');
  const saveAndBulkDuplicate = useFormSelector(form, 'save_and_bulk_duplicate');
  const saveAndApply = useFormSelector(form, 'save_and_apply');

  const canAddChannelContent = useChannelToggle();
  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField disabled={!isEventType} />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  useEffect(() => {
    if (selectedEventType && selectedEventType.channel_id) {
      change('channel_id', selectedEventType.channel_id);
    }
  }, [selectedEventType]);

  const onSaveSecondActionHandler = (field) => {
    if (invalid) {
      handleSubmit();
    } else {
      change(field, true);
    }
  };

  useEffect(() => {
    if (saveAndDuplicate || saveAndBulkDuplicate || saveAndApply) {
      handleSubmit();
    }
  }, [saveAndDuplicate, saveAndBulkDuplicate, saveAndApply]);

  const eventSectionsList = [
    // Only add this section in the event form
    ...(!isEventType
      ? [
          {
            id: 'selected-event-type',
            label: labelEventType,
            section: (
              <FormFieldGroup>
                <Field name="event_type_id" component={EventTypeSelectField} />
                {selectedEventType ? (
                  <EventTypeInfoBox eventType={selectedEventType} actionText="View details" />
                ) : (
                  <InfoText
                    top={4}
                    content={`This event is an one-off (not associated with an ${labelEventType})`}
                  />
                )}

                <Field component="input" type="hidden" name="save_and_duplicate" />
                <Field component="input" type="hidden" name="save_and_bulk_duplicate" />
                <Field component="input" type="hidden" name="save_and_apply" />
              </FormFieldGroup>
            ),
            hideNav: true,
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
    ...(isEventType
      ? [
          {
            id: 'inline',
            label: 'Inline Item',
            icon: 'inline',
            section: <InlineItemField />,
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: isEventType ? 'event-type-details' : 'event-details',
      label: isEventType ? `${labelEventType} Details` : 'Event Details',
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              label="Title"
              required
              name="name"
              component={TextField}
              placeholder={`Give your ${isEventType ? labelEventType : 'event'} a title`}
              validate={[validateRequired, maxLength]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="event_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel required={!isEventType} htmlFor="content_body">
              Description
            </InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                validate={isEventType ? [] : [validateRequired]}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_events']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_events']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'facilitators',
      label: 'Facilitators',
      icon: 'persons',
      section: (
        <FacilitatorsSection
          infoBoxContent={
            isEventType
              ? `People added here can edit/delete the ${toLower(
                  labelEventType
                )}, change all settings and configurations. They will be the default organizer, co-organizers, and presenters when creating new events from this ${toLower(
                  labelEventType
                )}.`
              : 'People added here can edit/delete the event, change all settings and configurations.'
          }
        />
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
      },
    },
    {
      id: 'enrollment-capacity',
      label: 'Enrollment Capacity',
      icon: 'ticket',
      section: (
        <>
          <EnrollmentCapSection
            isEditing={isEdit}
            fieldValues={fieldValues}
            initialValues={initialValues}
            isEventType={isEventType}
            change={change}
            onlineWrapperTourId={INTERCOM_DATA_TARGETS.eventFormOnlineAccordion}
            onlineCheckboxTourId={INTERCOM_DATA_TARGETS.eventFormOnlineCheckbox}
          />
          {
            // Please move this to the Attendance Policies section when it's ready
            isEventType && (
              <LimitDoubleBookingWrapper
                show
                tourId={INTERCOM_DATA_TARGETS.limitDoubleBookingWrapper}
              >
                <HR color={colors.neutral200} margin="20px -20px" />
                <Field
                  name="limit_double_booking"
                  tourId={parseInt(INTERCOM_DATA_TARGETS.limitDoubleBookingCheckbox)}
                  label={`Prevent enrollment in multiple upcoming events from this ${labelEventType}`}
                  infoText="In case of additional enrollment, the external unenrollment dialog will not show up."
                  labelSize="h5"
                  labelWeight="medium"
                  component={CheckboxField}
                />
              </LimitDoubleBookingWrapper>
            )
          }
        </>
      ),
      sectionProps: {
        defaultOpen: !fromPrevious,
        tourId: INTERCOM_DATA_TARGETS.eventFormEnrollmentAndCapacity,
      },
    },
    {
      id: 'location-time',
      label: 'Location & Time',
      icon: 'calendar',
      section: (
        <LocationSection
          isCompactMode={isEventType}
          locationName="location_id"
          timeslotsName="timeslots"
          locationId={fieldValues.location_id}
          isOnline={fieldValues.is_online}
          initialWatchLink={initialValues.id ? '' : initialValues.watch_link}
          initialDuration={initialValues.id ? '' : initialValues.duration}
          isEditing={Boolean(initialValues.id)}
          formName={form}
          change={change}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(isEventType
      ? [
          {
            id: 'completion',
            label: `Completion`,
            icon: 'clock',
            section: (
              <CompletionSection form={form} labelContentType={labelEventType} change={change} />
            ),
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
    {
      id: 'resources',
      label: `Resources`,
      icon: 'teach',
      section: <ResourcesSection formName={form} selectedOfficeHour={selectedOfficeHour} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const hasExternalRegistration = fieldValues.has_external_registration;

  const contentType = isEventType ? CONTENT_TYPES.eventtype : CONTENT_TYPES.event;
  const advancedSettingsList = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={contentType}
          formName={form}
          assignmentsCount={initialValues.going_enrollments_count}
          isEditing={Boolean(initialValues.id)}
        />
      ),
      sectionProps: {
        defaultOpen:
          !fromPrevious &&
          (!isEmpty(initialValues.survey_relationships) ||
            !isEmpty(initialValues.external_survey_link)),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showInfobox={isEventType}
          // This is used in the banner
          contentNameSingular="event"
          // This is used in the access control preference info text
          accessLevelContentName={
            isEventType ? `${toLower(labelEventType)} and its events` : 'event'
          }
          isAccessLevelContentNamePlural={isEventType}
          hideEnrolleesLabel="Attendee List is Private"
          hideEnrolleesInfoText={`Only admins, maintainers, and attendees see who is attending events from this ${toLower(
            labelEventType
          )}.`}
          showAttendeesSection={false}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          channel={initialValues.channel}
        />
      ),
      sectionProps: {
        defaultOpen:
          !fromPrevious && (initialValues.is_hidden || !isEmpty(initialValues.groups_ids)),
      },
    },
    {
      id: 'external-registration',
      label: 'External Registration',
      icon: 'link',
      section: (
        <ExternalRegistrationSection
          showInfobox={isEventType}
          initialShowExternalRegistration={hasExternalRegistration}
          contentNameSingular="event"
          externalRegistrationHelpText="If enrolling into events of this type requires an additional registration step on another system, use this space to guide your attendees there."
        />
      ),
      sectionProps: {
        defaultOpen: !fromPrevious && hasExternalRegistration,
      },
    },
    ...settingsSectionsList,
  ];

  const infoPanelText = isEventType
    ? `${labelEventTypePlural} serve both as templates for scheduling events and as collections of all past and upcoming events derived from them.`
    : `Events are live gatherings for groups of people around specific topics. They can contain multiple timeslots, organizers, presenters, attendeees, and be grouped by their ${toLower(
        labelEventTypePlural
      )}.`;

  return (
    <NewContentForm
      contentNameSingular={isEventType ? labelEventType : 'event'}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCArticleURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={fieldValues.name}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={eventSectionsList}
      advancedSettingsList={advancedSettingsList}
      onSaveAndDuplicateHandler={
        isEventType ? undefined : () => onSaveSecondActionHandler('save_and_duplicate')
      }
      onSaveAndBulkDuplicateHandler={
        isEventType || size(fieldValues.timeslots) !== 1
          ? undefined
          : () => onSaveSecondActionHandler('save_and_bulk_duplicate')
      }
      onSaveAndApplyHandler={
        isEventType ? () => onSaveSecondActionHandler('save_and_apply') : undefined
      }
      isModalForm={isModalForm}
    />
  );
};

EventForm.defaultProps = {
  initialValues: {},
  isEventType: false,
};

EventForm.propTypes = {
  form: PropTypes.string,
  settingsContext: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isEventType: PropTypes.bool,
  isModalForm: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  selectedEventType: PropTypes.object,
  selectedOfficeHour: PropTypes.object,
};

const ConnectedEventForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate,
  onSubmit: (
    values,
    dispatch,
    {
      isEdit,
      formName,
      currentUser,
      initialValues = {},
      selectedPresenters,
      selectedOrganizer,
      selectedCoOrganizers,
      selectedLocation,
      isEventType,
      submitWithConfirmationHandler,
    }
  ) => {
    return submitWithConfirmation(
      ({ proceedToSubmit, cancelSubmit }) => {
        let extraParams = {};
        // These extra params for the confirmation modal are only required for the event form
        if (!isEventType) {
          let confirmationModalValues = { ...values };
          // If we don't have an enrollment waitlist, send the waitlist limits as 0.
          // This only impact on the confirmation modal, we also have to take these
          // 2 values off the payload when submitting the request.
          // TODO: Maybe we could later refactor the ReviewModal component to account
          // for events without waitlist when the old event form is gone.
          if (!values.has_wait_list) {
            confirmationModalValues = {
              ...confirmationModalValues,
              wait_list_limit: 0,
              online_wait_list_limit: 0,
            };
          }
          extraParams = {
            values: confirmationModalValues,
            selectedPresenters,
            selectedOrganizer,
            selectedCoOrganizers,
            selectedLocation,
          };
        }
        submitWithConfirmationHandler({ proceedToSubmit, cancelSubmit })({
          initialValues,
          ...extraParams,
        });
      },
      (confirmData) => {
        const actionName =
          actions[isEventType ? 'eventType' : 'event'][isEdit ? 'update' : 'create'].toString();
        return onSubmitActions(actionName, (values) => {
          let fieldsToPick = [
            'name',
            'content_body',
            'cover',
            'is_online',
            'location_id',
            'external_link_description',
            'external_link',
            'enrollment_limit',
            'wait_list_limit',
            'online_enrollment_limit',
            'online_wait_list_limit',
            'is_local',
            'groups_ids',
            'hide_enrollees',
            'external_survey_link',
            'survey_relationships',
            'office_hour_id',
            'resources',
            'is_hidden',
            'channel_id',
          ];

          if (!isEventType) {
            fieldsToPick = [...fieldsToPick, 'event_type_id', 'timeslots', 'convert_event'];
          } else {
            fieldsToPick = [
              ...fieldsToPick,
              'duration',
              'is_completable',
              'is_hidden',
              'is_inline',
              'limit_double_booking',
              'time_to_complete',
              'time_to_retake',
              'watch_link_method',
              'watch_link',
            ];
          }
          let payload = pick(values, fieldsToPick);

          if (!payload.channel_id) {
            delete payload.channel_id;
          }

          // Handle empty values for enrollment caps
          if (payload.is_local && trim(payload.enrollment_limit) === '') {
            payload.enrollment_limit = 0;
          }
          if (payload.is_local && values.has_wait_list && trim(payload.wait_list_limit) === '') {
            payload.wait_list_limit = 0;
          }
          if (payload.is_online && trim(payload.online_enrollment_limit) === '') {
            payload.online_enrollment_limit = 0;
          }
          if (
            payload.is_online &&
            values.has_wait_list &&
            trim(payload.online_wait_list_limit) === ''
          ) {
            payload.online_wait_list_limit = 0;
          }

          // Reset enrollment cap values if toggle is off
          if (!payload.is_local) {
            payload.enrollment_limit = 0;
            payload.wait_list_limit = 0;
          }
          if (!payload.is_online) {
            payload.online_enrollment_limit = 0;
            payload.online_wait_list_limit = 0;
          }

          // If we don't have the waitlist enabled, reset the values
          if (!values.has_wait_list) {
            payload.wait_list_limit = 0;
            payload.online_wait_list_limit = 0;
          }

          // Reset the external registration values if the checkbox is off
          if (!values.has_external_registration) {
            payload.external_link_description = '';
            payload.external_link = '';
          }

          // If watch link method is empty, default it to custom
          if (payload.watch_link_method === '') {
            payload.watch_link_method = 'custom';
          }

          // Timeslots
          payload.timeslots = map(payload.timeslots, (t) => {
            const timeslot = pick(t, [
              ...(!isString(t.id) ? ['id'] : []),
              'starts_at',
              'duration',
              'rooms_info',
              'extra_info',
              'watch_link_method',
              'watch_link',
              ...(!isNil(t.rooms) ? ['rooms'] : []),
            ]);

            if (isArray(timeslot.rooms_info)) {
              timeslot.rooms_info = join(timeslot.rooms_info, ', ');
            }

            if (!isString(t.id)) timeslot.id = t.id;

            // If watch link method is empty, default it to custom
            if (timeslot.watch_link_method === '') {
              timeslot.watch_link_method = 'custom';
            }

            return timeslot;
          });

          // In Person timeslots
          if (!payload.is_online) {
            payload.timeslots = map(payload.timeslots, (t) => ({ ...t, watch_link: '' }));
          }

          // Custom tags
          const customTopicsList = buildCustomTopicsList({
            user: currentUser,
            toggleTypes: ['toggle_events'],
            flexibleTags: pick(
              values,
              map(
                currentUser?.custom_tags.flexible_filter_tags,
                (flexibleTag) => flexibleTag.filter_field_name
              )
            ),
            tags: values.tags,
          });

          const topics = get(values, 'topics') && !isNil(values.topics[0]) ? values.topics : [];

          payload.tags = concat(topics, customTopicsList);

          let organizerId = get(values, 'organizer_id');
          let coOrganizersIds = get(values, 'co_organizers_ids', []);
          const presentersIds = get(values, 'presenters_ids', []);

          // If organizer is not set we'll get the first co-organizer or the first presenter
          if (!organizerId) {
            [organizerId, ...coOrganizersIds] = coOrganizersIds;
            const presenterId = presentersIds[0];
            organizerId = organizerId || presenterId;
          }

          // Send a facilitators array in the payload
          let facilitators = [];

          if (organizerId) {
            facilitators = concat(facilitators, {
              user_id: organizerId,
              role: FACILITATOR_ROLES.main_organizer,
            });
          }

          if (coOrganizersIds) {
            facilitators = concat(
              facilitators,
              map(coOrganizersIds, (coOrganizerId) => ({
                user_id: coOrganizerId,
                role: FACILITATOR_ROLES.co_organizer,
              }))
            );
          }

          if (presentersIds) {
            facilitators = concat(
              facilitators,
              map(presentersIds, (presenterId) => ({
                user_id: presenterId,
                role: FACILITATOR_ROLES.presenter,
              }))
            );
          }

          payload.facilitators = facilitators;

          // Skip notification logic
          payload = {
            ...payload,
            ...confirmData,
          };

          // Surveys
          let { survey_relationships: surveyRelationships } = payload;
          const { external_survey_link: surveyLink } = payload;

          surveyRelationships = map(surveyRelationships, (sr) => {
            const surveyRelationship = pick(sr, [
              'survey_id',
              'survey_cutoff_period_value',
              'survey_cutoff_period_option',
              'survey_schedule_period_value',
              'survey_schedule_period_option',
              'survey_schedule_kind',
            ]);

            if (isEdit) surveyRelationship.id = sr.id;

            return surveyRelationship;
          });

          payload = {
            ...payload,
            external_survey_link: surveyLink,
            survey_relationships: surveyRelationships,
          };

          // Resources Links
          payload.resources = parseResources(payload.resources, isEdit);

          if (isEventType && !payload.duration) {
            payload.duration = '00:00:00';
          }

          // Settings
          payload.settings = prepareSettingsValuesForSubmission(values?.settings);

          const idKey = 'public_id';
          return {
            ...(initialValues[idKey] && { id: initialValues[idKey] }),
            key: formName,
            body: payload,
          };
        })(values, dispatch);
      }
    );
  },
  onSubmitSuccess: (
    result,
    dispatch,
    {
      onSubmitSuccessHandler,
      setEventToBulkDuplicate,
      saveAndDuplicate,
      saveAndBulkDuplicate,
      saveAndApply,
      isEventType,
    }
  ) => {
    if (setEventToBulkDuplicate && saveAndBulkDuplicate) {
      setEventToBulkDuplicate(result);
    }
    if (isEventType) {
      onSubmitSuccessHandler(result, saveAndApply);
    } else {
      onSubmitSuccessHandler(result, saveAndDuplicate, saveAndBulkDuplicate);
    }
  },
  onSubmitFail: handleFailureWithConfirmation(() => {
    toast.error('Submission failed, fix errors and try again.');
    scrollToTop();
  }),
})(EventForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);

  const presentersIds = formSelector(state, 'presenters_ids');
  const coOrganizersIds = formSelector(state, 'co_organizers_ids');
  const locationId = formSelector(state, 'location_id');
  const organizerId = formSelector(state, 'organizer_id');

  const selectedPresenters = map(presentersIds, (presenterId) => userSelector(state, presenterId));
  const selectedCoOrganizers = map(coOrganizersIds, (coOrganizerId) =>
    userSelector(state, coOrganizerId)
  );
  const selectedOrganizer = userSelector(state, organizerId, []);
  const selectedLocation = locationSelector(state, locationId);

  const eventTypeId = formSelector(state, 'event_type_id');
  const selectedEventType = eventTypeSelector(state, eventTypeId);

  const saveAndDuplicate = formSelector(state, 'save_and_duplicate');
  const saveAndBulkDuplicate = formSelector(state, 'save_and_bulk_duplicate');
  const saveAndApply = formSelector(state, 'save_and_apply');

  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);

  return {
    selectedPresenters,
    selectedOrganizer,
    selectedCoOrganizers,
    selectedLocation,
    selectedEventType,
    saveAndDuplicate,
    saveAndBulkDuplicate,
    selectedOfficeHour,
    saveAndApply,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedEventForm);
