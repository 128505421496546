import moment from 'moment-timezone';
import React from 'react';

import { FACILITATOR_ROLES } from '~/app/catalog/constants';
import {
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
  EVENT_STATUS_FULL,
  EVENT_STATUS_SEATS_AVAILABLE,
  EVENT_STATUS_WAIT_LIST_AVAILABLE,
} from '~/app/event-shared/constants';
import { EVENT_PERMISSIONS } from '~/app/event/constants';
import { normalizeInterval } from '~/services/datetime';
import {
  maxBy,
  minBy,
  get,
  includes,
  isEmpty,
  isNil,
  last,
  find,
  filter,
  map,
  pick,
} from 'lodash-es';

// ----- GENERAL SERVICES ----- //

// ----- GENERAL SERVICES ----- //

export function shouldDisplayFeedbackComponent(event, toggleDisplayFeedbackIfSurveyLink) {
  const surveyLink = event.external_survey_link || event?.event_type?.external_survey_link;
  const hasSurvey = !isEmpty(surveyLink);
  return !hasSurvey || (hasSurvey && toggleDisplayFeedbackIfSurveyLink);
}

// ----- TIMESLOT RELATED SERVICES ----- //

export function getEarliestTimeslot(event) {
  return minBy(event.timeslots, 'starts_at_tz_aware');
}

export function getLatestTimeslot(event) {
  return maxBy(event.timeslots, 'starts_at_tz_aware');
}

// ----- GENERAL EVENT RELATED SERVICES ----- //

export function getEventStatus(event) {
  const eventStatus = event.status;
  const onlineStatus = event.online_status;
  const eventIsLocal = event.is_local;
  // CA 2.0
  const eventIsOnline = get(event, 'allows_online_enrollment', get(event, 'is_online', false));
  const eventIsCancelled = event.is_archived;

  const eventHasLocalEnrollAvailable = eventStatus === EVENT_STATUS_SEATS_AVAILABLE;
  const eventHasLocalWaitlistAvailable = eventStatus === EVENT_STATUS_WAIT_LIST_AVAILABLE;
  const eventHasOnlineEnrollAvailable = onlineStatus === EVENT_STATUS_SEATS_AVAILABLE;
  const eventHasOnlineWaitlistAvailable = onlineStatus === EVENT_STATUS_WAIT_LIST_AVAILABLE;
  const eventIsFullLocal = eventStatus === EVENT_STATUS_FULL;
  const eventIsFullOnline = onlineStatus === EVENT_STATUS_FULL;
  const eventIsFull = eventIsFullLocal && eventIsFullOnline;
  const eventAllowsCancellation = allowCancellation(event);

  return {
    eventHasLocalEnrollAvailable,
    eventHasLocalWaitlistAvailable,
    eventHasOnlineEnrollAvailable,
    eventHasOnlineWaitlistAvailable,
    eventIsFullLocal,
    eventIsFullOnline,
    eventIsFull,
    eventIsLocal,
    eventIsOnline,
    eventAllowsCancellation,
    eventIsCancelled,
  };
}

export function getSpotsStatus(event) {
  const { enrollment } = event;

  const waitlist_local_enrollments_count = get(
    event,
    'waitlist_local_enrollments_count',
    get(event, 'enrollment_counts_dict.wait_list')
  );
  const localWaitlistCurrentSpot = get(enrollment, 'waitlist_position');
  const localWaitlistPretendedSpot = waitlist_local_enrollments_count + 1;
  const localWaitlistSpotsLeft = event.wait_list_limit - waitlist_local_enrollments_count;
  const localSpotsLeft = get(
    event,
    'remaining_spots_count',
    get(event, `remaining_capacity.going`, 0)
  );
  const hasUnlimitedLocalSpots = event.enrollment_limit === 0;
  const waitlist_going_online_enrollments_count = get(
    event,
    'waitlist_going_online_enrollments_count',
    get(event, 'enrollment_counts_dict.wait_list_online')
  );
  const onlineWaitlistCurrentSpot = get(enrollment, 'waitlist_online_position');
  const onlineWaitlistPretendedSpot = waitlist_going_online_enrollments_count + 1;
  const onlineWaitlistSpotsLeft =
    event.online_wait_list_limit - waitlist_going_online_enrollments_count;
  const onlineSpotsLeft = get(
    event,
    'remaining_online_spots_count',
    get(event, `remaining_capacity.going_online`, 0)
  );
  const hasUnlimitedOnlineSpots = event.online_enrollment_limit === 0;

  return {
    localWaitlistCurrentSpot,
    localWaitlistPretendedSpot,
    localWaitlistSpotsLeft,
    localSpotsLeft,
    hasUnlimitedLocalSpots,

    onlineWaitlistCurrentSpot,
    onlineWaitlistPretendedSpot,
    onlineWaitlistSpotsLeft,
    onlineSpotsLeft,
    hasUnlimitedOnlineSpots,
  };
}

export function getEventDate(event) {
  return moment.tz(get(event, 'utc_datetime', get(event, 'local_time')), event.timezone);
}

export function isNearOrStarted(event) {
  const date = getEventDate(event);
  return moment().isAfter(date.clone().subtract(1, 'hour'));
}

// returns the start and end datetimes for a given timeslot
export function getEventInterval(event, displayTimezone) {
  if (isEmpty(event.timeslots)) {
    return null;
  }
  const earliest = getEarliestTimeslot(event);
  const latest = getLatestTimeslot(event);

  const {
    start: nomalizedStart,
    end: normalizedEnd,
    timezone: normalizedTimezone,
  } = normalizeInterval(
    earliest.starts_at_tz_aware,
    latest.ends_at_tz_aware,
    event.timezone,
    displayTimezone
  );

  return {
    startsAt: nomalizedStart,
    endsAt: normalizedEnd,
    timezone: normalizedTimezone,
  };
}

// ----- ENROLLMENT RELATED SERVICES ----- //

export function allowSelfCheckin(event) {
  const toggleSelfCheckin = get(event, 'can_self_checkin', false);
  if (!toggleSelfCheckin) return false;

  const date = getEventDate(event);
  const { cutoffs } = event;
  const { cutoff_self_checkin_opens: cutoffOpens, cutoff_self_checkin_closes: cutoffCloses } =
    cutoffs;
  const opens = isNil(cutoffOpens) ? null : date.clone().add(cutoffOpens, 'seconds');
  const closes = isNil(cutoffCloses) ? null : date.clone().add(cutoffCloses, 'seconds');
  if (!isNil(cutoffOpens) && !isNil(cutoffCloses)) return moment().isBetween(opens, closes);
  if (!isNil(cutoffOpens)) return moment().isSameOrAfter(opens);
  if (!isNil(cutoffCloses)) return moment().isSameOrBefore(closes);
  return true;
}

export function getEnrollmentOpensCutoff(event) {
  const { cutoffs } = event;
  if (isNil(cutoffs)) return null;
  if (isNil(cutoffs.cutoff_enrollment_opens)) return null;
  return moment.tz(cutoffs.cutoff_enrollment_opens, event.timezone);
}

export function getEnrollmentClosesCutoff(event) {
  if (isNil(event)) return null;
  if (isNil(event.cutoffs)) return null;
  if (isNil(event.cutoffs.cutoff_enrollment_closes)) return null;
  return moment.tz(event.cutoffs.cutoff_enrollment_closes, event.timezone);
}

export function getCancellationDate(event) {
  if (isNil(event)) return null;
  if (isNil(event.cutoffs)) return null;
  if (isNil(event.cutoffs.cutoff_cancellation_closes)) return null;
  return getEventDate(event).clone().add(event.cutoffs.cutoff_cancellation_closes, 'seconds');
}

export function allowEnrollment(event) {
  const opens = getEnrollmentOpensCutoff(event);
  const closes = getEnrollmentClosesCutoff(event);
  const afterOpens = isNil(opens) ? true : moment().isSameOrAfter(opens);
  const beforeCloses = isNil(closes) ? true : moment().isSameOrBefore(closes);
  return afterOpens && beforeCloses;
}

export function getAllowEnrollment(event) {
  const opens = getEnrollmentOpensCutoff(event);
  const closes = getEnrollmentClosesCutoff(event);
  const afterOpens = isNil(opens) ? true : moment().isSameOrAfter(opens);
  const beforeCloses = isNil(closes) ? true : moment().isSameOrBefore(closes);
  return { after: afterOpens, before: beforeCloses };
}

export function allowCancellation(event) {
  const closes = getCancellationDate(event);
  if (isNil(closes)) return true;
  return moment().isBefore(closes);
}

export function getUserEnrollmentStatus(enrollmentStatus) {
  const userIsOnLocalWaitlist = enrollmentStatus === ENROLLMENT_STATUS_WAIT_LIST;
  const userIsOnOnlineWaitlist = enrollmentStatus === ENROLLMENT_STATUS_WAIT_LIST_ONLINE;
  const userIsEnrolledLocal = enrollmentStatus === ENROLLMENT_STATUS_GOING;
  const userIsEnrolledOnline = enrollmentStatus === ENROLLMENT_STATUS_GOING_ONLINE;
  const userHasAttended = enrollmentStatus === ENROLLMENT_STATUS_ATTENDED;

  const userIsOnWaitlist = userIsOnLocalWaitlist || userIsOnOnlineWaitlist;
  const userIsEnrolled = userIsEnrolledLocal || userIsEnrolledOnline;

  return {
    userIsOnLocalWaitlist,
    userIsOnOnlineWaitlist,
    userIsOnWaitlist,
    userIsEnrolledLocal,
    userIsEnrolledOnline,
    userIsEnrolled,
    userHasAttended,
  };
}

export const getEnrollmentConditions = ({
  eventHasLocalEnrollAvailable,
  eventIsLocal,
  eventHasLocalWaitlistAvailable,
  eventIsOnline,
  eventHasOnlineEnrollAvailable,
  eventHasOnlineWaitlistAvailable,

  userIsOnLocalWaitlist,
  userIsEnrolledLocal,
  userIsOnOnlineWaitlist,
  userIsEnrolledOnline,
  permissions = [],
}) => {
  const canShowLocal = !userIsOnLocalWaitlist && !userIsEnrolledLocal;
  const canShowOnline = !userIsOnOnlineWaitlist && !userIsEnrolledOnline;

  const showLocalEnroll = canShowLocal && eventIsLocal && eventHasLocalEnrollAvailable;
  const showLocalWaitlist =
    canShowLocal && eventIsLocal && !eventHasLocalEnrollAvailable && eventHasLocalWaitlistAvailable;

  const showOnlineEnroll = canShowOnline && eventIsOnline && eventHasOnlineEnrollAvailable;
  const showOnlineWaitlist =
    canShowOnline &&
    eventIsOnline &&
    !eventHasOnlineEnrollAvailable &&
    eventHasOnlineWaitlistAvailable;

  return {
    showLocalEnroll: showLocalEnroll && includes(permissions, EVENT_PERMISSIONS.enroll_locally),
    showLocalWaitlist:
      showLocalWaitlist && includes(permissions, EVENT_PERMISSIONS.waitlist_locally),
    showOnlineEnroll: showOnlineEnroll && includes(permissions, EVENT_PERMISSIONS.enroll_online),
    showOnlineWaitlist:
      showOnlineWaitlist && includes(permissions, EVENT_PERMISSIONS.waitlist_online),
  };
};

export function isUserPresenterOrOrganizer(userId, event) {
  const presentersIds = get(
    event,
    'presenters_ids',
    // CA 2.0
    map(
      filter(get(event, 'facilitators'), ({ role }) => role === FACILITATOR_ROLES.presenter),
      'user.id'
    )
  );
  const coOrganizersIds = get(
    event,
    'co_organizers_ids',
    // CA 2.0
    map(
      filter(get(event, 'facilitators'), ({ role }) => role === FACILITATOR_ROLES.co_organizer),
      'user.id'
    )
  );
  const organizerId = get(
    event,
    'organizer_id',
    // CA 2.0
    get(
      find(get(event, 'facilitators'), ({ role }) => role === FACILITATOR_ROLES.main_organizer),
      'user.id'
    )
  );

  return userId === organizerId || includes([...presentersIds, ...coOrganizersIds], userId);
}

// This function is used only on /backoffice/enrollments, as it uses different serializers,
// therefore has different fields. You shouldn't call other services from this module in it,
// as they rely on fields from the regular Event/Enrollment serializers.
export function getEnrollmentStatusForEnrollmentsAdmin(enrollment) {
  if (!enrollment || !enrollment.event) return null;

  const today = moment();
  const enrollmentStatus = enrollment.status;
  const eventStartDate = moment(get(enrollment, 'event.timeslots[0].starts_at'));
  const eventHasPassed = today.isAfter(eventStartDate);
  const checkedIn = enrollmentStatus === 'attended';
  const selfCheckin = enrollment.user.id === get(enrollment, 'checkin_creator.id');

  let status = 'Unknown';

  if (enrollmentStatus === 'event_cancelled') {
    status = 'Event Cancelled';
  } else if (includes(['not_going', 'unanswered'], enrollmentStatus)) {
    status = 'Dropped';
  } else if (enrollmentStatus === 'wait_list') {
    status = 'Waitlisted';
  } else if (enrollmentStatus === 'wait_list_online') {
    status = 'Waitlisted Online';
  } else if (!eventHasPassed) {
    if (enrollmentStatus === 'going') status = 'Enrolled';
  } else if (eventHasPassed && checkedIn && !selfCheckin) {
    status = 'Attended Confirmed';
  } else if (eventHasPassed && checkedIn) {
    status = 'Attended';
  } else if (eventHasPassed && !checkedIn) {
    status = 'No-show';
  }

  return status;
}

export const componentDecorator = (href, text, key) => (
  <a href={href} key={key} rel="noopener noreferrer" target="_blank">
    {text}
  </a>
);

export const hasPassed = (event) => {
  if (isNil(event?.timeslots)) return true;
  const { ends_at_tz_aware: eventEndsAt } = last(event?.timeslots);
  return moment().isAfter(moment(eventEndsAt));
};

export const getFacilitatorsIds = (eventType) => {
  const organizerId = get(find(eventType.facilitators, ['role', 'main_organizer']), 'user.id');
  const presentersIds = map(
    filter(eventType.facilitators, ['role', 'presenter']),
    ({ user }) => user.id
  );
  const coOrganizersIds = map(
    filter(eventType.facilitators, ['role', 'co_organizer']),
    ({ user }) => user.id
  );

  return { organizerId, presentersIds, coOrganizersIds };
};

export const getEventTypeEditableField = (eventType) => {
  const editableFields = pick(eventType, [
    // details
    'cover',
    'name',
    'content_body',
    'tags',
    // facilitators
    'organizer_id',
    'co_organizers_ids',
    'presenters_ids',
    // Capacity
    'enrollment_limit',
    'online_enrollment_limit',
    'wait_list_limit',
    'online_wait_list_limit',
    // Location
    'is_local',
    'is_online',
    'location_id',
    'duration',
    'watch_link',
    // Resources
    'office_hour_id',
    // Surveys
    'external_survey_link',
    // Access control
    'is_hidden',
    'groups_ids',
    'hide_enrollees',
    // External registration
    'external_link',
    'external_link_description',
  ]);
  return editableFields;
};
