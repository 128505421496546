import RQLFilterBar from '~/app/filters/components/RQLFilterBar/RQLFilterBar';
import { useNewRqlFilters } from '~/app/filters/hooks';
import { PillsDefinitionProps } from '~/app/filters/types';
import { cleanRQLExpression } from '~/app/filters/utils';
import ViewAllContent from '~/app/catalog/components/ViewAllContent';
import { useFetchDataListPaginated } from '~/app/data-fetching/hooks';
import { translatorFactory } from '~/app/feature-parity/utils';
import { usePeopleDirectoryFiltersDefinition } from '~/features/users/hooks/usePeopleDirectoryFiltersDefinition';
import { usePeopleDirectoryPillsDefinition } from '~/features/users/hooks/usePeopleDirectoryPillsDefinition';
import FilterPills from '~/app/navigation/components/FilterPills';
import { queries } from '~/queries';
import CardList from '~/app/shared-cards/components/CardList';
import { PersonCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import { useOrderingLabel } from '~/app/shared/hooks';
import { get, isEmpty, omit } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Typography } from '~/common/components/Typography';
import { Box } from '@mui/material';
import rql from '~/vendor/rql';

import { Channel } from '../../types';

const filtersTranslator = translatorFactory({
  defaultOrdering: 'name',
  orderingOptions: {
    name: 'name',
    '-name': '-name',
    title: 'title',
    department: 'department',
    location: 'location.name',
  },
});

function usePillsDefinition(options: PillsDefinitionProps) {
  const { filters } = options;

  const channelPillsDef = { pills: [] };

  const cleanedFilters = omit(filters, ['tab']);
  const peoplePillsDef = usePeopleDirectoryPillsDefinition({ filters: cleanedFilters });

  return {
    pills: [...channelPillsDef.pills, ...peoplePillsDef.pills],
  };
}

const PAGE_SIZE = 24;

type ChannelHomePeopleProps = {
  channel: Channel;
};

export function ChannelHomePeople(props: ChannelHomePeopleProps) {
  const { channel } = props;

  const { filters, ordering, updateFilter, removeValue, setOrdering, resetFilters, rqlExpression } =
    useNewRqlFilters({
      initialOrdering: 'name',
      translator: filtersTranslator,
    });

  const qParam = get(get(filters, 'q', ''), '$eq', '');

  const filtersDef = usePeopleDirectoryFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
  });

  const pillsDef = usePillsDefinition({ filters });

  const cleanedRQLExpression = cleanRQLExpression(rqlExpression, ['tab']);
  const searchRQLExpression = rql({
    channel: {
      $eq: channel.id,
    },
    page_size: PAGE_SIZE,
    view_mode: 'people',
  });
  const searchQuery = `${cleanedRQLExpression}&${searchRQLExpression}`;

  const {
    data: people,
    fetchMore,
    status: fetchStatus,
    count,
    hasNextPage,
    isLoading,
  } = useFetchDataListPaginated({
    ...queries.users.list(searchQuery),
  });

  const handleRemoveFilter = (filter: any) => {
    const filterName = get(filter, 'filterName', '');

    if (filterName === 'tab') {
      resetFilters(true);
      window.location.hash = '';
      return;
    }

    removeValue(filterName, get(filter, 'value', ''));
  };

  const orderingLabel = useOrderingLabel(ordering);

  return (
    <Box pt={2}>
      <Typography variant="h5" sx={{ color: grey['900'] }} fontWeight={500}>
        People
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <RQLFilterBar
          filters={filtersDef.filters}
          moreFilters={filtersDef.moreFilters}
          onClearAll={resetFilters}
        />

        <Box pb={isEmpty(pillsDef.pills) ? 1 : 0}>
          <FilterPills pills={pillsDef.pills} onRemove={handleRemoveFilter} />
        </Box>
      </Box>

      {isLoading && <Loading />}

      {!isLoading && count === 0 && <Box>No results found</Box>}

      <ViewAllContent
        fetchStatus={fetchStatus}
        fetchNextPage={fetchMore}
        hasNextPage={hasNextPage}
        totalResults={count}
        tooltipMessage={orderingLabel}
      >
        <CardList<Record<string, unknown>>
          items={people}
          renderItem={(person) => (
            <PersonCard key={person.id as number} person={person} q={qParam as string} />
          )}
        />
      </ViewAllContent>
    </Box>
  );
}
