import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import qs from 'query-string';

import { listQueryKeyFactory } from '~/app/data-fetching/utils';
import { ApiURLs, fetchURL } from '~/services/requests-base';

import {
  TrackBreadcrumb,
  TrackItem,
  TrackCompletionStatusInsightResponse,
  TrackCompletionStatusByItemInsight,
} from '../types';
import { get } from 'lodash-es';

type TrackBreadcrumbsQueryVariables = {
  parentTrackPublicId?: string;
  itemPublicId?: string;
  sectionId?: string;
};

type TrackBreadcrumbsResponse = {
  breadcrumbs: TrackBreadcrumb[];
};

type LegacyTrackItemsQueryVariables = {
  trackId?: number;
  trackType?: string;
  section?: string;
  pageSize?: number;
  viewMode?: string;
};

type DescendantTrackItemsQueryVars = {
  getAll?: boolean;
};

export const tracks = createQueryKeys('tracks', {
  list: listQueryKeyFactory({ endpointName: 'tracks_api:list_create' }),
  descendantTrackItems: (trackPublicId: string, vars: DescendantTrackItemsQueryVars = {}) => {
    return {
      queryKey: [trackPublicId, vars],
      queryFn: async () => {
        let url = ApiURLs['api_tracks:descendant_track_items'](trackPublicId);

        const queryParams = qs.stringify({
          get_all: vars.getAll,
        });

        url = `${url}?${queryParams}`;

        const res = await fetchURL(url);

        const data = get(res, 'data.results', []);

        /*
          TODO: The api response is not exactly of type TrackItem[]
        */
        return data as TrackItem[];
      },
    };
  },
  breadcrumbs: (rootTrackPublicId: string, variables: TrackBreadcrumbsQueryVariables) => {
    const { parentTrackPublicId, itemPublicId, sectionId } = variables;

    return {
      queryKey: [rootTrackPublicId, { parentTrackPublicId, itemPublicId, sectionId }],
      queryFn: async () => {
        let url = ApiURLs['api_tracks:breadcrumbs'](rootTrackPublicId);

        const queryParams = qs.stringify({
          direct_parent: parentTrackPublicId,
          item: itemPublicId,
          section: sectionId,
        });

        url = `${url}?${queryParams}`;

        const { data: response } = await fetchURL(url);
        return response as TrackBreadcrumbsResponse;
      },
    };
  },
  legacyTrackItems: (vars: LegacyTrackItemsQueryVariables = {}) => {
    const { trackId, trackType, section, pageSize, viewMode = 'summary' } = vars;

    return {
      queryKey: [vars],
      queryFn: async () => {
        let url = ApiURLs['api_tracks:track_items']();
        const queryParams = qs.stringify({
          track: trackId,
          page_size: pageSize,
          content_type: trackType,
          view_mode: viewMode,
          section,
        });

        url = `${url}?${queryParams}`;

        const { data: response } = await fetchURL(url);
        return response;
      },
    };
  },
  completionStatusInsights: (trackId: number, search: string) => {
    return {
      queryKey: [{ trackId, search }],
      queryFn: async () => {
        let url = ApiURLs['insights_api:track_completion_status'](trackId);

        url = `${url}?${search}`;

        const { data: response } = await fetchURL(url);
        return response as TrackCompletionStatusInsightResponse;
      },
    };
  },
  completionStatusByItemInsights: (trackId: number, search: string) => {
    return {
      queryKey: [{ trackId, search }],
      queryFn: async () => {
        let url = ApiURLs['insights_api:track_completion_status_by_item'](trackId);

        url = `${url}?${search}`;

        const { data: response } = await fetchURL(url);
        return response as TrackCompletionStatusByItemInsight;
      },
    };
  },
  insightsRQLFilters: (options: { initialFilters?: string[] } = {}) => {
    return {
      queryKey: ['insightsRQLFilters', options],
      queryFn: async () => {
        let url = ApiURLs['insights_api:tracks_rql_filters']();

        const queryParams = qs.stringify({
          initial_filters: options.initialFilters,
        });

        url = `${url}?${queryParams}`;

        const { data: response } = await fetchURL(url);
        return response;
      },
    };
  },
});

export type TracksQueryKeys = inferQueryKeys<typeof tracks>;
