import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import * as constants from '~/app/catalog/constants';
import { CHANNEL } from '~/app/catalog/constants';
import { parseResources } from '~/app/content-resources/services';
import { COURSE_STATUS_DRAFT } from '~/app/course/constants';
import actions from '~/app/entities/actions';
import { programSelector } from '~/app/entities/selectors';
import ChannelSelectField from '~/app/inputs/components/ChannelSelectField';
import CoverImageField from '~/app/inputs/components/CoverImageField';
import DurationField, { validateDuration } from '~/app/inputs/components/DurationField';
import FacilitatorField from '~/app/inputs/components/FacilitatorField';
import InlineItemField from '~/app/inputs/components/InlineItemField';
import InputLabel from '~/app/inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from '~/app/inputs/components/OldTextEditorField';
import TagTypesInputFields from '~/app/inputs/components/TagTypesInputFields';
import TextField from '~/app/inputs/components/TextField';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { parseNumber } from '~/services/utils';
import { useSettingsSectionsList } from '~/app/settings/hooks';
import { prepareSettingsValuesForSubmission } from '~/app/settings/services';
import { FormFieldGroup } from '~/app/shared/components/Form';
import InfoBox from '~/app/shared/components/InfoBox';
import NewContentForm from '~/app/shared/components/NewContentForm';
import CompletionSection from '~/app/shared/components/NewContentForm/CompletionSection';
import AccessControlSection from '~/app/shared/components/NewContentForm/sections/AccessControlSection';
import ResourcesSection from '~/app/shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from '~/app/shared/components/NewContentForm/sections/SurveysSection';
import Form from '~/app/shared/components/OldForm';
import {
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useToggles,
  useChannelToggle,
} from '~/app/shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from '~/app/topics/services';
import { concat, get, filter, isEmpty, isNil, map, pick, toLower } from 'lodash-es';
import { onSubmitActions } from '~/vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

export const CourseForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  selectedOfficeHour,
  isModalForm,
  currentUser,
}) => {
  const [settingsSectionsList] = useSettingsSectionsList('course/form', form);
  useFormPreventTransition(form);
  if (!isEmpty(initialValues.passing_score)) {
    // DRF serializes decimalfields as strings by default!
    initialValues.passing_score = Number(initialValues.passing_score) * 100;
  }
  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
    toggle_scorm_cloud_nonstandard: toggleScormNonStandard,
  } = useToggles();

  const {
    label_course: labelCourse,
    label_course_plural: labelCoursePlural,
    label_channel: labelChannel,
  } = useLabels();

  const infoPanelText = `${labelCoursePlural} are used for serving self-paced eLearning content, such as SCORM 1.2, SCORM 2004, AICC, xAPI, cmi5, and PDF. They can be embedded into tracks and individually assigned to people.`;

  const courseName = useFormSelector(form, 'name');
  const courseStatus = useFormSelector(form, 'status');

  const isPublishing = courseStatus === COURSE_STATUS_DRAFT;

  const canAddChannelContent = useChannelToggle();
  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const infoBox = isPublishing && (
    <InfoBox
      margin="8px 0 0 0"
      content="Your course has been imported successfully, but it will not be visible until you publish it."
    />
  );

  const passingScoreField = {
    id: 'passing',
    label: 'Passing',
    icon: 'lock',
    section: (
      <Field
        name="passing_score"
        component={TextField}
        min={0}
        max={100}
        label="Passing Score %"
        type="number"
        inputProps={{
          min: 0,
          'aria-label': `Passing Score %`,
        }}
        parse={parseNumber}
        subtext="Numeric values here override the SCORM pass/fail status as reported by the courseware."
      />
    ),
    sectionProps: {
      defaultOpen: true,
    },
  };

  const courseSectionsList = [
    {
      id: 'inline',
      label: 'Inline Item',
      icon: 'inline',
      section: <InlineItemField />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: 'course-details',
      label: `${labelCourse} Details`,
      icon: 'info',
      section: (
        <>
          <Field component="input" type="hidden" name="should_publish" />
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelCourse)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="course_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field name="author" label="Author" component={TextField} />
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              inputId="duration"
              name="duration"
              label="Estimated Duration (H:MM)"
              component={DurationField}
              validate={[validateDuration]}
              disableClearable
            />
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_courses']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_courses']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <>
          <FacilitatorField multiple={false} name="maintainer_id" label="Maintainer" />

          <FacilitatorField name="co_maintainers_ids" label="Co-Maintainer(s)" />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: `Completion`,
      icon: 'clock',
      section: <CompletionSection form={form} labelContentType={labelCourse} change={change} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'resources',
      label: `Resources`,
      icon: 'teach',
      section: <ResourcesSection formName={form} selectedOfficeHour={selectedOfficeHour} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(toggleScormNonStandard ? [passingScoreField] : []),
  ];

  const advancedSettingsList = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={constants.CONTENT_TYPES.video}
          formName={form}
          assignmentsCount={initialValues.assignments_count}
          isEditing={isEdit}
        />
      ),
      sectionProps: {
        defaultOpen:
          !isEmpty(initialValues.survey_relationships) ||
          !isEmpty(initialValues.external_survey_link),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={labelCourse}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
          channel={initialValues.channel}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  return (
    <NewContentForm
      contentNameSingular={labelCourse}
      contentInfoPanelText={infoPanelText}
      contentInfoBox={infoBox}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={courseName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={courseSectionsList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={isModalForm}
    />
  );
};

CourseForm.defaultProps = {
  initialValues: {},
};

CourseForm.propTypes = {
  form: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isModalForm: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,

  selectedOfficeHour: PropTypes.object,

  currentUser: PropTypes.object,
};

const ConnectedCourseForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { isEdit, formName, currentUser, initialValues = {} }) => {
    const actionName = actions.course.update.toString();
    return onSubmitActions(actionName, (values) => {
      const payload = {
        ...pick(values, [
          'author',
          'co_maintainers_ids',
          'content_body',
          'cover',
          'duration',
          'external_survey_link',
          'groups_ids',
          'hide_attendees',
          'is_hidden',
          'is_inline',
          'maintainer_id',
          'name',
          'office_hour_id',
          'resources',
          'should_publish',
          'survey_relationships',
          'time_to_complete',
          'time_to_retake',
          'topics',
          'channel_id',
          'passing_score',
        ]),
      };
      if (!payload.passing_score) {
        payload.passing_score = null;
      } else {
        payload.passing_score /= 100;
      }
      if (!payload.channel_id) {
        delete payload.channel_id;
      }

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_courses'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const tags = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

      payload.tags = filter(concat(tags, customTopicsList), Boolean);

      let facilitators = [];

      const maintainerId = get(payload, 'maintainer_id');
      const coMaintainersIds = get(payload, 'co_maintainers_ids');

      if (maintainerId) {
        facilitators = concat(facilitators, {
          user_id: maintainerId,
          role: constants.FACILITATOR_ROLES.maintainer,
        });
      }

      if (coMaintainersIds) {
        facilitators = concat(
          facilitators,
          map(coMaintainersIds, (coMaintainerId) => ({
            user_id: coMaintainerId,
            role: constants.FACILITATOR_ROLES.co_maintainer,
          }))
        );
      }

      payload.facilitators = facilitators;

      // Surveys
      let { survey_relationships: surveyRelationships } = payload;
      const { external_survey_link: surveyLink } = payload;

      surveyRelationships = map(surveyRelationships, (sr) => {
        return pick(sr, [
          'id',
          'survey_id',
          'survey_cutoff_period_value',
          'survey_cutoff_period_option',
          'survey_schedule_period_value',
          'survey_schedule_period_option',
          'survey_schedule_kind',
        ]);
      });

      payload.external_survey_link = surveyLink;
      payload.survey_relationships = surveyRelationships;

      // Resources Links
      payload.resources = parseResources(payload.resources, isEdit);

      if (!payload.duration) {
        payload.duration = '00:00:00';
      }

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(CourseForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);
  return {
    selectedOfficeHour,
    currentUser: get(state, 'user.currentUser'),
  };
};

export default connect(mapStateToProps)(ConnectedCourseForm);
