import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import type {} from '@mui/x-charts-pro/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { transparentize } from 'polished';
import { legendClasses, pieArcClasses, pieArcLabelClasses } from '@mui/x-charts-pro';

import colors from '~/services/colors';
import { green, grey, red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 695,
      md: 1108,
      lg: 1482,
      xl: 2167,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
        variant: 'outlined',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'error' && {
              backgroundColor: colors.error200,
              color: transparentize(0.46, colors.error200TextColor),
            }),
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          // Undo the _type.scss override
          '& p': {
            margin: '0 10px',
          },
        },
      },
    },
    MuiChartsLegend: {
      defaultProps: {
        itemMarkHeight: 8,
        itemMarkWidth: 8,
        labelStyle: {
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1.43',
        },
      },
      styleOverrides: {
        root: {
          [`& .${legendClasses.mark}`]: {
            rx: '100%',
            ry: '100%',
          },
        },
      },
    },
    MuiChartsSurface: {
      styleOverrides: {
        root: {
          [`& .${pieArcClasses.root}`]: {
            stroke: 'unset',
          },
          [`& .${pieArcLabelClasses.root}`]: {
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: '1.1667',
            fill: '#FFFFFF',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.action700,
      contrastText: colors.action700TextColor,
      dark: colors.action800,
    },
    secondary: {
      main: colors.emphasis600,
      light: colors.emphasis600,
      contrastText: colors.emphasis600TextColor,
      dark: colors.emphasis700,
    },
    error: {
      main: colors.error600,
      contrastText: colors.error600TextColor,
      dark: colors.error700,
    },
    // @ts-expect-error Typings are not updated
    neutral: {
      white: colors.neutral0,
      light: colors.neutral100,
      main: colors.neutral600,
      contrastText: colors.neutral600TextColor,
      dark: colors.neutral700,
    },
    // Custom colors
    grey300: {
      main: grey[300],
      contrastText: '#00000070',
    },
    green200: {
      main: green[200],
      contrastText: '#00000070',
    },
    red100Accent: {
      main: red.A100,
      contrastText: '#00000070',
    },
  },
});

theme = createTheme(theme, {
  palette: {
    rating: theme.palette.augmentColor({ color: { main: colors.rating600 }, name: 'rating' }),
  },
});

type MuiThemeWrapperProps = {
  children: React.ReactNode;
};

function MuiThemeWrapper(props: MuiThemeWrapperProps) {
  const { children } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </LocalizationProvider>
  );
}

export default MuiThemeWrapper;
