import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { addMenteeToProgram } from '~/app/data-fetching/mutations/mentorship';
import RQLFilterBar from '~/app/filters/components/RQLFilterBar';
import { useRQLFilters } from '~/app/filters/hooks';
import { useMentorshipMenteeFiltersDefinition } from '~/app/mentorship/hooks';
import { toast } from '~/app/notifications/components/NotificationCenter';
import { queries } from '~/queries';
import MentorshipRequestModal from '~/scenes/UserProfile/UserTabs/MentorshipRequestModal';
import CardList from '~/app/shared-cards/components/CardList';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import Button from '~/app/shared/components/Button';
import { MenteeCard } from '~/app/shared/components/Card';
import Loading from '~/app/shared/components/Loading';
import Text from '~/app/shared/components/Text';
import { User } from '~/app/shared/components/types';
import { useCurrentUser } from '~/app/shared/hooks';
import { isError, isEmpty, some, map, capitalize } from 'lodash-es';
import { Box, Stack } from '@mui/material';
import EmptyMessage from './EmptyMessage';
import { useMentorshipProgramLabels } from '~/features/mentorship_programs/hooks/useMentorshipProgramLabels';
import SuggestMentorshipModal from '~/app/shared-modals/components/SuggestMentorshipModal';
import { MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR } from '~/app/program/constants';

interface OfferMentorshipProps {
  handleOnClick: () => void;
}

const OfferMentorshipButton = ({ handleOnClick }: OfferMentorshipProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
      paddingBottom: '15px',
    }}
  >
    <Button variant="outlined" onClick={handleOnClick}>
      Offer to mentor
    </Button>
  </Box>
);

interface SuggestMentorButtonProps {
  handleOnClick: () => void;
  mentorLabel: string;
}

const SuggestMentorButton = (props: SuggestMentorButtonProps) => {
  const { handleOnClick, mentorLabel } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        paddingBottom: '15px',
      }}
    >
      <Button variant="outlined" onClick={handleOnClick}>
        {`Suggest a ${mentorLabel}`}
      </Button>
    </Box>
  );
};

const handleOfferToMentorModal = (mentee, setMenteeForRequest, setShowOfferToMentorModal) => {
  return () => {
    setShowOfferToMentorModal(true);
    setMenteeForRequest(mentee);
  };
};

const handleSuggestMentorModal = (mentee, setMenteeForRequest, setShowSuggestMentorModal) => {
  return () => {
    setShowSuggestMentorModal(true);
    setMenteeForRequest(mentee);
  };
};

export interface MentorshipProgramMenteeListProps {
  program: ContentItem;
  canJoin: boolean;
}

const MentorshipProgramMenteeList = ({ program, canJoin }: MentorshipProgramMenteeListProps) => {
  const { public_id, id } = program;
  const { menteeLabel, menteeLabelPlural, mentorLabel } = useMentorshipProgramLabels(program);

  const [showOfferToMentorModal, setShowOfferToMentorModal] = useState<boolean>(false);
  const [showSuggestMentorModal, setShowSuggestMentorModal] = useState<boolean>(false);
  const [menteeForRequest, setMenteeForRequest] = useState<User | null>(null);

  const currentUser = useCurrentUser();
  const { filters, updateFilter, resetFilters, rqlExpression } = useRQLFilters({
    initialFiltersState: {},
    initialOrdering: 'name',
  });

  const { filters: barFilters, moreFilters } = useMentorshipMenteeFiltersDefinition({
    filters,
    updateFilter,
  });

  const {
    isLoading,
    isError: hasError,
    data: response,
    error,
  } = useQuery(queries.mentorship_program.mentees(public_id, rqlExpression));

  const people = map(response?.results, 'mentee');

  const joinMutation = useMutation({
    mutationFn: () => addMenteeToProgram(public_id, currentUser.id, id),
    onError: () => {
      toast.error(`Error: Unable to join ${program.name} as a ${menteeLabel}.`);
    },
    onSuccess: () => {
      toast.success(`Successfully joined ${program.name} as a ${menteeLabel}.`);
    },
  });

  const { data: isMentor } = useQuery(
    queries.mentorship_program.mentors(public_id, null)._ctx.is_mentor(currentUser.id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (hasError && isError(error)) {
    return <span>Error: {error.message} </span>;
  }

  const isMentee = some(people, (mentee) => {
    return mentee.id == currentUser.id;
  });
  const showJoin = canJoin && !isMentee;

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <RQLFilterBar filters={barFilters} moreFilters={moreFilters} onClearAll={resetFilters} />
        </Box>
        {showJoin && (
          <Button sx={{ minWidth: '140px' }} onClick={() => joinMutation.mutate()}>
            {`Join as ${capitalize(menteeLabel)}`}
          </Button>
        )}
      </Stack>

      {isMentee && <Text size="h2">{`You are a ${menteeLabel}.`}</Text>}

      {isEmpty(people) && <EmptyMessage message={`No ${menteeLabelPlural} found.`} />}

      {!isEmpty(people) && (
        <Box sx={{ pt: 3 }}>
          <CardList
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            items={people}
            renderItem={(mentee: User) => (
              <MenteeCard
                cardProps={{ width: '100%', boxShadow: 0 }}
                mentee={mentee}
                offerComponent={
                  isMentor ? (
                    <OfferMentorshipButton
                      handleOnClick={handleOfferToMentorModal(
                        mentee,
                        setMenteeForRequest,
                        setShowOfferToMentorModal
                      )}
                    />
                  ) : null
                }
                suggestComponent={
                  currentUser.id === mentee.manager_id ? (
                    <SuggestMentorButton
                      handleOnClick={handleSuggestMentorModal(
                        mentee,
                        setMenteeForRequest,
                        setShowSuggestMentorModal
                      )}
                      mentorLabel={mentorLabel}
                    />
                  ) : null
                }
              />
            )}
          />
        </Box>
      )}

      {showOfferToMentorModal && (
        <MentorshipRequestModal
          mentee={menteeForRequest}
          handleClose={() => setShowOfferToMentorModal(false)}
          program={program}
        />
      )}

      {showSuggestMentorModal && menteeForRequest && (
        <SuggestMentorshipModal
          receiver={menteeForRequest}
          suggestionKind={MENTORSHIP_REQUEST_KIND_SUGGEST_MENTOR}
          handleClose={() => setShowSuggestMentorModal(false)}
          program={program}
        />
      )}
    </Box>
  );
};

export default MentorshipProgramMenteeList;
