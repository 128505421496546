import { useMutation } from '@tanstack/react-query';

import { apiPost, apiPatch, apiDelete } from '~/services/requests';

import { Article, CreateArticleBody, UpdateArticleBody } from '../types';

export function useCreateArticle() {
  return useMutation({
    mutationFn: async (body: CreateArticleBody) => {
      const { data: response } = await apiPost('articles_api:list_create', null, body);
      return response;
    },
  });
}

export function useEditArticle(id: number) {
  return useMutation({
    mutationFn: async (body: UpdateArticleBody) => {
      const { data: response } = await apiPatch('articles_api:retrieve_update_destroy', id, body);

      return response;
    },
  });
}

// TODO: Remove this once we have access to the private id on the frontend
export function useEditArticleByPublicId(publicId) {
  return useMutation({
    mutationFn: async (body: UpdateArticleBody) => {
      const { data: response } = await apiPatch(
        'articles_api:retrieve_update_destroy_by_public_id',
        publicId,
        body
      );

      return response;
    },
  });
}

export function useDeleteArticle(id: number) {
  return useMutation({
    mutationFn: async () => {
      const { data: response } = await apiDelete('articles_api:retrieve_update_destroy', id);
      return response;
    },
  });
}

export function useArchiveArticle(article: Article) {
  const { id } = article;

  return useMutation({
    mutationFn: async () => {
      const body = {};
      const { data: response } = await apiPost('articles_api:archival', id, body);

      return response;
    },
  });
}

export function useUnarchiveArticle(article: Article) {
  const { id } = article;

  return useMutation({
    mutationFn: async () => {
      const { data: response } = await apiDelete('articles_api:archival', id);

      return response;
    },
  });
}
