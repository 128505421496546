import get from 'lodash-es/get';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useLabels } from '~/app/shared/hooks';

export function useMentorshipProgramLabels(mentorship_program: ContentItem | null) {
  const {
    label_mentorship_sessions_creator_title_single,
    label_mentorship_sessions_attendee_title_single,
    label_mentorship_sessions_creator_title_plural,
    label_mentorship_sessions_attendee_title_plural,
  } = useLabels();

  const mentorLabel: string = get(
    mentorship_program,
    'settings.label_mentorship_sessions_creator_title_single.value',
    label_mentorship_sessions_creator_title_single
  );
  const menteeLabel: string = get(
    mentorship_program,
    'settings.label_mentorship_sessions_attendee_title_single.value',
    label_mentorship_sessions_attendee_title_single
  );
  const mentorLabelPlural: string = get(
    mentorship_program,
    'settings.label_mentorship_sessions_creator_title_plural.value',
    label_mentorship_sessions_creator_title_plural
  );
  const menteeLabelPlural: string = get(
    mentorship_program,
    'settings.label_mentorship_sessions_attendee_title_plural.value',
    label_mentorship_sessions_attendee_title_plural
  );

  return {
    mentorLabel,
    mentorLabelPlural,
    menteeLabel,
    menteeLabelPlural,
  };
}
