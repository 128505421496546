import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queries } from '~/queries';
import { constant, get, size, slice } from 'lodash-es';

type TransformWidgetDataReturn<DataType = unknown> = {
  results: DataType[];
  total: number;
};

function transformWidgetData<DataType = unknown>(
  data: unknown
): TransformWidgetDataReturn<DataType> {
  // Widgets can only show 3 items max
  const results = slice(get(data, 'results', []), 0, 3) as DataType[];
  const total = get(data, 'count', 0) as number;

  return { results, total };
}

export function useWidgetQuery<DataType = unknown>(resourceName: string, search: string) {
  const listQuery = get(queries, `${resourceName}.list`, constant({})) as any;

  let querySearch = 'page=1&page_size=3&view_mode=widget';

  if (size(search) > 0) {
    querySearch = `${querySearch}&${search}`;
  }

  return useQuery<TransformWidgetDataReturn<DataType>>({
    ...listQuery(querySearch),
    retry: 1,
    select: useCallback((data) => {
      return transformWidgetData<DataType>(data);
    }, []),
  });
}
