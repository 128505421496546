import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useEffect } from 'react';

import actions from '~/app/entities/actions';
import { assignmentSchema } from '~/app/entities/schema';
import { useEntities } from '~/app/entities/utils';
import { METRICS_ACTIVITIES, useMetrics } from '~/services/metrics';
import { isEmpty } from 'lodash-es';

import { DASHBOARD_ASSIGNMENTS } from '../constants';

const Context = createContext({});

export const useAssignmentsContext = () => useContext(Context);

export const AssignmentsProvider = ({
  children,
  fetchListAction,
  fetchFiltersAction,
  breadcrumbItems,
  exportCSVURL,
  dashboardName,
  enableSegments,
}) => {
  const { trackActivity } = useMetrics();

  const [fetchFilters, { data: dynamicFilters, status: fetchFiltersStatus }] = useEntities(
    fetchFiltersAction,
    null
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  const [
    fetchAssignments,
    { data: assignments, status: assignmentsStatus, count: assignmentsCount },
  ] = useEntities(fetchListAction, null, {
    schema: [assignmentSchema],
  });

  const refreshDashboard = (expression) => {
    // The empty expression means that the effects have not been processed yet, because an expression must have at least the ordering, page number, and page size.
    if (isEmpty(expression)) return;

    const expressionWithViewMode = `${expression}&view_mode=dashboard`;

    trackActivity(METRICS_ACTIVITIES.DASHBOARD_VIEW, {
      dashboardName,
      filters: expressionWithViewMode,
    });

    fetchAssignments(expressionWithViewMode);
  };

  return (
    <Context.Provider
      value={{
        dynamicFilters,
        fetchFiltersStatus,
        refreshDashboard,
        assignments,
        assignmentsStatus,
        assignmentsCount,
        breadcrumbItems,
        exportCSVURL,
        dashboardName,
        enableSegments,
      }}
    >
      {children}
    </Context.Provider>
  );
};

AssignmentsProvider.defaultProps = {
  fetchListAction: actions.contentAssignment.retrieveRql,
  fetchFiltersAction: actions.contentAssignment.retrieveRqlFilters,
  breadcrumbItems: [{ label: 'Content Items', link: null }, { label: 'Assignments' }],
  exportCSVURL: 'content_items_api:assignments_export_rql',
  dashboardName: DASHBOARD_ASSIGNMENTS,
  enableSegments: true,
};

AssignmentsProvider.propTypes = {
  children: PropTypes.node,
  fetchListAction: PropTypes.func,
  fetchFiltersAction: PropTypes.func,
  breadcrumbItems: PropTypes.array,
  exportCSVURL: PropTypes.string,
  dashboardName: PropTypes.string,
  enableSegments: PropTypes.bool,
};
